import React, { useEffect, useState, forwardRef, useImperativeHandle } from "react";
import styled from "styled-components";

interface Props {
    progress?: number;
}

export const ActionScale = forwardRef(({ progress = 100 }: Props, ref) => {
    const [currentProgress, setCurrentProgress] = useState(progress);

    useEffect(() => {
        if (currentProgress > 0) {
            const interval = setInterval(() => {
                setCurrentProgress((prev) => Math.max(prev - 1, 0));
            }, 100); // Decrease progress over 10 seconds (100ms * 100 steps)

            return () => clearInterval(interval);
        }
    }, [currentProgress]);

    useEffect(() => {
        if (currentProgress <= 0) {
            // Handle when progress is fully depleted
            console.log("Progress depleted");
        }
    }, [currentProgress]);

    useImperativeHandle(ref, () => ({
        resetProgress: () => {
            setCurrentProgress(100);
        },
    }));


    if(currentProgress <= 0) {
        return null;
    }

    return (
        <Absolute>
            <Relative>
                <svg

                    style={{
                        position: "absolute",
                        top: "-7px",
                        right: "-3px",
                        zIndex: 1,
                        width: "24px",
                        height: "24px",
                    }}
                    xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                    <rect x="0.5" y="0.5" width="19" height="19" rx="5.5" fill="#1287A5"/>
                    <rect x="0.5" y="0.5" width="19" height="19" rx="5.5" fill="url(#paint0_linear_336_25111)"/>
                    <rect x="0.5" y="0.5" width="19" height="19" rx="5.5" stroke="#E87D18"/>
                    <g clip-path="url(#clip0_336_25111)">
                        <path fill-rule="evenodd" clip-rule="evenodd"
                              d="M10.4199 4.87538L11.8774 7.93191L16.0069 8.58115L13.0226 11.6669L13.7166 15.9927L10.0035 13.9647L6.29728 15.9927L6.98438 11.6523L4 8.58115L8.13649 7.92462L10.0035 4L10.4199 4.87538ZM11.1486 8.59574L10.0035 6.18845L8.75419 8.82188L5.97802 9.25957L7.96992 11.3167L7.51186 14.2274L9.98959 12.8705L12.4812 14.2274L12.0162 11.3313L14.015 9.25957L11.2527 8.82918L11.1417 8.60304L11.1486 8.59574Z"
                              fill="#997B00"/>
                        <path fill-rule="evenodd" clip-rule="evenodd"
                              d="M10.0037 5.09424L11.5653 8.37691L15.0147 8.91673L12.523 11.4991L13.0991 15.11L9.99672 13.4176L6.90823 15.11L7.47735 11.4845L4.99268 8.91673L8.44901 8.36962L10.0037 5.09424Z"
                              fill="url(#paint1_linear_336_25111)"/>
                        <path fill-rule="evenodd" clip-rule="evenodd"
                              d="M4.99268 8.92403L8.78215 8.74895L10.0314 5.80913L11.4126 8.77083L15.0147 8.92403L11.5653 8.37691L10.0037 5.09424L8.44901 8.36962L4.99268 8.92403Z"
                              fill="#FFFF00"/>
                        <path fill-rule="evenodd" clip-rule="evenodd"
                              d="M7.26914 12.8268L7.90072 10.8718L4.99268 8.92407L7.47735 11.4846L7.26914 12.8268Z"
                              fill="#DBAF00"/>
                        <path fill-rule="evenodd" clip-rule="evenodd"
                              d="M11.9536 10.9083L12.7379 12.8633L12.5227 11.4991L15.0143 8.92407L11.9606 10.9156L11.9536 10.9083Z"
                              fill="#DBAF00"/>
                        <path fill-rule="evenodd" clip-rule="evenodd"
                              d="M13.0505 14.7891L9.96893 12.3162L6.9082 15.1101L9.99669 13.4177L13.0991 15.1101L13.0505 14.7891Z"
                              fill="#DBAF00"/>
                    </g>
                    <defs>
                        <linearGradient id="paint0_linear_336_25111" x1="0" y1="10" x2="20" y2="10"
                                        gradientUnits="userSpaceOnUse">
                            <stop stop-color="#FFA901"/>
                            <stop offset="1" stop-color="#FFC71E"/>
                        </linearGradient>
                        <linearGradient id="paint1_linear_336_25111" x1="10.0037" y1="13.4176" x2="10.0037" y2="5.09424"
                                        gradientUnits="userSpaceOnUse">
                            <stop stop-color="#FFC421"/>
                            <stop offset="1" stop-color="#F4EB28"/>
                        </linearGradient>
                        <clipPath id="clip0_336_25111">
                            <rect width="12" height="12" fill="white" transform="translate(4 4)"/>
                        </clipPath>
                    </defs>
                </svg>
                <Container>
                    <Container2>
                        <Progress progress={currentProgress}/>
                    </Container2>
                </Container>
            </Relative>
        </Absolute>
    );
});


const Absolute = styled.div`
    position: absolute;
    top: 40%;
    right: 10px;
`;

const Relative = styled.div`
    position: relative;
`;

const Container = styled.div`
    border: 1px solid #e87d18;
    background: #ffc541;
    width: 16px;
    height: 173px;
    border-radius: 50px;
    flex-shrink: 0;
    position: relative;

    display: flex;
    align-items: center;
    justify-content: center;
`;

const Container2 = styled.div`
    border: 1px solid #e87d18;
    background: #1287a5;
    width: 12px;
    top: 0;
    left: 0;
    height: 169px;
    border-radius: 50px;
    flex-shrink: 0;
    align-items: center;
    display: flex;
    flex-direction: column-reverse;
    overflow: hidden;
`;

const Progress = styled.div<{ progress: number }>`
    background: #eea206;
    width: 100%;
    height: ${(props) => props.progress}%;
    border-radius: 50px;
    transition: height 0.5s;
`;
