import {create} from 'zustand';
import {Friends} from "../api/requests/user-info";

type FriendsState = {
    friends: Friends | null;
    setFriends: (friends: Friends) => void;
};


export const useFriendsStore = create<FriendsState>((set) => ({
    friends: null,
    setFriends: (friends) => set({friends}),
}));