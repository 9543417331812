import {create} from 'zustand';
import {Channel} from "../api/requests/channels";

type ChannelState = {
    channels: Channel[] | null;
    setChannels: (channels: Channel[]) => void;
};


export const useChannelsStore = create<ChannelState>((set) => ({
    channels: null,
    setChannels: (channels) => set({channels}),
}));