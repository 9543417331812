
import {create} from 'zustand';

type State = {
     price: number | null;
     setPrice: (price: (prev: number | null) => number | null) => void;
};


export const useInvoiceStore = create<State>((set) => ({
     price: null,
     setPrice: (price) => set((state) => ({price: price(state.price!!)})),
}));