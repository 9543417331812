import React, {useEffect, useRef, useState} from 'react';
import Matter, {Bodies, Body, Bounds, Constraint, Engine, Events, Render, Runner, World} from 'matter-js';
import {useWindowSize} from "../../hooks/useWindowSize";
import styled from "styled-components";
import {Life} from "../../life";
import {Scale} from "../../scale";
import {useUserStore} from "../../../store/user-store";
import {ActionModal} from "../../action-modal";
import {useNavigate} from "react-router-dom";
import {buildHandler} from "../../../api/requests/area";
import {useAreasStore} from "../../../store/areas-store";
import {PauseModal} from "../../pause-modal";
import {useHapticFeedback} from "@tma.js/sdk-react";
import {usePositionStore} from "../../../store/position-store";
import {UWinModal} from "../../u-win-modal";
import {ActionScale} from "../../action-scale";

const loadImage = (url: string) => {
    return new Promise<string>((resolve, reject) => {
        const img = new Image();
        img.onload = () => resolve(img.src);
        img.onerror = reject;
        img.src = url;
    });
};


interface IBlockImage {
    total: number;
    current: number;
    blox_level: number;
}

const getBlockImage = ({total, current, blox_level}: IBlockImage) => {

    let lvl = blox_level

    if (blox_level > 10) {
        lvl = 10
    }

    if (current < 1 && current < total) {
        return `/images/first-box/${lvl}.png`
    } else if (current + 1 === total) {
        return `/images/last-box/${lvl}.png`
    } else {
        return `/images/box/${lvl}.png`
    }

}


const getPersonImage = (type: number = 1) => {
    return `/images/person/${type}.png`

}

const MatterGame: React.FC = () => {
    const scene = useRef<HTMLDivElement>(null);
    const engine = useRef<Matter.Engine>(Engine.create());
    const runner = useRef<Matter.Runner>(Runner.create());
    const render = useRef<Matter.Render | null>(null);
    const [isRunning, setIsRunning] = useState<boolean>(true);
    const [life, setLife] = useState<number>(3);
    const haptic = useHapticFeedback();


    const box = useRef<Matter.Body[]>([]);
    const ground = useRef<Matter.Body>();
    const underGround = useRef<Matter.Body>();
    const magnet = useRef<Matter.Body>();

    const hangPoint = useRef<Matter.Body>();
    const intervalID = useRef<NodeJS.Timeout | null>(null);
    const constraint = useRef<Matter.Constraint | null>(null);
    const magnetConstraint = useRef<Matter.Constraint | null>(null);
    const isDropping = useRef<boolean>(false);
    const oscillationIntervalID = useRef<NodeJS.Timeout | null>(null);

    const {width: wi, height: he} = useWindowSize();

    const width = wi + 80;
    const height = he + 40;
    const ellipseParams = useRef<{ a: number, b: number }>({a: 80, b: 40});
    const magnetPosition = useRef<{ x: number, y: number } | null>(null);
    const hangPointPosition = useRef<{ x: number, y: number } | null>(null);
    const [groundY, setGroundY] = useState(-6);
    const [gameOver, setGameOver] = useState<boolean>(false);

    const [isLoading, setIsLoading] = useState<boolean>(true);

    const stopButtonRef = useRef<HTMLDivElement>(null);
    const LINE_HEIGHT = he - (300)
    const {user} = useUserStore();
    const total = 10 + (((user?.block_level || 0) - 1) * 3)
    const actionScaleRef = useRef<{ resetProgress: () => void }>(null);

    const isFitstBloackOnGround = useRef<boolean>(false);
    const isDonotMoveCamera = useRef<boolean>(false);


    const {setAreas} = useAreasStore();

    const comboCount = useRef(0)
    const highQualityCount = useRef(0)
    const mediumQualityCount = useRef(0)
    const lowQualityCount = useRef(0)


    const bConunt = comboCount?.current + highQualityCount.current + mediumQualityCount.current + lowQualityCount.current

    const navigate = useNavigate()
    // get y and x from query params
    const {position} = usePositionStore()
    const x = position?.pos_x
    const y = position?.pos_y

    const handleStopGame = (e: any) => {
        e.stopPropagation();
        Runner.stop(runner.current);
        if (render.current) {
            Render.stop(render.current);
        }
        if (intervalID.current) {
            clearInterval(intervalID.current);
        }
    };

    //
    useEffect(() => {
        if (!position) {
            navigate('/')
        }
    }, []);

// Example of when to reset the progress
    useEffect(() => {
        if (comboCount.current || highQualityCount.current ) {
            actionScaleRef.current?.resetProgress();
        }
    }, [comboCount.current, highQualityCount.current]);

    const handleStartGame = (e: any) => {
        e.stopPropagation();
        Runner.run(runner.current, engine.current);
        if (render.current) {
            Render.run(render.current);
        }
        setIsRunning(prevState => !prevState);
    }

    useEffect(() => {
        const engineInstance = engine.current;

        // Initialize the render only after the images are fully loaded and bodies are created
        const initializeRender = async () => {
            // Ensure all images and bodies are loaded and created
            await createSwing(width / 2);
            await createGround();
            const initialY = LINE_HEIGHT - 120;
            await createBlock(width / 2, initialY);

            render.current = Render.create({
                element: scene.current!,
                engine: engineInstance,
                isFixed: false,
                delta: 1000 / 30,
                fps: 30,
                options: {
                    width: width,
                    height: height,
                    wireframes: false,

                    hasBounds: true,
                    background: 'transparent',
                    //@ts-ignore
                    pixelRatio: /android/i.test(navigator.userAgent || navigator.vendor || window.opera) ? 0.4 : 'auto',
                    bounds: {
                        min: {x: 0, y: 0},
                        max: {x: width, y: height},
                    }

                },
            });

            Runner.run(runner.current, engineInstance);
            Render.run(render.current);


            startBlockOscillation();
            startEllipticalMotion();
        };

        initializeRender().then()

        const handleDropBlock = (e: Event) => {
            if (e.target !== stopButtonRef.current) {
                dropBlock();
            }
        };

        document.body.addEventListener('click', handleDropBlock);

        Events.on(engine.current, 'beforeUpdate', () => {
            if (magnet.current) {
                magnetPosition.current = {x: magnet.current.position.x, y: magnet.current.position.y};
            }
            if (hangPoint.current) {
                hangPointPosition.current = {x: hangPoint.current.position.x, y: hangPoint.current.position.y};
            }
        });

        setTimeout(() => {
            setIsLoading(false);
        }, 4000);


        return () => {
            document.body.removeEventListener('click', handleDropBlock);
            if (render.current) {
                Render.stop(render.current);
                World.clear(engineInstance.world, false);
                Engine.clear(engineInstance);
                render.current.canvas.remove();
                render.current.textures = {};
                Events.off(engine.current, 'afterUpdate', checkIfResting);

            }
            if (oscillationIntervalID.current) {
                clearInterval(oscillationIntervalID.current);
            }
            if (intervalID.current) {
                clearInterval(intervalID.current);
            }
        };
    }, [width, height]);


    const createUmbrellaPerson = async (targetX: number, targetY: number, direction: 'left' | 'right', index: number) => {
        try {
            const personImage = getPersonImage(index + 1);

            const startX = direction === 'left' ? -50 : width + 50;  // Based on direction
            const startY = targetY - 200;

            const umbrellaPerson = Bodies.rectangle(startX, startY, 40, 40, {
                isStatic: false,
                friction: 0.01,
                density: 0.001,
                render: {
                    fillStyle: 'red',
                    sprite: {
                        texture: personImage,
                        xScale: 0.20,
                        yScale: 0.20,
                    },
                },
                collisionFilter: {
                    group: -1,
                    mask: 0x000
                }
            });

            World.add(engine.current.world, [umbrellaPerson]);

            const moveUmbrellaPerson = () => {
                const dx = targetX - umbrellaPerson.position.x;
                const dy = targetY - umbrellaPerson.position.y;

                const newX = umbrellaPerson.position.x + dx / 15;
                const newY = umbrellaPerson.position.y + dy / 15;

                Body.setPosition(umbrellaPerson, {x: newX, y: newY});

                if (Math.abs(dx) < 1 && Math.abs(dy) < 1) {
                    clearInterval(moveInterval);

                    Body.set(umbrellaPerson, {
                        collisionFilter: {
                            category: 0x0002,
                            mask: 0xFFFF
                        }
                    });
                }
            };

            const moveInterval = setInterval(moveUmbrellaPerson, 1000 / 60);

            const checkIfTouchingBlock = () => {
                if (umbrellaPerson.position.y - 60 > targetY) {
                    setTimeout(() => {
                        World.remove(engine.current.world, umbrellaPerson);
                        clearInterval(moveInterval);
                    }, 150);
                }
            };

            setInterval(checkIfTouchingBlock, 1000 / 60);

        } catch (error) {
            console.log("Error creating umbrella person:", error);
        }
    };



    const startBlockOscillation = () => {
        let angle = 0;
        const amplitude = 0.01;
        const frequency = 0.05;

        const oscillate = () => {
            if (ground.current && render.current) {
                const groundY = ground.current.position.y;
                const renderBounds = render.current.bounds;

                if (groundY > renderBounds.max.y) {
                    angle += frequency;

                    box.current.forEach(b => {
                        const sway = amplitude * Math.sin(angle);
                        Body.setAngle(b, sway);
                        Body.setPosition(b, {
                            x: b.position.x + sway,
                            y: b.position.y
                        });
                    });
                }
            }
            requestAnimationFrame(oscillate);
        };

        oscillate();
    }


    const createGround = async () => {
        try {
            const floorTexture = await loadImage('/images/floor.png');

            underGround.current = Bodies.rectangle(wi / 2, ((he) * 0.9), 100, 50, {
                isStatic: true,
                render: {
                    fillStyle: 'red',
                    strokeStyle: 'transparent',

                    sprite: {
                        texture: floorTexture,
                        xScale: 0.33,
                        yScale: 0.33,
                    },
                },
            });

            ground.current = Bodies.rectangle(wi / 2, ((he) * 0.9) - 66, 100, 50, {
                isStatic: true,
                render: {
                    fillStyle: 'rgba(60, 60, 59, 1)',
                }
            });

            World.add(engine.current.world, [ground.current, underGround.current]);
        } catch (error) {
            console.log("Error loading image:", error);
        }
    }


    const createBlock = async (initialX: number, initialY: number) => {
        console.log("Create block")

        if (constraint.current) {
            return;
        }

        if (comboCount.current + highQualityCount.current + mediumQualityCount.current + lowQualityCount.current === total) {
            return;
        }
        try {
            const blockTexture = await loadImage(
                getBlockImage({
                    total,
                    current: comboCount.current + highQualityCount.current + mediumQualityCount.current + lowQualityCount.current,
                    blox_level: user?.block_level || 0
                })
            );

            const boxCurrent = Bodies.rectangle(
                initialX,
                initialY + 20.1,

                60,

                (comboCount.current + highQualityCount.current + mediumQualityCount.current + lowQualityCount.current) + 1 === total ? 76 : 60,

                {
                    angle: 0,
                    density: 0.001,
                    friction: 0.5,
                    frictionAir: 0.0,
                    restitution: 0,
                    mass: 0,

                    inertia: Infinity,
                    isStatic: false,
                    render: {
                        fillStyle: "transparent",
                        sprite: {
                            texture: blockTexture,
                            xScale: 0.33,
                            yScale: 0.33,
                        },
                    },
                });

            box.current.push(boxCurrent);
            if (!constraint.current) {
                constraint.current = Constraint.create({
                    bodyA: magnet.current,
                    bodyB: box.current[box.current.length - 1],
                    length: 0.1,
                    pointB: {
                        x: 0,
                        y: (comboCount.current + highQualityCount.current + mediumQualityCount.current + lowQualityCount.current) + 1 === total ? -50 : -40
                    },
                    render: {
                        visible: true,
                        lineWidth: 2,
                        strokeStyle: '#000',
                    }
                });
            }

            World.add(engine.current.world, [boxCurrent as unknown as Constraint, constraint.current]);


            if (render.current && hangPoint.current && box.current.length === 1) {
                Bounds.shift(render.current.bounds, {
                    x: hangPoint.current.position.x - width / 2,
                    y: hangPoint.current.position.y,
                });
            }

            Events.on(engine.current, 'afterUpdate', checkIfResting);

        } catch (error) {
        }
    };

    const startEllipticalMotion = () => {
        const {a, b} = ellipseParams.current;
        let angle = 1;
        const angularSpeed = isRunning ? 0.03 : 0;

        const moveHangPoint = () => {
            if (hangPoint.current && render.current) {
                angle += angularSpeed;
                const centerOfEllipse = {
                    x: (render.current.bounds.min.x + wi / 2) + (a * Math.cos(angle)),
                    y: (render.current.bounds.min.y + LINE_HEIGHT - (LINE_HEIGHT * 1.5) + (b * Math.sin(angle))),
                };
                const x = centerOfEllipse.x + a * Math.cos(angle);
                const y = centerOfEllipse.y + b * Math.sin(angle);
                Body.setPosition(hangPoint.current, {x, y});
                if (magnet.current) {
                    Body.setPosition(magnet.current, {x: hangPoint.current.position.x, y: magnet.current.position.y});
                }

            }
        };

        if (oscillationIntervalID.current) {
            clearInterval(oscillationIntervalID.current);
        }

        oscillationIntervalID.current = setInterval(moveHangPoint, 1000 / 60); // 60 FPS
    };

    const createSwing = async (initialX: number) => {
        try {
            const hangPointTexture = await loadImage("/images/hang-point.png");
            const magnetTexture = await loadImage("/images/magnet.png");

            hangPoint.current = Bodies.circle(initialX, LINE_HEIGHT - (LINE_HEIGHT * 1.5), 10, {
                isStatic: true,
                render: {
                    fillStyle: "transparent",
                    sprite: {
                        texture: hangPointTexture,
                        xScale: 0.33,
                        yScale: 0.33,
                    }
                }
            });

            magnet.current = Bodies.rectangle(
                hangPointPosition.current?.x ? hangPointPosition.current.x : initialX,
                hangPointPosition?.current?.y ? hangPointPosition.current.y + LINE_HEIGHT : LINE_HEIGHT - (LINE_HEIGHT * 1.5), 60, 20, {
                    isStatic: false,


                    mass: 0.5, // Reduced mass
                    inertia: Infinity, // Less resistance to rotation
                    density: 0.006, // Increased density
                    frictionAir: 0.05, // Slightly increased air friction

                    render: {
                        fillStyle: "#000",
                        sprite: {
                            texture: magnetTexture,
                            xScale: 1,
                            yScale: 1
                        }
                    },
                    // density: 0.004,
                    // frictionAir: 0.03,
                });

            magnetConstraint.current = Constraint.create({
                bodyA: hangPoint.current,
                bodyB: magnet.current,
                stiffness: 1, // Increased stiffness for less flexibility
                type: 'pin',
                length: LINE_HEIGHT,
                render: {
                    visible: true,
                    lineWidth: 2,
                    strokeStyle: '#000',
                }
            });

            World.add(engine.current.world, [
                hangPoint.current as unknown as Constraint,
                magnetConstraint.current,
                magnet.current as unknown as Constraint,
            ]);
        } catch (error) {
            console.log("Error loading image:", error);
        }
    };


    const smoothMoveCamera = (dy: number, duration: number, onComplete: () => void) => {
        console.log("Smooth move camera")
        const frames = 60; // Number of frames for smooth transition
        const dyPerFrame = dy / frames; // Distance to move per frame

        let currentFrame = 0;

        const moveStep = () => {
            if (currentFrame < frames) {
                setGroundY(prevGroundY => prevGroundY - dyPerFrame); // Update the ground Y position

                Bounds.translate(render.current!.bounds, {x: 0, y: -dyPerFrame});
                currentFrame++;
                requestAnimationFrame(moveStep);
            } else {
                onComplete();
            }
        };

        requestAnimationFrame(moveStep);
    };

    const moveCameraUp = (dy: number) => {
        console.log("Move camera up")
        smoothMoveCamera(dy, 1000, () => {
        });

        const initialX = magnetPosition.current ? magnetPosition.current.x : width / 2;
        const initialY = magnetPosition.current ? magnetPosition.current.y - 20 : 180;
        createBlock(initialX, initialY).then();

    };


    useEffect(() => {
        if (total === bConunt) {
            setGameOver(true);
        }
    }, [bConunt, total]);


    useEffect(() => {
        if (gameOver) {
            handleStopGame(new Event(''));
            buildHandler({
                pos_x: Number(x),
                pos_y: Number(y),
                combo_count: comboCount.current,
                high_quality_count: highQualityCount.current,
                medium_quality_count: mediumQualityCount.current,
                low_quality_count: lowQualityCount.current
            }).then((res) => {
                //@ts-ignore
                setAreas((prevAreas) => {
                    return prevAreas?.map((area) => {
                        if (area.pos_x === Number(x) && area.pos_y === Number(y)) {
                            return res;
                        }
                        return area;
                    });
                })

            }).catch(() => {

            })
        }
    }, [gameOver]);


    const dropBlock = () => {
        if (intervalID.current) {
            clearInterval(intervalID.current);
        }
        if (constraint.current) {
            World.remove(engine.current.world, constraint.current);
            constraint.current = null;
            isDropping.current = true;
        }
        if (box.current.length > 0) {
            const lastBox = box.current[box.current.length - 1];
            Body.setVelocity(lastBox, {x: 0, y: 5});
            Body.setAngularVelocity(lastBox, 0);
            Body.setInertia(lastBox, 10000);
        }
    };

    const isOverlappingEnough = (lastBox: Matter.Body, otherBox: Matter.Body, threshold: number) => {
        const lastBoxBounds = lastBox.bounds;
        const otherBoxBounds = otherBox.bounds;

        const overlapMinX = Math.max(lastBoxBounds.min.x, otherBoxBounds.min.x);
        const overlapMaxX = Math.min(lastBoxBounds.max.x, otherBoxBounds.max.x);

        const overlapWidth = overlapMaxX - overlapMinX;
        const overlapPercentage = overlapWidth / (lastBoxBounds.max.x - lastBoxBounds.min.x);
        console.log("Overlap percentage", overlapPercentage)
        return overlapPercentage >= threshold;
    };


    const isOverlappingEnough2 = (lastBox: Matter.Body, otherBox: Matter.Body) => {
        const lastBoxBounds = lastBox.bounds;
        const otherBoxBounds = otherBox.bounds;

        const overlapMinX = Math.max(lastBoxBounds.min.x, otherBoxBounds.min.x);
        const overlapMaxX = Math.min(lastBoxBounds.max.x, otherBoxBounds.max.x);


        const overlapWidth = overlapMaxX - overlapMinX;
        return overlapWidth / (lastBoxBounds.max.x - lastBoxBounds.min.x);
    };


    const checkIfResting = () => {
            if (box.current.length > 0) {

                const lastBox = box.current[box.current.length - 1];

                const velocity = lastBox.velocity;
                const speed = Math.sqrt(velocity.x * velocity.x + velocity.y * velocity.y);
                if (box.current.length > 1) {
                    const prevBox = box.current[box.current.length - 2];
                    if (isTouching(lastBox, prevBox)) {
                        if (isOverlappingEnough(lastBox, prevBox, 0.49)) {
                            Body.setAngle(lastBox, 0);
                            Body.setAngularVelocity(lastBox, 0);

                        }
                        if (Math.abs(lastBox.angle) > Math.PI / 6) {
                            Body.setAngle(lastBox, lastBox.angle > 0 ? Math.PI / 6 : -Math.PI / 6);
                        }

                    }

                }


                if (isDropping.current && speed < 0.1 && (box.current.length >= 1)) {
                    Events.off(engine.current, 'afterUpdate', checkIfResting);
                    isDropping.current = false;
                    if (box.current.length > 1) {
                        const lastBox = box.current[box.current.length - 1];
                        const prevBox = box.current[box.current.length - 2];
                        if (isOverlappingEnough(lastBox, prevBox, 0.2)) {

                            const overlapTo = isOverlappingEnough2(lastBox, prevBox)
                            if (overlapTo >= 0.95) {
                                comboCount.current += 1;
                                Body.setPosition(lastBox, {x: prevBox.position.x, y: lastBox.position.y});
                                for (let i = 0; i < 5; i++) { // 5 umbrellas for combos
                                    setTimeout(() => {
                                        const direction = Math.random() > 0.5 ? 'left' : 'right';
                                        createUmbrellaPerson(lastBox.position.x, lastBox.position.y - 100, direction, i);
                                    }, i * 200)
                                }
                            } else if (overlapTo > 0.8) {
                                highQualityCount.current += 1;
                                for (let i = 0; i < 3; i++) { // 3 umbrellas for high quality
                                    setTimeout(() => {
                                        const direction = Math.random() > 0.5 ? 'left' : 'right';
                                        createUmbrellaPerson(lastBox.position.x, lastBox.position.y - 100, direction, i);
                                    },i * 200)
                                }
                            } else if (overlapTo >= 0.5) {
                                mediumQualityCount.current += 1;

                                const direction = Math.random() > 0.5 ? 'left' : 'right';
                                setTimeout(() => {

                                    createUmbrellaPerson(lastBox.position.x, lastBox.position.y - 100, direction, 0);
                                },  100)

                            } else if (overlapTo > 0.1) {
                                lowQualityCount.current += 1;
                            }

                            haptic.impactOccurred('medium')
                        }

                    }


                    if (isTouching(lastBox, ground.current!!) && isFitstBloackOnGround.current) {
                        handleLifeLost(lastBox);
                        isDonotMoveCamera.current = true;
                    }


                    if (isTouching(lastBox, ground.current!!) && !comboCount.current) {
                        comboCount.current += 1;
                        isFitstBloackOnGround.current = true;

                        haptic.impactOccurred('medium')
                    }


                    box.current.forEach(b => {
                        Body.setVelocity(b, {x: 0, y: 0});
                        Body.setInertia(b, Infinity);
                        Body.setAngle(b, 0);
                        Body.setAngularVelocity(b, 0);
                    });


                    if (!isDonotMoveCamera.current) {
                        if (box.current.length === 3) {
                            moveCameraUp(80);
                        } else {
                            moveCameraUp(60);
                        }
                    } else {
                        moveCameraUp(0);
                        isDonotMoveCamera.current = false;
                    }

                } else if (lastBox.position.y > height && box.current.length > 0) {

                    if (box.current.length === 1) {
                        setLife(prevLife => {
                            const newLife = prevLife - 1;
                            return newLife;
                        });
                    } else {
                        handleLifeLost(lastBox);
                    }
                    moveCameraUp(10);
                    Events.off(engine.current, 'afterUpdate', checkIfResting);
                }

            }
        }
    ;

    const handleLifeLost = (lastBox: Matter.Body) => {

        setLife(prevLife => {
            const newLife = prevLife - 1;
            return newLife;
        });
        World.remove(engine.current.world, lastBox);
        box.current.pop();
    };


    useEffect(() => {
        if (life === 0) {
            handleStopGame(new Event(''));
        }
    }, [life]);

    const isTouching = (lastBox: Matter.Body, prevBox: Matter.Body) => {
        const lastBoxBounds = lastBox.bounds;
        const prevBoxBounds = prevBox.bounds;
        const blockHeight = lastBoxBounds.max.y - lastBoxBounds.min.y;

        const verticalTouch = (prevBox.position.y >= lastBox.position.y - blockHeight) && (prevBox.position.y <= lastBox.position.y + blockHeight);

        const horizontalOverlap =
            lastBoxBounds.max.x > prevBoxBounds.min.x &&
            lastBoxBounds.min.x < prevBoxBounds.max.x;

        return horizontalOverlap && verticalTouch;
    };


    return (
        <div style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            textAlign: 'center',
            height: '100vh',
            width: '100%',
            backgroundImage: 'url(/images/bg.png)',
            position: 'relative',
            overflow: 'hidden',
        }}>
            {isLoading &&
                <Loader>
                    loading
                </Loader>
            }
            <Relative>
                <div ref={scene}/>

                <Life full={life}/>
                <Scale total={total} current={bConunt}/>
                {comboCount.current + highQualityCount.current + mediumQualityCount.current + lowQualityCount.current > 1 &&

                    <ActionScale ref={actionScaleRef}/>
                }
                <StopButton ref={stopButtonRef} onTouchStart={(e) => {
                    e.stopPropagation();
                    e.preventDefault();
                    if (isRunning) {
                        handleStopGame(e)
                        setIsRunning(prevState => !prevState);

                    } else {
                        handleStartGame(e)
                    }
                }}>
                    {isRunning ? "Pause" : "Resume"}

                </StopButton>
            </Relative>
            <ActionModal
                onClick={() => {
                    navigate('/')
                }}
                color={'linear-gradient(0deg, #B2282D 0%, #ED1C24 99.86%)'}
                borderColor={'transparent'}
                text={'Leave'}
                title={'Game Over'}
                open={life === 0}
                onClose={() => {
                    navigate('/')
                }}
            />

            <UWinModal
                onClose={() => {
                    navigate('/')
                }}

                combo={comboCount.current}
                high={highQualityCount.current}
                middle={mediumQualityCount.current}
                low={lowQualityCount.current}
                open={gameOver}/>
            {/*@ts-ignore*/}
            <PauseModal open={!isRunning} onPause={handleStartGame} onLeave={() => {
                navigate('/')
            }}/>
        </div>
    );
};

export default MatterGame;


const Loader = styled.div`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    z-index: 100000;
    background: black;
    width: 100%;
    height: 100%;
    color: white;
`;

const Relative = styled.div`
    position: relative;
    width: 100%;
    box-sizing: border-box;
    overflow: hidden;
`;

const Bottom = styled.div<{ groundY: number }>`
    position: absolute;
    left: 50%;
    bottom: ${(props) => props.groundY}px; // Adjust top position dynamically
    transform: translateX(-50%);
    width: 100%;
    transition: transform 0.3s ease-out; // Add transition for smooth movement
    display: flex;
    justify-content: center;
    align-items: center;
`;


const StopButton = styled.div`
    position: absolute;
    top: 20px;
    right: 10px;
    display: flex;
    width: 70px;
    height: 32px;
    min-width: 50px;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    z-index: 1000000;
    border-radius: 12px;
    border: 1px solid #9B0000;
    background: linear-gradient(0deg, #B2282D 0%, #ED1C24 99.86%);
    color: #FFF;
    text-align: center;
    font-size: 15px;
    font-family: American Captain;
    font-style: normal;
    font-weight: 500;
    letter-spacing: 1px;
    text-transform: uppercase;
    padding-top: 2px;

`;


const SText = styled.div`
    color: #FFF;
    text-align: center;
    font-size: 15px;
    font-family: American Captain;
    font-style: normal;
    font-weight: 500;
    letter-spacing: 1px;
    text-transform: uppercase;
    margin-top: 2px;
`