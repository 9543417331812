import {IconProps} from "../../model";

export const PlayIcon = (props: IconProps) => {
    return (
        <svg
            {...props}
            xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30" fill="none">
            <path fill-rule="evenodd" clip-rule="evenodd"
                  d="M3.27044 -0.00392914H26.7296C27.6258 -0.00392914 28.4434 0.365569 29.0409 0.955191C29.6305 1.55268 30 2.37028 30 3.2665V26.7256C30 27.6219 29.6305 28.4395 29.0409 29.037C28.4513 29.6344 27.6258 30.0039 26.7296 30.0039H3.27044C2.37422 30.0039 1.5566 29.6344 0.95912 29.0448C0.369498 28.4473 0 27.6297 0 26.7335V3.27437C0 2.37814 0.369498 1.56053 0.95912 0.963049C1.54874 0.365564 2.36635 -0.00392914 3.27044 -0.00392914ZM26.7296 2.1816H3.27044C2.9717 2.1816 2.69655 2.29952 2.50001 2.49606C2.30347 2.70047 2.18553 2.96776 2.18553 3.2665V26.7256C2.18553 27.0244 2.30347 27.2995 2.50001 27.4961C2.69655 27.6926 2.9717 27.8184 3.27044 27.8184H26.7296C27.0283 27.8184 27.3035 27.6926 27.5 27.5039C27.6965 27.2995 27.8145 27.0322 27.8145 26.7335V3.27437C27.8145 2.97563 27.6965 2.70047 27.5 2.50393C27.3035 2.30739 27.0283 2.1816 26.7296 2.1816Z"
                  fill="#0D647A"/>
            <path
                d="M26.7296 1.08887H3.27831C2.07127 1.08887 1.09277 2.06736 1.09277 3.2744V26.7257C1.09277 27.9327 2.07127 28.9112 3.27831 28.9112H26.7296C27.9366 28.9112 28.9151 27.9327 28.9151 26.7257V3.2744C28.9151 2.06736 27.9366 1.08887 26.7296 1.08887Z"
                fill="#19B8E0"/>
            <path fill-rule="evenodd" clip-rule="evenodd"
                  d="M3.27049 1.08887H26.7296C27.9324 1.08887 28.9073 2.07157 28.9073 3.2744V15.004H1.08496V3.2744C1.08496 2.07157 2.06766 1.08887 3.27049 1.08887Z"
                  fill="#30C3E9"/>
            <path fill-rule="evenodd" clip-rule="evenodd"
                  d="M22.7676 15.9473L16.2896 19.6894L16.1874 19.7444L9.81159 23.4237L8.55374 24.147L7.9248 24.5086V5.18468L8.55374 5.54633L9.81159 6.27745L16.2896 10.0196L16.6748 10.2476L22.7597 13.7617L24.0176 14.485L24.6465 14.8466L24.0176 15.2083L22.7597 15.9315L22.7676 15.9473ZM14.6465 17.7397L15.0317 17.5117L19.6229 14.8624L15.1339 12.268L15.0317 12.213L10.4484 9.56362V20.1611L14.6465 17.7397Z"
                  fill="#0D647A"/>
            <path fill-rule="evenodd" clip-rule="evenodd"
                  d="M22.1383 14.8546L15.6604 18.5967L9.18237 22.3389V14.8546V7.37817L15.6604 11.1203L22.1383 14.8546Z"
                  fill="white"/>
            <path fill-rule="evenodd" clip-rule="evenodd"
                  d="M7.91672 24.5165V16.1831L7.49219 25.452L20.3853 17.3231L7.91672 24.5165Z" fill="#1287A5"/>
        </svg>
    )
}