import styled from "styled-components";

interface BorderedTitleProps extends React.HTMLAttributes<HTMLDivElement> {
    title: string;

}

export const BorderedTitle = (props: BorderedTitleProps) => {
    return (
        <Text  {...props}>
            {props.title}
        </Text>
    )
}

const Text = styled.div`

  color: rgba(241, 172, 0, 1);
  font-size: 52px;
  line-height: 52px;
  letter-spacing: 1px;
  font-style: normal;
  font-weight: 400;
  text-transform: uppercase;
  text-shadow: 1px 1px #a13a06,
    -1px -1px 0 #a13a06,
    1px -1px 0 #a13a06,
  -1px 1px 0 #a13a06,
  0px 1px 0 #a13a06,
  1px 0px 0 #a13a06,
  -1px 0px 0 #a13a06,
    0px -1px 0 #a13a06;
`