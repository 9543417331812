import styled from "styled-components";


export interface ColorButtonProps {
    color: string,
    borderColor: string,
    onClick: () => void,
    text: string;
 }

export const ColorButton = ({color, borderColor, onClick, text}: ColorButtonProps) => {
    return (
        <Button   borderColor={borderColor} color={color} onClick={onClick}>
            {text}
        </Button>
    )
}


const Button = styled.div<{ color: string; borderColor: string; }>`
  display: flex;
  width: 100%;
  height: 44px;
  min-width: 50px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 12px;
  border: 1px solid ${props => props.borderColor};
  background: ${props => props.color};
  color: #FFF;
  text-align: center;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 100% */
  letter-spacing: 1px;
  text-transform: uppercase;
  padding-top: 4px;
`