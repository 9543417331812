import {create} from 'zustand';
import {Area} from "../api/requests/area";

type AreasState = {
    areas: Area[] | null;
    setAreas: (areas:(prev: Area[]) => Area[] | null) => void;
};

export const useAreasStore = create<AreasState>((set) => ({
    areas: null,
    setAreas: (areas) => set((state) => ({areas: areas(state.areas!!)})),
}));
