import styled from "styled-components";
import {BottomNav} from "../bottom-nav";
import {BorderedTitle} from "../bordered-title";
import {ThreeDBlockIcon} from "../icons/sd-block";
import {PlusIcon} from "../icons/plus";
import {CLockIcon} from "../icons/clock";
import {PlayIcon} from "../icons/play-icon";
import {useChannelsStore} from "../../store/channels-store";
import {useLinksStore} from "../../store/links-store";
import {Link, useNavigate} from "react-router-dom";
import {postEvent} from "@telegram-apps/sdk";
import {tgLink} from "../utils/tg-link";
import {useFriendsStore} from "../../store/friends-store";
import {CheckedIcon} from "../icons/checked-icon";
import React from "react";
import {useUserStore} from "../../store/user-store";
import {linkHandler} from "../../api/requests/channels";
import {useTaskStore} from "../../store/task-store";
import {OnboardingModal} from "./onboarding";


export const EarnView = () => {

    const {channels, setChannels} = useChannelsStore()
    const {links, setLinks} = useLinksStore()
    const {friends} = useFriendsStore()
    const {tasks} = useTaskStore()
    const {setUser} = useUserStore()

    const nav = useNavigate()

    return (

        <Wrapper>
            <Container>
                <div style={{width: '100%', height: 64}}/>
                <Col>
                    <BorderedTitle title={'earn'}/>
                    <List>
                        <SubTitle>
                            Daily
                        </SubTitle>
                        <DaylyItem
                            onCopy={() => {
                                navigator.clipboard.writeText(friends?.friends_share_link || '')
                            }}
                            onClick={() => tgLink(friends?.friends_share_link || '')}/>
                    </List>


                    <List style={{marginBottom: 25}}>
                        <SubTitle>
                            Projects
                        </SubTitle>
                        {tasks?.map((channel, index) => (
                            <TaskItem
                                isAvailible={true}
                                onClick={() => {
                                    nav(`/task/${channel.id}`)
                                }}
                                key={index} channel={channel.name}
                            />
                        ))}
                    </List>


                    <List style={{marginBottom: 25}}>
                        <SubTitle>
                            Channels
                        </SubTitle>
                        {channels?.map((channel, index) => (
                            <EarnItem
                                isAvailible={channel.is_available}
                                onClick={() => {
                                    setChannels(channels.map((ch) => {
                                        if (ch.id === channel.id) {
                                            ch.is_available = false
                                        }
                                        return ch
                                    }))
                                    tgLink(channel.invite_link)
                                }}
                                key={index} channel={channel.title}
                                value={channel.reward}/>
                        ))}
                    </List>
                    <List style={{paddingBottom: 100}}>
                        <SubTitle>
                            Bots
                        </SubTitle>
                        {links?.map((channel, index) => (
                            <EarnItem
                                isAvailible={channel.is_available}
                                onClick={async () => {


                                    const response = await linkHandler(channel.id)

                                    setUser((user) => {
                                        return {
                                            ...user,
                                            balance: response.new_balance
                                        }
                                    })

                                    setLinks(links.map((ch) => {
                                        if (ch.id === channel.id) {
                                            ch.is_available = false
                                        }
                                        return ch
                                    }))
                                    tgLink(channel.link)
                                }}
                                key={index}
                                channel={channel.name}
                                value={channel.reward}/>
                        ))}
                    </List>
                </Col>
            </Container>
            <BottomNav/>

            <OnboardingModal text={'Invite friends to BLOXX\n' +
                '            and get a guaranteed\n' +
                '            bonus'} next={'/boost'}/>

        </Wrapper>

    )
}

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100vh;
    background-image: url('/images/dark-bg.png');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    overflow-y: auto; /* Enable vertical scrolling */
`;

const Container = styled.div`
    display: flex;
    flex-direction: column;
    overflow: auto; /* Ensure container can scroll */
    width: 100%;
    align-items: center;
    position: relative;
    justify-content: center;
    gap: 20px;
    padding-top: 54px;
    padding-bottom: 120px; /* Add padding to ensure the last items are visible */

`;


const Col = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    max-width: 320px;
    height: 100%;


`


const List = styled.div`
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: flex-start;
    gap: 15px;

`


interface EarnItemProps {
    channel: string;
    value?: number
    onClick: () => void
    isAvailible?: boolean
}

const EarnItem = ({channel, isAvailible, value, onClick}: EarnItemProps) => {
    return (
        <ThreeDContainer isAvailible={isAvailible} onClick={onClick}>
            <ItemRow>
                <EarnCol>
                    <ChannelText>
                        {channel}
                    </ChannelText>
                    {value &&
                        <ValueText>
                            +{value} BLOXX
                        </ValueText>
                    }
                </EarnCol>
                {!isAvailible ? <CheckedIcon/> : <PlusIcon/>}
            </ItemRow>
        </ThreeDContainer>
    )
}


const TaskItem = ({channel, isAvailible, value, onClick}: EarnItemProps) => {
    return (
        <ThreeDContainer isTask isAvailible={isAvailible} onClick={onClick}>
            <ItemRow>
                <EarnCol>
                    <ChannelText>
                        {channel}
                    </ChannelText>
                    {value &&
                        <ValueText>
                            +{value} BLOXX
                        </ValueText>
                    }
                </EarnCol>
                {!isAvailible ? <CheckedIcon/> : <PlusIcon/>}
            </ItemRow>
        </ThreeDContainer>
    )
}


const ThreeDContainer = styled.div<{ isAvailible?: boolean; isTask?: boolean }>`
    box-sizing: border-box;
    border-radius: 3px;
    border: 1px solid #e87c18;
    background: ${props => !props.isAvailible ? 'rgba(255, 192, 48, 1)' : 'rgba(15, 112, 136, 0.80)'};
    box-shadow: 0px 5px 0px 0px #C24606;
    display: flex;
    width: 100%;
    padding: ${props => props.isTask ? '18px 0' : '12px 0'};
    flex-direction: column;
    align-items: center;
    gap: 3px;
    text-decoration: none;
`


const DaylyItem = ({onClick, onCopy}: { onClick?: () => void; onCopy?: () => void }) => {
    return (
        <DaylyThreeDContainer onClick={onClick}>
            <ItemRow>
                <CLockIcon/>
                <DaylyText>
                    Invite 3 or more friends and earn 50.000 BLOXX
                </DaylyText>
                <svg
                    onClick={(e) => {
                        e.stopPropagation()


                    }}
                    style={{
                        zIndex: 1,
                        cursor: 'pointer'
                    }}
                    xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30" fill="none">
                    <path fill-rule="evenodd" clip-rule="evenodd"
                          d="M3.27044 -0.00390625H26.7296C27.6258 -0.00390625 28.4434 0.365592 29.0409 0.955214C29.6305 1.5527 30 2.3703 30 3.26652V26.7257C30 27.6219 29.6305 28.4395 29.0409 29.037C28.4513 29.6345 27.6258 30.004 26.7296 30.004H3.27044C2.37422 30.004 1.5566 29.6345 0.95912 29.0448C0.369498 28.4473 0 27.6297 0 26.7335V3.27439C0 2.37817 0.369498 1.56056 0.95912 0.963071C1.54874 0.365587 2.36635 -0.00390625 3.27044 -0.00390625ZM26.7296 2.18163H3.27044C2.9717 2.18163 2.69655 2.29955 2.50001 2.49609C2.30347 2.70049 2.18553 2.96778 2.18553 3.26652V26.7257C2.18553 27.0244 2.30347 27.2995 2.50001 27.4961C2.69655 27.6926 2.9717 27.8184 3.27044 27.8184H26.7296C27.0283 27.8184 27.3035 27.6926 27.5 27.5039C27.6965 27.2995 27.8145 27.0323 27.8145 26.7335V3.27439C27.8145 2.97565 27.6965 2.7005 27.5 2.50396C27.3035 2.30742 27.0283 2.18163 26.7296 2.18163Z"
                          fill="#0D647A"/>
                    <path
                        d="M26.7296 1.08887H3.27831C2.07127 1.08887 1.09277 2.06736 1.09277 3.2744V26.7257C1.09277 27.9327 2.07127 28.9112 3.27831 28.9112H26.7296C27.9366 28.9112 28.9151 27.9327 28.9151 26.7257V3.2744C28.9151 2.06736 27.9366 1.08887 26.7296 1.08887Z"
                        fill="#19B8E0"/>
                    <path fill-rule="evenodd" clip-rule="evenodd"
                          d="M3.27049 1.08887H26.7296C27.9324 1.08887 28.9073 2.07157 28.9073 3.2744V15.004H1.08496V3.2744C1.08496 2.07157 2.06766 1.08887 3.27049 1.08887Z"
                          fill="#30C3E9"/>
                    <g clip-path="url(#clip0_606_11126)">
                        <g filter="url(#filter0_d_606_11126)">
                            <path
                                d="M16.6665 23.3334H8.33317C7.88656 23.3489 7.45356 23.1783 7.13757 22.8623C6.82159 22.5463 6.65098 22.1133 6.6665 21.6667V13.3334C6.65098 12.8868 6.82159 12.4538 7.13757 12.1378C7.45356 11.8218 7.88656 11.6512 8.33317 11.6667H11.6665V8.33341C11.651 7.88681 11.8216 7.45381 12.1376 7.13782C12.4536 6.82183 12.8866 6.65123 13.3332 6.66675H21.6665C22.1131 6.65123 22.5461 6.82183 22.8621 7.13782C23.1781 7.45381 23.3487 7.88681 23.3332 8.33341V16.6667C23.3484 17.1133 23.1777 17.5461 22.8618 17.8621C22.5459 18.178 22.113 18.3487 21.6665 18.3334H18.3332V21.6667C18.3484 22.1133 18.1778 22.5461 17.8618 22.8621C17.5459 23.178 17.113 23.3487 16.6665 23.3334ZM8.33317 13.3334V21.6667H16.6665V18.3334H13.3332C12.8866 18.3487 12.4538 18.178 12.1378 17.8621C11.8219 17.5461 11.6512 17.1133 11.6665 16.6667V13.3334H8.33317ZM13.3332 8.33341V16.6667H21.6665V8.33341H13.3332Z"
                                fill="white"/>
                            <path
                                d="M6.78402 23.2159C7.19586 23.6277 7.75939 23.8511 8.34128 23.8334H16.6585C17.2403 23.8508 17.8036 23.6274 18.2154 23.2156C18.6271 22.8039 18.8506 22.2405 18.8332 21.6588V18.8334H21.6585C22.2403 18.8508 22.8036 18.6274 23.2154 18.2156C23.6271 17.8039 23.8506 17.2405 23.8332 16.6588V8.34152C23.8508 7.75964 23.6275 7.1961 23.2156 6.78427C22.8038 6.37243 22.2403 6.14908 21.6584 6.16675H13.3413C12.7594 6.14908 12.1959 6.37243 11.784 6.78427C11.3722 7.1961 11.1488 7.75963 11.1665 8.3415V11.1667H8.34126C7.75938 11.1491 7.19585 11.3724 6.78402 11.7843C6.37219 12.1961 6.14883 12.7596 6.1665 13.3415V21.6586C6.14883 22.2405 6.37219 22.8041 6.78402 23.2159ZM16.1665 21.1667H8.83317V13.8334H11.1665V16.6588C11.1491 17.2405 11.3726 17.8039 11.7843 18.2156C12.196 18.6274 12.7594 18.8508 13.3411 18.8334H16.1665V21.1667ZM21.1665 16.1667H13.8332V8.83341H21.1665V16.1667Z"
                                stroke="#0D647A"/>
                        </g>
                    </g>
                    <defs>
                        <filter id="filter0_d_606_11126" x="5.66553" y="5.66577" width="18.6685" height="19.6687"
                                filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                            <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                            <feColorMatrix in="SourceAlpha" type="matrix"
                                           values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                            {/*<feOffset dy="1"/>*/}
                            <feComposite in2="hardAlpha" operator="out"/>
                            <feColorMatrix type="matrix"
                                           values="0 0 0 0 0.0705882 0 0 0 0 0.529412 0 0 0 0 0.647059 0 0 0 1 0"/>
                            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_606_11126"/>
                            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_606_11126"
                                     result="shape"/>
                        </filter>
                        <clipPath id="clip0_606_11126">
                            <rect width="20" height="20" fill="white" transform="translate(5 5)"/>
                        </clipPath>
                    </defs>
                </svg>
                <PlayIcon/>
            </ItemRow>
        </DaylyThreeDContainer>
    )
}


const DaylyThreeDContainer = styled.div`
    box-sizing: border-box;
    border-radius: 3px;
    border: 1px solid #e87c18;
    background: #FFB500;
    box-shadow: 0px 5px 0px 0px #C24606;
    display: flex;
    width: 100%;
    padding: 8px 0;
    flex-direction: column;
    align-items: center;
    gap: 3px;
    margin-bottom: 25px;
`

const DaylyText = styled.div`
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px;
    text-transform: uppercase;
    text-shadow: 1px 1px #C96814,
    -1px -1px 0 #C96814,
    1px -1px 0 #C96814,
    -1px 1px 0 #C96814,
    0px 1px 0 #C96814,
    1px 0px 0 #C96814,
    -1px 0px 0 #C96814,
    0px -1px 0 #C96814;
    color: white;
    text-align: left;
    width: 70%;
`

const ChannelText = styled.div`
    color: #FFF;
    font-family: Roboto;
    font-size: 15px;
    font-style: normal;
    font-weight: 700;
    line-height: 19px;
    letter-spacing: -0.4px;
    text-transform: uppercase;

`


const ValueText = styled.div`
    color: #FFF;
    font-family: Roboto;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 153.846% */
    letter-spacing: -0.23px;
`

const ItemRow = styled.div`
    display: flex;
    padding: 0px 16px;
    align-items: center;
    gap: 16px;
    flex-shrink: 0;
    width: 100%;
    justify-content: space-between;
    box-sizing: border-box;
`

const EarnCol = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 2px;
    align-self: stretch;
    width: 80%;
`

const SubTitle = styled.div`
    color: #FFF;
    text-shadow: 0px 2px 2px rgba(0, 0, 0, 0.20);
    font-family: Roboto;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 25px; /* 125% */
    text-transform: uppercase;
`


