import {IconProps} from "../../../model";

export const InviteIcon = (props: IconProps) => {
    return (
        <svg
            {...props}
            xmlns="http://www.w3.org/2000/svg" width="26" height="29" viewBox="0 0 26 29" fill="none">
            <g clip-path="url(#clip0_61_1291)">
                <path fill-rule="evenodd" clip-rule="evenodd"
                      d="M2.07833 4.76501H23.5783C24.5983 4.76501 25.4283 5.59501 25.4283 6.61501V26.525C25.4283 27.545 24.5983 28.375 23.5783 28.375H2.07833C1.05833 28.375 0.228333 27.545 0.228333 26.525V6.61501C0.228333 5.59501 1.05833 4.76501 2.07833 4.76501Z"
                      fill="#C24606"/>
                <path fill-rule="evenodd" clip-rule="evenodd"
                      d="M2.97833 0.625H22.6883C23.4383 0.625 24.1283 0.935 24.6283 1.435C25.1283 1.935 25.4383 2.625 25.4383 3.375V23.085C25.4383 23.835 25.1283 24.525 24.6283 25.025C24.1283 25.525 23.4383 25.835 22.6883 25.835H2.97833C2.22833 25.835 1.53833 25.525 1.03833 25.025C0.538333 24.525 0.228333 23.835 0.228333 23.085V3.375C0.228333 2.625 0.538333 1.935 1.03833 1.435C1.53833 0.935 2.21833 0.625 2.97833 0.625ZM22.6883 2.455H2.97833C2.72833 2.455 2.49833 2.555 2.32833 2.725C2.16833 2.895 2.05833 3.125 2.05833 3.375V23.085C2.05833 23.335 2.15833 23.565 2.32833 23.735C2.49833 23.905 2.71833 24.005 2.97833 24.005H22.6883C22.9383 24.005 23.1683 23.905 23.3383 23.735C23.4983 23.565 23.6083 23.335 23.6083 23.085V3.375C23.6083 3.125 23.5083 2.895 23.3383 2.725C23.1683 2.555 22.9483 2.455 22.6883 2.455Z"
                      fill="#E87D18"/>
                <path
                    d="M22.6883 1.54501H2.97833C1.96765 1.54501 1.14833 2.36433 1.14833 3.37501V23.085C1.14833 24.0957 1.96765 24.915 2.97833 24.915H22.6883C23.699 24.915 24.5183 24.0957 24.5183 23.085V3.37501C24.5183 2.36433 23.699 1.54501 22.6883 1.54501Z"
                    fill="#FFB500"/>
                <path fill-rule="evenodd" clip-rule="evenodd"
                      d="M2.97833 1.54501H22.6883C23.6983 1.54501 24.5183 2.36501 24.5183 3.37501V13.225H1.14833V3.37501C1.14833 2.36501 1.96833 1.54501 2.97833 1.54501Z"
                      fill="#FFC030"/>
                <path fill-rule="evenodd" clip-rule="evenodd"
                      d="M3.92833 2.61501H23.6383C23.9183 2.61501 24.1883 2.67501 24.4183 2.79501C24.1783 2.06501 23.4883 1.54501 22.6783 1.54501H2.97833C1.96833 1.54501 1.14833 2.36501 1.14833 3.37501V23.085C1.14833 23.815 1.57833 24.445 2.19833 24.745C2.13833 24.565 2.09833 24.365 2.09833 24.165V4.44501C2.09833 3.43501 2.91833 2.61501 3.92833 2.61501Z"
                      fill="#FFCE61"/>
                <path fill-rule="evenodd" clip-rule="evenodd"
                      d="M3.67833 2.495C4.10833 2.495 4.50833 2.675 4.78833 2.955C5.07833 3.24499 5.24833 3.635 5.24833 4.065C5.24833 4.495 5.06833 4.895 4.78833 5.175C4.49833 5.465 4.10833 5.635 3.67833 5.635C3.24833 5.635 2.84833 5.455 2.56833 5.175C2.27833 4.885 2.10833 4.495 2.10833 4.065C2.10833 3.635 2.28833 3.235 2.56833 2.955C2.85833 2.665 3.24833 2.495 3.67833 2.495ZM4.46833 3.285C4.26833 3.085 3.98833 2.955 3.67833 2.955C3.36833 2.955 3.08833 3.085 2.88833 3.285C2.68833 3.485 2.55833 3.765 2.55833 4.075C2.55833 4.385 2.68833 4.66499 2.88833 4.865C3.08833 5.065 3.36833 5.195 3.67833 5.195C3.98833 5.195 4.26833 5.075 4.46833 4.865C4.66833 4.66499 4.79833 4.385 4.79833 4.075C4.79833 3.765 4.67833 3.485 4.46833 3.285Z"
                      fill="#1A1A1A"/>
                <path fill-rule="evenodd" clip-rule="evenodd"
                      d="M3.67833 2.72501C4.41833 2.72501 5.02833 3.32501 5.02833 4.07501C5.02833 4.82501 4.42833 5.42501 3.67833 5.42501C2.92833 5.42501 2.32833 4.82501 2.32833 4.07501C2.32833 3.32501 2.92833 2.72501 3.67833 2.72501Z"
                      fill="#4D4D4D"/>
                <path d="M4.59964 3.48404L2.60721 3.96643L2.77193 4.64677L4.76436 4.16439L4.59964 3.48404Z"
                      fill="#1A1A1A"/>
                <path fill-rule="evenodd" clip-rule="evenodd"
                      d="M3.81833 6.01499C3.13833 6.01499 2.54833 5.60499 2.29833 5.01499C2.58833 5.48499 3.11833 5.80499 3.70833 5.80499C4.62833 5.80499 5.36833 5.06499 5.36833 4.14499C5.36833 3.91499 5.31833 3.68499 5.22833 3.48499C5.38833 3.73499 5.47833 4.03499 5.47833 4.35499C5.47833 5.27499 4.73833 6.01499 3.81833 6.01499Z"
                      fill="#FFCE61"/>
                <path fill-rule="evenodd" clip-rule="evenodd"
                      d="M21.9183 2.495C22.3483 2.495 22.7483 2.675 23.0283 2.955C23.3183 3.24499 23.4883 3.635 23.4883 4.065C23.4883 4.495 23.3083 4.895 23.0283 5.175C22.7483 5.465 22.3483 5.635 21.9183 5.635C21.4883 5.635 21.0883 5.455 20.8083 5.175C20.5183 4.885 20.3483 4.495 20.3483 4.065C20.3483 3.635 20.5283 3.235 20.8083 2.955C21.0983 2.665 21.4883 2.495 21.9183 2.495ZM22.7083 3.285C22.5083 3.085 22.2283 2.955 21.9183 2.955C21.6083 2.955 21.3283 3.075 21.1283 3.285C20.9283 3.485 20.7983 3.765 20.7983 4.075C20.7983 4.385 20.9183 4.66499 21.1283 4.865C21.3283 5.065 21.6083 5.195 21.9183 5.195C22.2283 5.195 22.5083 5.065 22.7083 4.865C22.9083 4.66499 23.0383 4.385 23.0383 4.075C23.0383 3.765 22.9083 3.485 22.7083 3.285Z"
                      fill="#1A1A1A"/>
                <path fill-rule="evenodd" clip-rule="evenodd"
                      d="M21.9183 2.72501C22.6583 2.72501 23.2683 3.32501 23.2683 4.07501C23.2683 4.82501 22.6683 5.42501 21.9183 5.42501C21.1683 5.42501 20.5683 4.82501 20.5683 4.07501C20.5683 3.32501 21.1683 2.72501 21.9183 2.72501Z"
                      fill="#4D4D4D"/>
                <path d="M21.0578 3.42155L20.8349 4.08514L22.7783 4.73765L23.0011 4.07406L21.0578 3.42155Z"
                      fill="#1A1A1A"/>
                <path fill-rule="evenodd" clip-rule="evenodd"
                      d="M22.0683 6.01499C21.3883 6.01499 20.7983 5.60499 20.5483 5.01499C20.8383 5.48499 21.3683 5.80499 21.9583 5.80499C22.8783 5.80499 23.6183 5.06499 23.6183 4.14499C23.6183 3.91499 23.5683 3.68499 23.4783 3.48499C23.6383 3.73499 23.7283 4.03499 23.7283 4.35499C23.7283 5.27499 22.9883 6.01499 22.0683 6.01499Z"
                      fill="#FFCE61"/>
                <path fill-rule="evenodd" clip-rule="evenodd"
                      d="M4.49833 20.925C4.85833 21.145 5.09833 21.485 5.20833 21.865C5.30833 22.255 5.26833 22.685 5.04833 23.055L5.02833 23.085C4.80833 23.445 4.46833 23.695 4.08833 23.795C3.69833 23.895 3.26833 23.855 2.89833 23.635L2.86833 23.615C2.50833 23.395 2.26833 23.055 2.15833 22.675C2.05833 22.305 2.09833 21.905 2.28833 21.535L2.31833 21.475C2.53833 21.095 2.88833 20.845 3.27833 20.745C3.64833 20.645 4.05833 20.685 4.41833 20.875H4.45833L4.50833 20.925H4.49833ZM4.75833 21.985C4.68833 21.715 4.50833 21.465 4.23833 21.305C3.96833 21.145 3.66833 21.115 3.38833 21.195C3.10833 21.265 2.86833 21.445 2.70833 21.715C2.55833 21.985 2.52833 22.285 2.59833 22.565C2.66833 22.845 2.84833 23.095 3.11833 23.245C3.38833 23.395 3.68833 23.435 3.95833 23.355C4.23833 23.285 4.47833 23.105 4.63833 22.835L4.65833 22.805C4.79833 22.545 4.82833 22.255 4.75833 21.985Z"
                      fill="#1A1A1A"/>
                <path fill-rule="evenodd" clip-rule="evenodd"
                      d="M4.34833 21.105C4.98833 21.475 5.20833 22.305 4.83833 22.945C4.46833 23.585 3.63833 23.805 2.99833 23.435C2.35833 23.065 2.13833 22.235 2.50833 21.595C2.87833 20.955 3.70833 20.735 4.34833 21.105Z"
                      fill="#4D4D4D"/>
                <path d="M4.02128 21.2421L2.77077 22.8665L3.32544 23.2935L4.57596 21.6691L4.02128 21.2421Z"
                      fill="#1A1A1A"/>
                <path fill-rule="evenodd" clip-rule="evenodd"
                      d="M3.81833 24.215C3.13833 24.215 2.54833 23.805 2.29833 23.215C2.58833 23.685 3.11833 24.005 3.70833 24.005C4.62833 24.005 5.36833 23.265 5.36833 22.345C5.36833 22.115 5.31833 21.885 5.22833 21.685C5.38833 21.935 5.47833 22.235 5.47833 22.555C5.47833 23.475 4.73833 24.215 3.81833 24.215Z"
                      fill="#FFCE61"/>
                <path fill-rule="evenodd" clip-rule="evenodd"
                      d="M21.9183 20.695C22.3483 20.695 22.7483 20.875 23.0283 21.155C23.3183 21.445 23.4883 21.835 23.4883 22.265C23.4883 22.695 23.3083 23.095 23.0283 23.375C22.7483 23.665 22.3483 23.835 21.9183 23.835C21.4883 23.835 21.0883 23.655 20.8083 23.375C20.5183 23.085 20.3483 22.695 20.3483 22.265C20.3483 21.835 20.5283 21.435 20.8083 21.155C21.0983 20.875 21.4883 20.695 21.9183 20.695ZM22.7083 21.485C22.5083 21.285 22.2283 21.155 21.9183 21.155C21.6083 21.155 21.3283 21.275 21.1283 21.485C20.9283 21.685 20.7983 21.965 20.7983 22.275C20.7983 22.585 20.9183 22.865 21.1283 23.065C21.3283 23.265 21.6083 23.395 21.9183 23.395C22.2283 23.395 22.5083 23.265 22.7083 23.065C22.9083 22.865 23.0383 22.585 23.0383 22.275C23.0383 21.965 22.9083 21.685 22.7083 21.485Z"
                      fill="#1A1A1A"/>
                <path fill-rule="evenodd" clip-rule="evenodd"
                      d="M21.9183 20.925C22.6583 20.925 23.2683 21.525 23.2683 22.275C23.2683 23.025 22.6683 23.625 21.9183 23.625C21.1683 23.625 20.5683 23.025 20.5683 22.275C20.5683 21.525 21.1683 20.925 21.9183 20.925Z"
                      fill="#4D4D4D"/>
                <path d="M21.3195 21.3734L20.8928 21.9283L22.5179 23.1779L22.9446 22.623L21.3195 21.3734Z"
                      fill="#1A1A1A"/>
                <path fill-rule="evenodd" clip-rule="evenodd"
                      d="M22.0683 24.215C21.3883 24.215 20.7983 23.805 20.5483 23.215C20.8383 23.685 21.3683 24.005 21.9583 24.005C22.8783 24.005 23.6183 23.265 23.6183 22.345C23.6183 22.115 23.5683 21.885 23.4783 21.685C23.6383 21.935 23.7283 22.235 23.7283 22.555C23.7283 23.475 22.9883 24.215 22.0683 24.215Z"
                      fill="#FFCE61"/>
                <path fill-rule="evenodd" clip-rule="evenodd"
                      d="M20.7983 19.245H17.0683V20.035H8.35833V19.245H3.89833V18.405C3.89833 17.585 3.93833 16.775 4.04833 16.015C4.15833 15.245 4.33833 14.525 4.60833 13.905C4.79833 13.455 5.04833 13.055 5.33833 12.715C5.50833 12.525 5.68833 12.355 5.88833 12.205C5.75833 12.105 5.62833 11.995 5.50833 11.885H5.49833V11.865C5.18833 11.555 4.93833 11.175 4.76833 10.755C4.59833 10.345 4.50833 9.89499 4.50833 9.43499C4.50833 8.97499 4.59833 8.52499 4.76833 8.11499C4.89833 7.80499 5.06833 7.51499 5.27833 7.25499H5.26833L5.51833 6.99499C6.13833 6.37499 7.00833 5.98499 7.96833 5.98499C8.21833 5.98499 8.46833 6.01499 8.70833 6.06499C8.92833 6.11499 9.13833 6.18499 9.33833 6.26499L9.40833 6.29499C9.56833 6.37499 9.72833 6.46499 9.88833 6.56499C9.93833 6.59499 9.97833 6.62499 10.0283 6.66499L10.1383 6.55499C10.4783 6.21499 10.8983 5.93499 11.3583 5.74499C11.7983 5.56499 12.2883 5.45499 12.7883 5.45499C13.2883 5.45499 13.7783 5.55499 14.2183 5.74499C14.6783 5.93499 15.0983 6.21499 15.4383 6.55499C15.4383 6.55499 15.5283 6.65499 15.5783 6.71499C15.6283 6.67499 15.6683 6.64499 15.7183 6.61499C15.8683 6.50499 16.0283 6.41499 16.1983 6.33499C16.4283 6.22499 16.6783 6.13499 16.9283 6.07499C17.1783 6.01499 17.4383 5.98499 17.6983 5.98499C18.1583 5.98499 18.6083 6.07499 19.0183 6.24499C19.4383 6.42499 19.8283 6.67499 20.1383 6.99499C20.7583 7.61499 21.1483 8.48499 21.1483 9.44499C21.1483 9.91499 21.0583 10.355 20.8883 10.765C20.7083 11.185 20.4583 11.565 20.1383 11.885C20.0083 12.015 19.8783 12.125 19.7283 12.235C19.8583 12.335 19.9783 12.445 20.0883 12.575C20.3483 12.855 20.5683 13.185 20.7583 13.545C21.0683 14.155 21.2783 14.865 21.4183 15.645C21.5783 16.525 21.6383 17.475 21.6383 18.405V19.245H20.7983Z"
                      fill="#E87D18"/>
                <path fill-rule="evenodd" clip-rule="evenodd"
                      d="M17.7483 12.405C16.6483 12.405 15.8983 12.905 15.3983 13.705C15.4783 13.825 15.5483 13.945 15.6083 14.075C16.1983 15.225 16.4383 16.795 16.4983 18.405H20.8083C20.8083 15.465 20.1983 12.405 17.7483 12.405ZM12.7783 6.29498C14.3883 6.29498 15.6983 7.60498 15.6983 9.21498C15.6983 10.825 14.3883 12.135 12.7783 12.135C11.1683 12.135 9.85833 10.825 9.85833 9.21498C9.85833 7.60498 11.1683 6.29498 12.7783 6.29498ZM12.8283 12.515C15.5583 12.515 16.2283 15.915 16.2283 19.195H9.19833C9.19833 15.725 9.86833 12.515 12.8283 12.515ZM7.95833 6.82498C6.50833 6.82498 5.33833 7.99498 5.33833 9.44498C5.33833 10.895 6.50833 12.065 7.95833 12.065C8.84833 12.065 9.62833 11.625 10.1083 10.945C9.78833 10.445 9.59833 9.84498 9.59833 9.20498C9.59833 8.67498 9.72833 8.17498 9.95833 7.73498C9.47833 7.17498 8.76833 6.81498 7.96833 6.81498L7.95833 6.82498ZM7.99833 12.405C5.33833 12.405 4.73833 15.295 4.73833 18.405H8.93833C8.98833 16.925 9.17833 15.525 9.65833 14.445C9.78833 14.145 9.93833 13.875 10.1083 13.625C9.63833 12.875 8.95833 12.405 8.00833 12.405H7.99833ZM17.6983 6.82498C16.8683 6.82498 16.1283 7.21498 15.6483 7.81498C15.8583 8.23498 15.9683 8.71498 15.9683 9.21498C15.9683 9.82498 15.7983 10.395 15.4983 10.875C15.9683 11.595 16.7783 12.065 17.6883 12.065C19.1383 12.065 20.3083 10.895 20.3083 9.44498C20.3083 7.99498 19.1383 6.82498 17.6883 6.82498H17.6983Z"
                      fill="url(#paint0_linear_61_1291)"/>
                <path fill-rule="evenodd" clip-rule="evenodd"
                      d="M8.36833 19.245H3.89833C3.89833 18.365 3.88833 17.665 3.98833 16.515C3.77833 17.545 3.68833 18.515 3.71833 19.495L8.35833 19.255L8.36833 19.245Z"
                      fill="#FFCE61"/>
                <path fill-rule="evenodd" clip-rule="evenodd"
                      d="M17.0683 20.035H8.35833L17.3383 20.295L17.2883 19.535H21.9383C21.8883 18.745 21.7883 17.985 21.5983 17.245C21.6283 17.805 21.6283 18.685 21.6283 19.245H17.0583V20.035H17.0683Z"
                      fill="#FFCE61"/>
                <path fill-rule="evenodd" clip-rule="evenodd"
                      d="M20.1383 12.275C21.2483 11.245 21.3683 10.395 21.1483 9.10498C21.2483 10.385 20.7483 11.455 19.7383 12.235C19.9483 12.415 20.0983 12.575 20.2383 12.735L20.1383 12.275Z"
                      fill="#FFCE61"/>
                <path fill-rule="evenodd" clip-rule="evenodd"
                      d="M5.47834 12.245C4.36834 11.215 4.28834 10.365 4.50834 9.07498C4.40834 10.355 4.86834 11.425 5.87834 12.205C5.66834 12.385 5.48834 12.525 5.34834 12.685L5.46834 12.235L5.47834 12.245Z"
                      fill="#FFCE61"/>
            </g>
            <defs>
                <linearGradient id="paint0_linear_61_1291" x1="12.7683" y1="19.195" x2="12.7683" y2="6.31498"
                                gradientUnits="userSpaceOnUse">
                    <stop/>
                    <stop offset="0.14" stop-color="#191A1A"/>
                    <stop offset="0.29" stop-color="#2D2E2E"/>
                    <stop offset="0.46" stop-color="#3B3C3D"/>
                    <stop offset="0.66" stop-color="#434445"/>
                    <stop offset="1" stop-color="#464748"/>
                </linearGradient>
                <clipPath id="clip0_61_1291">
                    <rect width="25.21" height="27.75" fill="white" transform="translate(0.228333 0.625)"/>
                </clipPath>
            </defs>
        </svg>
    )
}