import styled from "styled-components";
import React, {useRef, useEffect, useState} from "react";


interface ThreeDContainerProps {
    children: React.ReactNode;
    bordered?: boolean;
    mainColor?: string;
    borderColor?: string;
    height?: number;
    onClick?: () => void;
}

export const ThreeDContainer = ({children, bordered, mainColor, borderColor, height,onClick}: ThreeDContainerProps) => {
    const containerRef = useRef(null);
    const [dimensions, setDimensions] = useState({width: 0, height: 0});

    useEffect(() => {
        if (containerRef.current) {
            setDimensions({
                // @ts-ignore
                width: containerRef.current.offsetWidth,
                // @ts-ignore
                height: containerRef.current.offsetHeight,
            });
        }

    }, [containerRef.current]);


    return (
        <Relative
            onClick={onClick}
            style={{
                height: height ? height + 30 : '71px',
            }}

        >
            <ContainerBg
                style={{
                    width: dimensions.width,
                    height: dimensions.height,
                    borderRadius: bordered ? '12px' : '3px',


                }}
            />
            <Container
                mainColor={mainColor}
                borderColor={borderColor}
                style={{
                    height: height ? height : '43px',
                    borderRadius: bordered ? '12px' : '3px',


                }}
                ref={containerRef}
            >
                {children}
            </Container>
        </Relative>
    )
}

const Relative = styled.div`
  position: relative;
  width: 100%;
  //height: 71px;
  box-sizing: border-box;
`

const Container = styled.div<{ mainColor?: string; borderColor?: string }>`
  position: absolute;
  z-index: 10 !important;
  width: 100%;
  padding: 8px 0px 8px 0px;
  gap: 3px;
  border-radius: 12px;
  border: 1px solid ${props => props.borderColor || 'rgba(241, 172, 0, 1)'};
  background: ${props => props.mainColor || '#167e98'};
  display: flex;
  justify-content: center;
  align-items: center;
`

const ContainerBg = styled.div`
  z-index: 1;
  border-radius: 12px;
  background: #c24506;
  position: absolute;
  top: 5px;
`


