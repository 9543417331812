import styled, {keyframes} from "styled-components";
import {ColorButton} from "../color-button";
import {useNavigate} from "react-router-dom";
import {useEffect, useState} from "react";
import {useUserStore} from "../../store/user-store";

interface OnboardingProps {
    text: string;
    next: string;
    isLast?: boolean;
    onClick?: () => void;
    zIndex?: number;

}

export const OnboardingModal = ({text, onClick, zIndex, next, isLast}: OnboardingProps) => {
    const [isVisible, setIsVisible] = useState(false)
    const nav = useNavigate()
    const {user} = useUserStore()

    useEffect(() => {
        if (user?.is_onboarding_completed === false) {
            setIsVisible(true)
        } else {
            setIsVisible(false)
        }
    }, [user])

    if (!isVisible) {
        return null
    }

    return (
        <Wrapper style={{zIndex: zIndex}}>
            <Container>
                <SubTitle>
                    {text}
                </SubTitle>
                <ColorButton
                    onClick={() => {
                        if (onClick) {
                            onClick()
                        }
                        nav(next, {replace: true})
                    }}
                    color={'linear-gradient(0deg, #28B236 0%, #1CED63 99.86%)'}
                    borderColor={'transparent'}
                    text={isLast ? 'Finish' : 'Next'}/>
            </Container>
        </Wrapper>

    )
}


const fadeIn = keyframes`
    from {
        opacity: 0;
        transform: scale(0.8);
    }
    to {
        opacity: 1;
        transform: scale(1);
    }
`;


const SubTitle = styled.div`
    color: #FFF;
    text-align: center;
    font-family: Roboto;
    font-size: 22px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px; /* 123.077% */
    letter-spacing: -0.078px;
    margin-top: 30px;
    line-height: 30px;
`


const Wrapper = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: flex-end;
    justify-content: space-around;
    z-index: 10000;
`


const Container = styled.div`
    display: flex;
    width: 80%;
    height: 201px;
    padding: 0px 28px 32px 28px;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    border-radius: 12px;
    border: 4px solid #F9D838;
    background: #0F7088;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    backdrop-filter: blur(10px);
    animation: ${fadeIn} 0.3s ease-in-out;
    position: relative;
    margin-bottom: 60px;


`



