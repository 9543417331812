import {IconProps} from "../../model";

export const PlusIcon = (props:IconProps) => {
    return(
        <svg
            {...props}
            xmlns="http://www.w3.org/2000/svg" width="20" height="22" viewBox="0 0 20 22" fill="none">
            <g clip-path="url(#clip0_132_3350)">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M1.4687 3.27932H18.5313C19.3396 3.27932 20 3.93912 20 4.74664V20.5228C20 21.3303 19.3396 21.9902 18.5313 21.9902H1.4687C0.660424 21.9902 0 21.3303 0 20.5228V4.74664C0 3.93912 0.660424 3.27932 1.4687 3.27932Z" fill="#C24606"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M2.17841 0H17.8117C18.413 0 18.9551 0.246195 19.3494 0.640107C19.7437 1.03402 19.9901 1.5855 19.9901 2.17637V17.795C19.9901 18.3957 19.7437 18.9373 19.3494 19.3312C18.9551 19.7252 18.413 19.9714 17.8117 19.9714H2.17841C1.57713 19.9714 1.03499 19.7252 0.64071 19.3312C0.246427 18.9373 0 18.3859 0 17.795V2.17637C0 1.57565 0.246427 1.03402 0.64071 0.640107C1.03499 0.246195 1.57713 0 2.17841 0ZM17.8216 1.45748H2.17841C1.98127 1.45748 1.79399 1.53626 1.66585 1.66428C1.5377 1.80215 1.45885 1.97941 1.45885 2.17637V17.795C1.45885 17.9919 1.5377 18.1791 1.66585 18.3071C1.79399 18.4351 1.98127 18.5237 2.17841 18.5237H17.8117C18.0089 18.5237 18.1962 18.4449 18.3243 18.3169C18.4524 18.1791 18.5313 18.0018 18.5313 17.8048V2.17637C18.5313 1.97941 18.4524 1.7923 18.3243 1.66428C18.1962 1.53626 18.0089 1.44763 17.8117 1.44763L17.8216 1.45748Z" fill="#E87D18"/>
                <path d="M2.17833 0.728739H17.8116C18.6101 0.728739 19.2705 1.37869 19.2705 2.18621V17.8048C19.2705 18.6025 18.6199 19.2623 17.8116 19.2623H2.17833C1.37991 19.2623 0.719482 18.6124 0.719482 17.8048V2.17637C0.719482 1.37869 1.37005 0.718891 2.17833 0.718891V0.728739Z" fill="#FFB500"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M2.17848 0.728739H17.8118C18.6102 0.728739 19.2706 1.37869 19.2706 2.18621V9.99553H0.729492V2.17637C0.729492 1.37869 1.38006 0.718891 2.18834 0.718891L2.17848 0.728739Z" fill="#FFC030"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M2.93732 1.57565H18.5706C18.7974 1.57565 19.0044 1.62489 19.1916 1.71352C18.9945 1.14235 18.4524 0.718891 17.8116 0.718891H2.17833C1.37991 0.718891 0.719482 1.36885 0.719482 2.17637V17.795C0.719482 18.376 1.06448 18.8782 1.55733 19.1047C1.50805 18.957 1.47848 18.7995 1.47848 18.6419V3.03313C1.47848 2.23545 2.12904 1.57565 2.93732 1.57565Z" fill="#FFCE61"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M2.74037 1.48702C3.08537 1.48702 3.4008 1.62489 3.62751 1.85139C3.85422 2.07789 3.99222 2.39302 3.99222 2.73769C3.99222 3.08236 3.85422 3.39749 3.62751 3.62399C3.4008 3.85049 3.08537 3.98836 2.74037 3.98836C2.39538 3.98836 2.07995 3.85049 1.85324 3.62399C1.62652 3.39749 1.48853 3.08236 1.48853 2.73769C1.48853 2.39302 1.62652 2.07789 1.85324 1.85139C2.07995 1.62489 2.39538 1.48702 2.74037 1.48702ZM3.36137 2.10743C3.20366 1.94987 2.97694 1.85139 2.73052 1.85139C2.48409 1.85139 2.26723 1.94987 2.09966 2.10743C1.94195 2.265 1.84338 2.49149 1.84338 2.73769C1.84338 2.98389 1.94195 3.20054 2.09966 3.36795C2.25738 3.52551 2.48409 3.62399 2.73052 3.62399C2.97694 3.62399 3.1938 3.52551 3.36137 3.36795C3.51908 3.21038 3.61765 2.98389 3.61765 2.73769C3.61765 2.49149 3.51908 2.27484 3.36137 2.10743Z" fill="#1A1A1A"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M2.74035 1.66428C3.33177 1.66428 3.80491 2.14682 3.80491 2.72784C3.80491 3.30886 3.32191 3.79141 2.74035 3.79141C2.15878 3.79141 1.67578 3.30886 1.67578 2.72784C1.67578 2.14682 2.15878 1.66428 2.74035 1.66428Z" fill="#4D4D4D"/>
                <path d="M3.46478 2.26791L1.88403 2.65026L2.01624 3.19583L3.59699 2.81347L3.46478 2.26791Z" fill="#1A1A1A"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M2.84889 4.27395C2.30676 4.27395 1.84347 3.94897 1.63647 3.48612C1.86319 3.86034 2.28704 4.10654 2.76018 4.10654C3.4896 4.10654 4.08103 3.51567 4.08103 2.78693C4.08103 2.59982 4.0416 2.42256 3.9726 2.265C4.10074 2.46195 4.16974 2.6983 4.16974 2.95434C4.16974 3.68308 3.57832 4.27395 2.84889 4.27395Z" fill="#FFCE61"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M17.2103 1.48702C17.5553 1.48702 17.8708 1.62489 18.0975 1.85139C18.3242 2.07789 18.4622 2.39302 18.4622 2.73769C18.4622 3.08236 18.3242 3.39749 18.0975 3.62399C17.8708 3.85049 17.5553 3.98836 17.2103 3.98836C16.8653 3.98836 16.5499 3.85049 16.3232 3.62399C16.0965 3.39749 15.9585 3.08236 15.9585 2.73769C15.9585 2.39302 16.0965 2.07789 16.3232 1.85139C16.5499 1.62489 16.8653 1.48702 17.2103 1.48702ZM17.8412 2.10743C17.6835 1.94987 17.4568 1.85139 17.2103 1.85139C16.9639 1.85139 16.7471 1.94987 16.5795 2.10743C16.4218 2.265 16.3232 2.49149 16.3232 2.73769C16.3232 2.98389 16.4218 3.20054 16.5795 3.36795C16.7372 3.52551 16.9639 3.62399 17.2103 3.62399C17.4568 3.62399 17.6736 3.52551 17.8412 3.36795C17.9989 3.21038 18.0975 2.98389 18.0975 2.73769C18.0975 2.49149 17.9989 2.27484 17.8412 2.10743Z" fill="#1A1A1A"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M17.2103 1.66428C17.8017 1.66428 18.2749 2.14682 18.2749 2.72784C18.2749 3.30886 17.7919 3.79141 17.2103 3.79141C16.6287 3.79141 16.1458 3.30886 16.1458 2.72784C16.1458 2.14682 16.6287 1.66428 17.2103 1.66428Z" fill="#4D4D4D"/>
                <path d="M16.5357 2.2084L16.3569 2.74057L17.8989 3.2575L18.0776 2.72533L16.5357 2.2084Z" fill="#1A1A1A"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M17.3286 4.27395C16.7865 4.27395 16.3232 3.94897 16.1162 3.48612C16.3429 3.86034 16.7668 4.10654 17.2399 4.10654C17.9693 4.10654 18.5608 3.51567 18.5608 2.78693C18.5608 2.59982 18.5213 2.42256 18.4523 2.265C18.5805 2.46195 18.6495 2.6983 18.6495 2.95434C18.6495 3.68308 18.0581 4.27395 17.3286 4.27395Z" fill="#FFCE61"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M3.31199 16.0519L3.36127 16.0815C3.65698 16.2489 3.86398 16.5345 3.94284 16.8397C4.02169 17.1352 3.99212 17.4503 3.84427 17.7457V17.7753L3.80484 17.8147C3.62741 18.1003 3.36127 18.2972 3.0557 18.376C2.75013 18.4548 2.40514 18.4252 2.10942 18.248H2.08971C1.80385 18.0609 1.60671 17.7851 1.52786 17.4897C1.449 17.1844 1.47857 16.8397 1.656 16.5443C1.83342 16.2489 2.10942 16.0421 2.41499 15.9633C2.7107 15.8845 3.02613 15.9141 3.32184 16.0618L3.31199 16.0519ZM3.59784 16.9284C3.5387 16.7117 3.4007 16.5148 3.18384 16.3867C2.97685 16.2686 2.73042 16.239 2.51356 16.2981C2.29671 16.3572 2.09957 16.4951 1.97142 16.7117C1.85314 16.9185 1.82357 17.1647 1.88271 17.3814C1.94185 17.598 2.07985 17.795 2.29671 17.923C2.50371 18.0412 2.75013 18.0707 2.96699 18.0116C3.18384 17.9526 3.38099 17.8147 3.49927 17.598C3.61755 17.3814 3.64713 17.145 3.58798 16.9185L3.59784 16.9284Z" fill="#1A1A1A"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M3.27264 16.2292C3.78521 16.5246 3.96264 17.1746 3.66693 17.6867C3.37122 18.1987 2.72065 18.376 2.20808 18.0806C1.69551 17.7851 1.51809 17.1352 1.8138 16.6231C2.10951 16.111 2.76008 15.9337 3.27264 16.2292Z" fill="#4D4D4D"/>
                <path d="M3.0141 16.3502L2.02197 17.6378L2.46718 17.9802L3.45931 16.6926L3.0141 16.3502Z" fill="#1A1A1A"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M2.84865 18.701C2.30651 18.701 1.84323 18.376 1.63623 17.9132C1.86294 18.2874 2.2868 18.5336 2.75994 18.5336C3.48936 18.5336 4.08078 17.9427 4.08078 17.214C4.08078 17.0269 4.04136 16.8496 3.97236 16.692C4.1005 16.889 4.1695 17.1253 4.1695 17.3814C4.1695 18.1101 3.57807 18.701 2.84865 18.701Z" fill="#FFCE61"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M17.2103 15.9042C17.5553 15.9042 17.8708 16.0421 18.0975 16.2686C18.3242 16.4951 18.4622 16.8102 18.4622 17.1549C18.4622 17.4996 18.3242 17.8147 18.0975 18.0412C17.8708 18.2677 17.5553 18.4055 17.2103 18.4055C16.8653 18.4055 16.5499 18.2677 16.3232 18.0412C16.0965 17.8147 15.9585 17.4996 15.9585 17.1549C15.9585 16.8102 16.0965 16.4951 16.3232 16.2686C16.5499 16.0421 16.8653 15.9042 17.2103 15.9042ZM17.8412 16.5345C17.6835 16.3769 17.4568 16.2784 17.2103 16.2784C16.9639 16.2784 16.7471 16.3769 16.5795 16.5345C16.4218 16.692 16.3232 16.9185 16.3232 17.1647C16.3232 17.4109 16.4218 17.6276 16.5795 17.795C16.7372 17.9525 16.9639 18.051 17.2103 18.051C17.4568 18.051 17.6736 17.9525 17.8412 17.795C17.9989 17.6374 18.0975 17.4109 18.0975 17.1647C18.0975 16.9185 17.9989 16.7019 17.8412 16.5345Z" fill="#1A1A1A"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M17.2103 16.0913C17.8017 16.0913 18.2749 16.5739 18.2749 17.1549C18.2749 17.7359 17.7919 18.2184 17.2103 18.2184C16.6287 18.2184 16.1458 17.7359 16.1458 17.1549C16.1458 16.5739 16.6287 16.0913 17.2103 16.0913Z" fill="#4D4D4D"/>
                <path d="M16.7463 16.4345L16.4038 16.8795L17.6931 17.87L18.0356 17.4251L16.7463 16.4345Z" fill="#1A1A1A"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M17.3286 18.701C16.7865 18.701 16.3232 18.376 16.1162 17.9132C16.3429 18.2874 16.7668 18.5336 17.2399 18.5336C17.9693 18.5336 18.5608 17.9427 18.5608 17.214C18.5608 17.0269 18.5213 16.8496 18.4523 16.692C18.5805 16.889 18.6495 17.1253 18.6495 17.3814C18.6495 18.1101 18.0581 18.701 17.3286 18.701Z" fill="#FFCE61"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M3.80486 8.10474H7.94483V3.31871H12.0454V8.11459H16.8458V12.2113H12.0454V17.0072H7.94483V12.2113H3.1543V8.11459H3.80486V8.10474ZM8.60526 9.4145H4.46529V10.8917H9.26568V15.6876H10.7442V10.8917H15.5446V9.4145H10.7442V4.61862H9.26568V9.4145H8.61511H8.60526Z" fill="#E87D18"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M3.80493 8.76455H8.60533V3.96867H11.3949V8.76455H16.1953V11.5515H11.3949V16.3474H8.60533V11.5515H3.80493V8.76455Z" fill="url(#paint0_linear_132_3350)"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M12.0552 12.2113V15.4217L12.3607 12.5264L16.8457 12.2113H12.0552Z" fill="#FFCE61"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M7.94483 12.2113H3.1543L7.70826 12.4181L7.94483 13.6195V12.2113Z" fill="#FFCE61"/>
            </g>
            <defs>
                <linearGradient id="paint0_linear_132_3350" x1="9.99517" y1="16.3474" x2="9.99517" y2="3.96867" gradientUnits="userSpaceOnUse">
                    <stop/>
                    <stop offset="0.14" stop-color="#191A1A"/>
                    <stop offset="0.29" stop-color="#2D2E2E"/>
                    <stop offset="0.46" stop-color="#3B3C3D"/>
                    <stop offset="0.66" stop-color="#434445"/>
                    <stop offset="1" stop-color="#464748"/>
                </linearGradient>
                <clipPath id="clip0_132_3350">
                    <rect width="20" height="22" fill="white"/>
                </clipPath>
            </defs>
        </svg>
    )
}