import {IconProps} from "../../model";

export const TGIcon = (props: IconProps) => (
    <svg
        {...props}
        xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40" fill="none">
        <path fill-rule="evenodd" clip-rule="evenodd"
              d="M4.36059 -0.00523376H35.6394C36.8344 -0.00523376 37.9245 0.48743 38.7212 1.27359C39.5073 2.07024 40 3.16037 40 4.35534V35.6342C40 36.8291 39.5073 37.9193 38.7212 38.7159C37.935 39.5126 36.8344 40.0052 35.6394 40.0052H4.36059C3.16562 40.0052 2.07547 39.5126 1.27883 38.7264C0.492664 37.9298 0 36.8396 0 35.6446V4.36583C0 3.17086 0.492664 2.08072 1.27883 1.28407C2.06499 0.487424 3.15514 -0.00523376 4.36059 -0.00523376ZM35.6394 2.90881H4.36059C3.96227 2.90881 3.5954 3.06604 3.33334 3.32809C3.07129 3.60063 2.91404 3.95702 2.91404 4.35534V35.6342C2.91404 36.0325 3.07129 36.3994 3.33334 36.6614C3.5954 36.9235 3.96227 37.0912 4.36059 37.0912H35.6394C36.0377 37.0912 36.4046 36.9235 36.6667 36.6719C36.9287 36.3994 37.086 36.043 37.086 35.6446V4.36583C37.086 3.96751 36.9287 3.60064 36.6667 3.33858C36.4046 3.07653 36.0377 2.90881 35.6394 2.90881Z"
              fill="#E87D18"/>
        <path
            d="M35.6393 1.45183H4.37095C2.76157 1.45183 1.45691 2.75649 1.45691 4.36587V35.6342C1.45691 37.2436 2.76157 38.5483 4.37095 38.5483H35.6393C37.2487 38.5483 38.5533 37.2436 38.5533 35.6342V4.36587C38.5533 2.75649 37.2487 1.45183 35.6393 1.45183Z"
            fill="#FFB500"/>
        <path fill-rule="evenodd" clip-rule="evenodd"
              d="M4.3607 1.45183H35.6395C37.2433 1.45183 38.5431 2.7621 38.5431 4.36587V20.0053H1.44666V4.36587C1.44666 2.7621 2.75693 1.45183 4.3607 1.45183Z"
              fill="#FFC030"/>
        <path fill-rule="evenodd" clip-rule="evenodd"
              d="M5.88039 3.16046H37.1592C37.5995 3.16046 38.0292 3.26529 38.4066 3.44349C38.0187 2.29045 36.9286 1.46236 35.6498 1.46236H4.37095C2.76718 1.46236 1.45691 2.77263 1.45691 4.3764V35.6552C1.45691 36.8083 2.13825 37.8146 3.12357 38.2863C3.02923 37.9928 2.97682 37.6888 2.97682 37.3638V6.085C2.97682 4.48123 4.28709 3.17096 5.89087 3.17096L5.88039 3.16046Z"
              fill="#FFCE61"/>
        <path fill-rule="evenodd" clip-rule="evenodd"
              d="M5.47155 2.9716C6.16337 2.9716 6.79231 3.25462 7.24304 3.70535C7.69377 4.15609 7.97679 4.78502 7.97679 5.47684C7.97679 6.16867 7.69377 6.7976 7.24304 7.24833C6.79231 7.69907 6.16337 7.98208 5.47155 7.98208C4.77972 7.98208 4.15079 7.69907 3.70006 7.24833C3.24932 6.7976 2.96631 6.16867 2.96631 5.47684C2.96631 4.78502 3.24932 4.15609 3.70006 3.70535C4.15079 3.25462 4.77972 2.9716 5.47155 2.9716ZM6.72941 4.21899C6.40446 3.89404 5.96421 3.69488 5.47155 3.69488C4.97889 3.69488 4.53864 3.89404 4.21369 4.21899C3.88874 4.54393 3.68958 4.98418 3.68958 5.47684C3.68958 5.96951 3.88874 6.40975 4.21369 6.7347C4.53864 7.05965 4.97889 7.25881 5.47155 7.25881C5.96421 7.25881 6.40446 7.05965 6.72941 6.7347C7.05435 6.40975 7.25352 5.96951 7.25352 5.47684C7.25352 4.98418 7.05435 4.54393 6.72941 4.21899Z"
              fill="#1A1A1A"/>
        <path fill-rule="evenodd" clip-rule="evenodd"
              d="M5.47234 3.32808C6.65683 3.32808 7.6107 4.28197 7.6107 5.46645C7.6107 6.65094 6.65683 7.60481 5.47234 7.60481C4.28786 7.60481 3.33398 6.65094 3.33398 5.46645C3.33398 4.28197 4.28786 3.32808 5.47234 3.32808Z"
              fill="#4D4D4D"/>
        <path d="M6.93216 4.52648L3.77393 5.29112L4.03785 6.38121L7.19608 5.61657L6.93216 4.52648Z" fill="#1A1A1A"/>
        <path fill-rule="evenodd" clip-rule="evenodd"
              d="M5.70277 8.55873C4.62311 8.55873 3.69019 7.90882 3.29187 6.97591C3.75309 7.73063 4.58118 8.22329 5.53506 8.22329C6.99208 8.22329 8.16609 7.04929 8.16609 5.59227C8.16609 5.22539 8.09271 4.869 7.94596 4.54405C8.19753 4.94237 8.3338 5.42455 8.3338 5.92769C8.3338 7.38471 7.1598 8.55873 5.70277 8.55873Z"
              fill="#FFCE61"/>
        <path fill-rule="evenodd" clip-rule="evenodd"
              d="M34.434 2.9716C35.1258 2.9716 35.7547 3.25462 36.2054 3.70535C36.6562 4.15609 36.9392 4.78502 36.9392 5.47684C36.9392 6.16867 36.6562 6.7976 36.2054 7.24833C35.7547 7.69907 35.1258 7.98208 34.434 7.98208C33.7421 7.98208 33.1132 7.69907 32.6625 7.24833C32.2117 6.7976 31.9287 6.16867 31.9287 5.47684C31.9287 4.78502 32.2117 4.15609 32.6625 3.70535C33.1132 3.25462 33.7421 2.9716 34.434 2.9716ZM35.6813 4.21899C35.3564 3.89404 34.9161 3.69488 34.4235 3.69488C33.9308 3.69488 33.4905 3.89404 33.1656 4.21899C32.8407 4.54393 32.6415 4.98418 32.6415 5.47684C32.6415 5.96951 32.8407 6.40975 33.1656 6.7347C33.4905 7.05965 33.9308 7.25881 34.4235 7.25881C34.9161 7.25881 35.3564 7.05965 35.6813 6.7347C36.0063 6.40975 36.2054 5.96951 36.2054 5.47684C36.2054 4.98418 36.0063 4.54393 35.6813 4.21899Z"
              fill="#1A1A1A"/>
        <path fill-rule="evenodd" clip-rule="evenodd"
              d="M34.4235 3.32808C35.608 3.32808 36.5619 4.28197 36.5619 5.46645C36.5619 6.65094 35.608 7.60481 34.4235 7.60481C33.239 7.60481 32.2852 6.65094 32.2852 5.46645C32.2852 4.28197 33.239 3.32808 34.4235 3.32808Z"
              fill="#4D4D4D"/>
        <path d="M33.0479 4.39091L32.691 5.45424L35.7717 6.488L36.1285 5.42467L33.0479 4.39091Z" fill="#1A1A1A"/>
        <path fill-rule="evenodd" clip-rule="evenodd"
              d="M34.6539 8.55873C33.5743 8.55873 32.6414 7.90882 32.243 6.97591C32.7043 7.73063 33.5323 8.22329 34.4862 8.22329C35.9433 8.22329 37.1173 7.04929 37.1173 5.59227C37.1173 5.22539 37.0439 4.869 36.8971 4.54405C37.1487 4.94237 37.285 5.42455 37.285 5.92769C37.285 7.38471 36.111 8.55873 34.6539 8.55873Z"
              fill="#FFCE61"/>
        <path fill-rule="evenodd" clip-rule="evenodd"
              d="M6.36286 32.8145L6.32092 32.7935C5.91212 32.5734 5.44043 32.5315 5.02114 32.6468C4.58089 32.7621 4.18255 33.0451 3.94146 33.4749C3.70037 33.8942 3.64796 34.3868 3.76327 34.8271C3.87857 35.2673 4.16159 35.6552 4.58088 35.8963H4.59137C5.01066 36.1479 5.49283 36.2003 5.93309 36.0745C6.37334 35.9592 6.76118 35.6761 7.01275 35.2464L7.04419 35.2045C7.26432 34.7956 7.30625 34.3239 7.19094 33.9047C7.07564 33.4644 6.79263 33.0766 6.36286 32.825V32.8145ZM6.72973 32.1856C7.32721 32.5315 7.72553 33.087 7.89325 33.7055C8.06096 34.3239 7.98759 34.9948 7.65216 35.5923C7.30625 36.2003 6.7507 36.5986 6.13225 36.7663C5.5138 36.934 4.83246 36.8606 4.23498 36.5147C3.63749 36.1688 3.23917 35.6133 3.07146 34.9948C2.90374 34.3764 2.97711 33.695 3.32302 33.0975C3.66893 32.5001 4.22448 32.0913 4.84293 31.934C5.46138 31.7663 6.14273 31.8397 6.74022 32.1856H6.72973Z"
              fill="#1A1A1A"/>
        <path fill-rule="evenodd" clip-rule="evenodd"
              d="M6.54087 32.5C7.55764 33.087 7.91403 34.3973 7.32703 35.4245C6.74003 36.4413 5.42976 36.7977 4.41299 36.2107C3.38573 35.6237 3.03982 34.3134 3.62682 33.2862C4.21382 32.2694 5.52409 31.913 6.55134 32.5H6.54087Z"
              fill="#4D4D4D"/>
        <path d="M6.01362 32.7157L4.03186 35.2909L4.92073 35.975L6.90249 33.3997L6.01362 32.7157Z" fill="#1A1A1A"/>
        <path fill-rule="evenodd" clip-rule="evenodd"
              d="M5.7018 37.4372C4.62213 37.4372 3.68922 36.7873 3.29089 35.8544C3.75211 36.6091 4.5802 37.1017 5.53408 37.1017C6.9911 37.1017 8.16512 35.9277 8.16512 34.4707C8.16512 34.1038 8.09173 33.7474 7.94498 33.4225C8.19656 33.8208 8.33282 34.2925 8.33282 34.8061C8.33282 36.2632 7.15882 37.4372 5.7018 37.4372Z"
              fill="#FFCE61"/>
        <path fill-rule="evenodd" clip-rule="evenodd"
              d="M34.434 31.8501C35.1258 31.8501 35.7547 32.1331 36.2054 32.5838C36.6562 33.0346 36.9392 33.6635 36.9392 34.3553C36.9392 35.0471 36.6562 35.6761 36.2054 36.1268C35.7547 36.5775 35.1258 36.8606 34.434 36.8606C33.7421 36.8606 33.1132 36.5775 32.6625 36.1268C32.2117 35.6761 31.9287 35.0471 31.9287 34.3553C31.9287 33.6635 32.2117 33.0346 32.6625 32.5838C33.1132 32.1331 33.7421 31.8501 34.434 31.8501ZM35.6813 33.0975C35.3564 32.7725 34.9161 32.5734 34.4235 32.5734C33.9308 32.5734 33.4905 32.7725 33.1656 33.0975C32.8407 33.4224 32.6415 33.8627 32.6415 34.3553C32.6415 34.848 32.8407 35.2882 33.1656 35.6132C33.4905 35.9381 33.9308 36.1373 34.4235 36.1373C34.9161 36.1373 35.3564 35.9381 35.6813 35.6132C36.0063 35.2882 36.2054 34.848 36.2054 34.3553C36.2054 33.8627 36.0063 33.4224 35.6813 33.0975Z"
              fill="#1A1A1A"/>
        <path fill-rule="evenodd" clip-rule="evenodd"
              d="M34.4235 32.2169C35.608 32.2169 36.5619 33.1708 36.5619 34.3553C36.5619 35.5398 35.608 36.4936 34.4235 36.4936C33.239 36.4936 32.2852 35.5398 32.2852 34.3553C32.2852 33.1708 33.239 32.2169 34.4235 32.2169Z"
              fill="#4D4D4D"/>
        <path d="M33.4927 32.9378L32.809 33.8269L35.3849 35.8077L36.0686 34.9186L33.4927 32.9378Z" fill="#1A1A1A"/>
        <path fill-rule="evenodd" clip-rule="evenodd"
              d="M34.6539 37.4372C33.5743 37.4372 32.6414 36.7873 32.243 35.8544C32.7043 36.6091 33.5323 37.1017 34.4862 37.1017C35.9433 37.1017 37.1173 35.9277 37.1173 34.4707C37.1173 34.1038 37.0439 33.7475 36.8971 33.4225C37.1487 33.8208 37.285 34.2925 37.285 34.8061C37.285 36.2632 36.111 37.4372 34.6539 37.4372Z"
              fill="#FFCE61"/>
        <path
            d="M28.665 11.717L10.935 18.554C9.72497 19.04 9.73197 19.715 10.713 20.016L15.265 21.436L25.797 14.791C26.295 14.488 26.75 14.651 26.376 14.983L17.843 22.684H17.841L17.843 22.685L17.529 27.377C17.989 27.377 18.192 27.166 18.45 26.917L20.661 24.767L25.26 28.164C26.108 28.631 26.717 28.391 26.928 27.379L29.947 13.151C30.256 11.912 29.474 11.351 28.665 11.717Z"
            fill="black"/>
        <path
            d="M28.665 11.717L10.935 18.554C9.72497 19.04 9.73197 19.715 10.713 20.016L15.265 21.436L25.797 14.791C26.295 14.488 26.75 14.651 26.376 14.983L17.843 22.684H17.841L17.843 22.685L17.529 27.377C17.989 27.377 18.192 27.166 18.45 26.917L20.661 24.767L25.26 28.164C26.108 28.631 26.717 28.391 26.928 27.379L29.947 13.151C30.256 11.912 29.474 11.351 28.665 11.717Z"
            fill="url(#paint0_linear_103_2491)"/>
        <path fill-rule="evenodd" clip-rule="evenodd"
              d="M10.935 18.554L28.665 11.717C29.474 11.351 30.256 11.912 29.947 13.151L26.928 27.379C26.717 28.391 26.108 28.631 25.26 28.164L20.661 24.767L18.45 26.917C18.4418 26.925 18.4336 26.9329 18.4254 26.9408C18.1788 27.1793 17.9743 27.377 17.529 27.377L17.843 22.685L17.841 22.684H17.843L26.376 14.983C26.6736 14.7188 26.4464 14.5617 26.0903 14.6611C26.089 14.6614 26.0877 14.6618 26.0865 14.6621C25.996 14.6878 25.8974 14.7299 25.797 14.791L15.265 21.436L10.713 20.016C9.73204 19.715 9.72504 19.04 10.935 18.554ZM10.4197 20.972C10.4189 20.9718 10.4182 20.9715 10.4174 20.9713C10.0881 20.87 9.74686 20.7111 9.4766 20.4555C9.18857 20.1831 8.9559 19.7665 9.00743 19.2543C9.0552 18.7795 9.32841 18.4269 9.5895 18.196C9.85703 17.9594 10.1946 17.7737 10.5623 17.6261L10.5752 17.6209L28.2825 10.7927C28.9639 10.4961 29.8101 10.5233 30.4203 11.1131C31.0214 11.6941 31.121 12.557 30.9219 13.3744L27.907 27.5831C27.9068 27.5838 27.9067 27.5844 27.9066 27.5851C27.773 28.224 27.4537 28.9182 26.7434 29.238C26.0383 29.5554 25.3165 29.3367 24.7776 29.04C24.7388 29.0186 24.7015 28.9947 24.6659 28.9684L20.7497 26.0757L19.1445 27.6365C19.1354 27.6453 19.1255 27.6549 19.1148 27.6654C19.0064 27.771 18.8153 27.9572 18.5779 28.099C18.2758 28.2793 17.9317 28.377 17.529 28.377C17.2522 28.377 16.9878 28.2623 16.7987 28.0601C16.6096 27.8579 16.5128 27.5864 16.5313 27.3102L16.8409 22.6829C16.841 22.6072 16.8498 22.5305 16.8678 22.4542C16.92 22.233 17.0435 22.0436 17.2104 21.9079L19.1139 20.19L15.7986 22.2817C15.5508 22.4381 15.2469 22.4779 14.9672 22.3906L10.4197 20.972Z"
              fill="#E87D18"/>
        <defs>
            <linearGradient id="paint0_linear_103_2491" x1="20.0082" y1="26.4436" x2="20.0082" y2="14.3412"
                            gradientUnits="userSpaceOnUse">
                <stop/>
                <stop offset="0.14" stop-color="#191A1A"/>
                <stop offset="0.29" stop-color="#2D2E2E"/>
                <stop offset="0.46" stop-color="#3B3C3D"/>
                <stop offset="0.66" stop-color="#434445"/>
                <stop offset="1" stop-color="#464748"/>
            </linearGradient>
        </defs>
    </svg>
)