import {IconProps} from "../../model";

export const ThreeDBlockIcon = (props:IconProps) => {
    return(
        <svg
            {...props}
            xmlns="http://www.w3.org/2000/svg" width="39" height="42" viewBox="0 0 39 42" fill="none">
            <g clip-path="url(#clip0_132_3976)">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M2.85962 6.39212H36.1216C37.7019 6.39212 38.9812 7.68935 38.9812 9.24977V39.1235C38.9812 40.7028 37.6831 41.9812 36.1216 41.9812H2.85962C1.27931 41.9812 0 40.684 0 39.1235V9.26857C0 7.68934 1.29812 6.41092 2.85962 6.41092V6.39212Z" fill="#C24606"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M4.25181 0H34.7482C35.9146 0 36.987 0.470009 37.7583 1.24082C38.5297 2.01164 39 3.08326 39 4.24888V33.803C39 34.9687 38.5297 36.0403 37.7583 36.7923C36.987 37.5631 35.9146 38.0519 34.7482 38.0519H4.25181C3.08538 38.0519 2.01302 37.5631 1.24168 36.8111C0.470333 36.0403 0 34.9687 0 33.803V4.24888C0 3.08326 0.470333 2.01164 1.24168 1.25962C2.01302 0.488809 3.06657 0 4.25181 0ZM34.7482 2.83885H4.25181C3.85673 2.83885 3.49928 2.98926 3.2547 3.25246C3.01013 3.51567 2.84081 3.87287 2.84081 4.24888V33.803C2.84081 34.1979 2.99132 34.5551 3.2547 34.7995C3.51809 35.0627 3.85673 35.2131 4.25181 35.2131H34.7482C35.1433 35.2131 35.5007 35.0627 35.7453 34.7995C35.9899 34.5363 36.1592 34.1791 36.1592 33.7842V4.24888C36.1592 3.85407 36.0087 3.49687 35.7453 3.25246C35.4819 2.98926 35.1433 2.83885 34.7482 2.83885Z" fill="#E87D18"/>
                <path d="M4.2517 1.41003H34.7481C36.3096 1.41003 37.5889 2.68845 37.5889 4.24888V33.803C37.5889 35.3635 36.3096 36.6419 34.7481 36.6419H4.2517C2.69019 36.6419 1.41089 35.3635 1.41089 33.803V4.24888C1.41089 2.68845 2.69019 1.41003 4.2517 1.41003Z" fill="#FFB500"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M4.2517 1.41003H34.7481C36.3096 1.41003 37.5889 2.68845 37.5889 4.24888V25.0609H1.41089V4.24888C1.41089 2.68845 2.69019 1.41003 4.2517 1.41003Z" fill="#FFC541"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M5.73795 3.08326H36.2343C36.667 3.08326 37.0809 3.17726 37.4384 3.36526C37.0621 2.23724 35.9898 1.42883 34.7481 1.42883H4.2517C2.69019 1.42883 1.41089 2.70725 1.41089 4.26768V33.8218C1.41089 34.9499 2.06935 35.9275 3.02883 36.3787C2.93477 36.0967 2.87833 35.7959 2.87833 35.4763V5.92211C2.87833 4.36168 4.15763 3.08326 5.71914 3.08326H5.73795Z" fill="#FFCE61"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M6.22712 3.76007H32.7915C33.6757 3.76007 34.4847 4.11728 35.0867 4.71889C35.67 5.3017 36.0462 6.11012 36.0462 7.01253V30.9454C36.0462 31.829 35.6888 32.6374 35.0867 33.239C34.5035 33.8218 33.6946 34.1979 32.7915 34.1979H6.22712C5.34289 34.1979 4.53392 33.8406 3.93189 33.239C3.34868 32.6562 2.97241 31.8478 2.97241 30.9454V7.01253C2.97241 6.12892 3.32986 5.3017 3.93189 4.71889C4.5151 4.13608 5.32408 3.76007 6.22712 3.76007ZM32.7727 5.3017H6.22712C5.75678 5.3017 5.34289 5.4897 5.02306 5.80931C4.72205 6.11012 4.5151 6.54252 4.5151 7.01253V30.9454C4.5151 31.4154 4.70324 31.829 5.02306 32.1486C5.32408 32.4494 5.75678 32.6562 6.22712 32.6562H32.7915C33.2618 32.6562 33.6757 32.4682 33.9956 32.1486C34.2966 31.8478 34.5035 31.4154 34.5035 30.9454V7.01253C34.5035 6.54252 34.3154 6.12892 33.9956 5.80931C33.6946 5.5085 33.2618 5.3017 32.7915 5.3017H32.7727Z" fill="#E87D18"/>
                <path d="M6.22724 4.53089H32.7916C34.1462 4.53089 35.2562 5.64011 35.2562 6.99373V30.9266C35.2562 32.2802 34.1462 33.3894 32.7916 33.3894H6.22724C4.87268 33.3894 3.7627 32.2802 3.7627 30.9266V7.01253C3.7627 5.65891 4.87268 4.54969 6.22724 4.54969V4.53089Z" fill="#1287A5"/>
            </g>
            <defs>
                <clipPath id="clip0_132_3976">
                    <rect width="39" height="42" fill="white"/>
                </clipPath>
            </defs>
        </svg>
    )
}