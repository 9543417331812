import styled from "styled-components";
import {BottomNav} from "../bottom-nav";
import {BorderedTitle} from "../bordered-title";
import {ThreeDBlockIcon} from "../icons/sd-block";
import {PlusIcon} from "../icons/plus";
import React from "react";
import {useBoostsStore} from "../../store/boosts-store";
import {Boost, boostHandler, useAreas} from "../../api/requests/area";
import {useUserStore} from "../../store/user-store";
import {getBoostIcon} from "../utils/getBoostIcon";
import {ActionModal} from "../action-modal";
import {useAreasStore} from "../../store/areas-store";
import {OnboardingModal} from "./onboarding";


export const BoostView = () => {
    const [selectedBoost, setSelectedBoost] = React.useState<Boost | null>(null)
    const {boosts, setBoosts} = useBoostsStore();
    const {user, setUser} = useUserStore();
    const {setAreas} = useAreasStore()

    const {mutate} = useAreas()
    if (!user) {
        return null
    }


    const onBoost = async (boost: string) => {
        const resp = await boostHandler({name: boost})
        if (resp) {
            setUser((prevUser) => {
                return {
                    ...prevUser,
                    // @ts-ignore
                    balance: prevUser?.balance - boosts?.find((item) => item?.name === boost)?.next_level_price || 0,
                    block_level: resp?.find((item) => item?.name === 'block')?.level || 0
                }
            })
            mutate().then((data) => {
                setAreas(data as any)
            })
            setBoosts(() => resp)
        }
    }
    return (
        <Wrapper>
            <Container>
                <div style={{width: '100%', height: 64}}/>
                <Col>
                    <BorderedTitle title={'boost'}/>
                    <List style={{paddingBottom: 100}}>
                        <SubTitle>
                            Upgrade your bloxx
                        </SubTitle>
                        {boosts?.map((channel, index) => (
                            <BoostItem
                                icon={getBoostIcon(channel.name)}
                                onClick={() => setSelectedBoost(channel)}
                                level={channel.level} key={index} channel={channel.name}
                                value={channel.next_level_price}/>
                        ))}
                    </List>
                </Col>
            </Container>
            <BottomNav/>
            <ActionModal
                onClick={async () => {
                    if (selectedBoost && user.balance >= selectedBoost.next_level_price) {
                        await onBoost(selectedBoost.name)
                        setSelectedBoost(null)
                    }
                }}
                color={
                    user.balance >= (selectedBoost?.next_level_price ?? 0)
                        ? 'linear-gradient(0deg, #28B236 0%, #1CED63 99.86%)'
                        : 'linear-gradient(0deg, #A9A9A9 0%, #D3D3D3 99.86%)'
                }
                borderColor={
                    user.balance >= (selectedBoost?.next_level_price ?? 0)
                        ? '#76FF7C'
                        : '#B0B0B0'
                }
                text={
                    user.balance >= (selectedBoost?.next_level_price ?? 0)
                        ? 'Upgrade for ' + selectedBoost?.next_level_price + ' BLOXX'
                        : 'Insufficient funds'
                }
                subText={'Please confirm that you want to upgrade ' + selectedBoost?.name}
                title={selectedBoost?.name || ''}
                open={selectedBoost !== null}
                onClose={() => setSelectedBoost(null)}
            />
            <OnboardingModal text={'Improve blocks level and level of tower upgrades'} next={'/?onboarding=true'}/>
        </Wrapper>

    )
}

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100vh;
    background-image: url('/images/dark-bg.png');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    overflow-y: auto; /* Enable vertical scrolling */
`;

const Container = styled.div`
    display: flex;
    flex-direction: column;
    overflow: auto; /* Ensure container can scroll */
    width: 100%;
    align-items: center;
    position: relative;
    justify-content: center;
    gap: 20px;
    padding-top: 54px;
    padding-bottom: 200px; /* Add padding to ensure the last items are visible */

`;


const Col = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    max-width: 320px;
    height: 100%;

    width: 100%;

`


const List = styled.div`
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: flex-start;
    gap: 15px;
    margin-top: 20px;

`


const SubTitle = styled.div`
    color: #FFF;
    text-shadow: 0px 2px 2px rgba(0, 0, 0, 0.20);
    font-family: Roboto;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 25px; /* 125% */
    text-transform: uppercase;
`


interface EarnItemProps {
    channel: string;
    value: number
    level: number
    onClick: () => void
    icon?: React.ReactNode

}

const BoostItem = ({channel, value, level, onClick, icon}: EarnItemProps) => {
    return (
        <ThreeDContainer onClick={onClick}>

            <ItemRow>
                <Relativ>
                    {icon}
                    <ThreeDBlockIcon/>
                </Relativ>
                <BoostCol>
                    <ChannelText>
                        {channel}
                        <Divider/>
                        <LevelText>
                            {level} lvl
                        </LevelText>
                    </ChannelText>
                    <ValueText>
                        +{value} BLOXX
                    </ValueText>
                </BoostCol>
                <PlusIcon/>
            </ItemRow>
        </ThreeDContainer>
    )
}


const ThreeDContainer = styled.div`
    box-sizing: border-box;
    border-radius: 3px;
    border: 1px solid #e87c18;
    background: rgba(15, 112, 136, 0.80);
    box-shadow: 0px 5px 0px 0px #C24606;
    display: flex;
    width: 100%;
    padding: 12px 0;
    flex-direction: column;
    align-items: center;
    gap: 3px;
`

const Relativ = styled.div`
    position: relative;
`


const ChannelText = styled.div`
    color: #FFF;
    font-family: Roboto;
    font-size: 15px;
    font-style: normal;
    font-weight: 700;
    line-height: 19px;
    letter-spacing: -0.4px;
    text-transform: uppercase;
    display: flex;
    gap: 4px;

`

const LevelText = styled.div`
    color: #FFF;
    font-family: Roboto;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: 19px; /* 158.333% */
    letter-spacing: -0.4px;
    text-transform: uppercase;

`


const ValueText = styled.div`
    color: #FFF;
    font-family: Roboto;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 153.846% */
    letter-spacing: -0.23px;
`

const ItemRow = styled.div`
    display: flex;
    padding: 0px 16px;
    align-items: center;
    gap: 16px;
    flex-shrink: 0;
    width: 100%;
    justify-content: space-between;
    box-sizing: border-box;
`

const BoostCol = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 2px;
    align-self: stretch;
    width: 80%;
`

const Divider = styled.div`
    width: 0.5px;
    height: 15px;
    background: #2B2B2B;
`

