import styled from "styled-components";
import {HeartIcon} from "./icons/heart";


interface Props {
    full: number;
}

export const Life = ({full}: Props) => {
    const cnt = 3
    return (
        <Row>
            {[...Array(cnt)].map((_, i) => {
                return <LifeItem key={i} isFull={i < full}/>
            })}
        </Row>
    )

}


const Row = styled.div`
  display: flex;
  width: 78px;
  height: 26px;
  justify-content: center;
  align-items: flex-start;
  flex-shrink: 0;
  position: absolute;
  top: 20px;
  left: 10px;
`

const LifeItem = ({isFull = true}) => {
    return (
        <Container>
            {isFull && <HeartIcon
            style={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)"
            }}
            />}
        </Container>
    )
}


const Container = styled.div`
  border-radius: 2px;
  border: 1px solid #E87D18;
  background: #1287A5;
  box-shadow: 0px 2px 0px 0px #C24606, 0px -2px 0px 0px #0F7088 inset, 0px 2px 0px 0px #0F7088 inset;
  width: 26px;
  height: 26px;
  flex-shrink: 0;
  position: relative;
`

