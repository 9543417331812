import {IconProps} from "../../../model";

export const BoostIcon = (props: IconProps) => {
    return(
        <svg
            {...props}
            xmlns="http://www.w3.org/2000/svg" width="26" height="29" viewBox="0 0 26 29" fill="none">
            <g clip-path="url(#clip0_61_1363)">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M2.41168 4.76501H23.9117C24.9317 4.76501 25.7617 5.59501 25.7617 6.61501V26.525C25.7617 27.545 24.9317 28.375 23.9117 28.375H2.41168C1.39168 28.375 0.561676 27.545 0.561676 26.525V6.61501C0.561676 5.59501 1.39168 4.76501 2.41168 4.76501Z" fill="#C24606"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M3.31168 0.625H23.0217C23.7717 0.625 24.4617 0.935 24.9617 1.435C25.4617 1.935 25.7717 2.625 25.7717 3.375V23.085C25.7717 23.835 25.4617 24.525 24.9617 25.025C24.4617 25.525 23.7717 25.835 23.0217 25.835H3.31168C2.56168 25.835 1.87168 25.525 1.37168 25.025C0.871676 24.525 0.561676 23.835 0.561676 23.085V3.375C0.561676 2.625 0.871676 1.935 1.37168 1.435C1.87168 0.935 2.55168 0.625 3.31168 0.625ZM23.0217 2.455H3.31168C3.06168 2.455 2.83168 2.555 2.66168 2.725C2.50168 2.895 2.39168 3.125 2.39168 3.375V23.085C2.39168 23.335 2.49168 23.565 2.66168 23.735C2.83168 23.905 3.05168 24.005 3.31168 24.005H23.0217C23.2717 24.005 23.5017 23.905 23.6717 23.735C23.8317 23.565 23.9417 23.335 23.9417 23.085V3.375C23.9417 3.125 23.8417 2.895 23.6717 2.725C23.5017 2.555 23.2817 2.455 23.0217 2.455Z" fill="#E87D18"/>
                <path d="M23.0217 1.54501H3.31167C2.30099 1.54501 1.48167 2.36433 1.48167 3.37501V23.085C1.48167 24.0957 2.30099 24.915 3.31167 24.915H23.0217C24.0324 24.915 24.8517 24.0957 24.8517 23.085V3.37501C24.8517 2.36433 24.0324 1.54501 23.0217 1.54501Z" fill="#FFB500"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M3.31167 1.54501H23.0217C24.0317 1.54501 24.8517 2.36501 24.8517 3.37501V13.225H1.48167V3.37501C1.48167 2.36501 2.30167 1.54501 3.31167 1.54501Z" fill="#FFC030"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M4.26167 2.61501H23.9717C24.2517 2.61501 24.5217 2.67501 24.7517 2.79501C24.5117 2.06501 23.8217 1.54501 23.0117 1.54501H3.31167C2.30167 1.54501 1.48167 2.36501 1.48167 3.37501V23.085C1.48167 23.815 1.91167 24.445 2.53167 24.745C2.47167 24.565 2.43167 24.365 2.43167 24.165V4.44501C2.43167 3.43501 3.25167 2.61501 4.26167 2.61501Z" fill="#FFCE61"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M4.01167 2.495C4.44167 2.495 4.84167 2.675 5.12167 2.955C5.41167 3.24499 5.58167 3.635 5.58167 4.065C5.58167 4.495 5.40167 4.895 5.12167 5.175C4.83167 5.465 4.44167 5.635 4.01167 5.635C3.58167 5.635 3.18167 5.455 2.90167 5.175C2.61167 4.885 2.44167 4.495 2.44167 4.065C2.44167 3.635 2.62167 3.235 2.90167 2.955C3.19167 2.665 3.58167 2.495 4.01167 2.495ZM4.80167 3.285C4.60167 3.085 4.32167 2.955 4.01167 2.955C3.70167 2.955 3.42167 3.085 3.22167 3.285C3.02167 3.485 2.89167 3.765 2.89167 4.075C2.89167 4.385 3.02167 4.66499 3.22167 4.865C3.42167 5.065 3.70167 5.195 4.01167 5.195C4.32167 5.195 4.60167 5.075 4.80167 4.865C5.00167 4.66499 5.13167 4.385 5.13167 4.075C5.13167 3.765 5.01167 3.485 4.80167 3.285Z" fill="#1A1A1A"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M4.01167 2.72501C4.75167 2.72501 5.36167 3.32501 5.36167 4.07501C5.36167 4.82501 4.76167 5.42501 4.01167 5.42501C3.26167 5.42501 2.66167 4.82501 2.66167 4.07501C2.66167 3.32501 3.26167 2.72501 4.01167 2.72501Z" fill="#4D4D4D"/>
                <path d="M4.93299 3.48404L2.94055 3.96643L3.10527 4.64677L5.09771 4.16439L4.93299 3.48404Z" fill="#1A1A1A"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M4.15168 6.01499C3.47168 6.01499 2.88168 5.60499 2.63168 5.01499C2.92168 5.48499 3.45168 5.80499 4.04168 5.80499C4.96168 5.80499 5.70168 5.06499 5.70168 4.14499C5.70168 3.91499 5.65168 3.68499 5.56168 3.48499C5.72168 3.73499 5.81168 4.03499 5.81168 4.35499C5.81168 5.27499 5.07168 6.01499 4.15168 6.01499Z" fill="#FFCE61"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M22.2517 2.495C22.6817 2.495 23.0817 2.675 23.3617 2.955C23.6517 3.24499 23.8217 3.635 23.8217 4.065C23.8217 4.495 23.6417 4.895 23.3617 5.175C23.0817 5.465 22.6817 5.635 22.2517 5.635C21.8217 5.635 21.4217 5.455 21.1417 5.175C20.8517 4.885 20.6817 4.495 20.6817 4.065C20.6817 3.635 20.8617 3.235 21.1417 2.955C21.4317 2.665 21.8217 2.495 22.2517 2.495ZM23.0417 3.285C22.8417 3.085 22.5617 2.955 22.2517 2.955C21.9417 2.955 21.6617 3.075 21.4617 3.285C21.2617 3.485 21.1317 3.765 21.1317 4.075C21.1317 4.385 21.2517 4.66499 21.4617 4.865C21.6617 5.065 21.9417 5.195 22.2517 5.195C22.5617 5.195 22.8417 5.065 23.0417 4.865C23.2417 4.66499 23.3717 4.385 23.3717 4.075C23.3717 3.765 23.2417 3.485 23.0417 3.285Z" fill="#1A1A1A"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M22.2517 2.72501C22.9917 2.72501 23.6017 3.32501 23.6017 4.07501C23.6017 4.82501 23.0017 5.42501 22.2517 5.42501C21.5017 5.42501 20.9017 4.82501 20.9017 4.07501C20.9017 3.32501 21.5017 2.72501 22.2517 2.72501Z" fill="#4D4D4D"/>
                <path d="M21.3911 3.42155L21.1683 4.08514L23.1117 4.73765L23.3345 4.07406L21.3911 3.42155Z" fill="#1A1A1A"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M22.4017 6.01499C21.7217 6.01499 21.1317 5.60499 20.8817 5.01499C21.1717 5.48499 21.7017 5.80499 22.2917 5.80499C23.2117 5.80499 23.9517 5.06499 23.9517 4.14499C23.9517 3.91499 23.9017 3.68499 23.8117 3.48499C23.9717 3.73499 24.0617 4.03499 24.0617 4.35499C24.0617 5.27499 23.3217 6.01499 22.4017 6.01499Z" fill="#FFCE61"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M4.83168 20.925C5.19168 21.145 5.43168 21.485 5.54168 21.865C5.64168 22.255 5.60168 22.685 5.38168 23.055L5.36168 23.085C5.14168 23.445 4.80168 23.695 4.42168 23.795C4.03168 23.895 3.60168 23.855 3.23168 23.635L3.20168 23.615C2.84168 23.395 2.60168 23.055 2.49168 22.675C2.39168 22.305 2.43168 21.905 2.62168 21.535L2.65168 21.475C2.87168 21.095 3.22168 20.845 3.61168 20.745C3.98168 20.645 4.39168 20.685 4.75168 20.875H4.79168L4.84168 20.925H4.83168ZM5.09168 21.985C5.02168 21.715 4.84168 21.465 4.57168 21.305C4.30168 21.145 4.00168 21.115 3.72168 21.195C3.44168 21.265 3.20168 21.445 3.04168 21.715C2.89168 21.985 2.86168 22.285 2.93168 22.565C3.00168 22.845 3.18168 23.095 3.45168 23.245C3.72168 23.395 4.02168 23.435 4.29168 23.355C4.57168 23.285 4.81168 23.105 4.97168 22.835L4.99168 22.805C5.13168 22.545 5.16168 22.255 5.09168 21.985Z" fill="#1A1A1A"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M4.68168 21.105C5.32168 21.475 5.54168 22.305 5.17168 22.945C4.80168 23.585 3.97168 23.805 3.33168 23.435C2.69168 23.065 2.47168 22.235 2.84168 21.595C3.21168 20.955 4.04168 20.735 4.68168 21.105Z" fill="#4D4D4D"/>
                <path d="M4.35462 21.2421L3.10411 22.8665L3.65879 23.2935L4.9093 21.6691L4.35462 21.2421Z" fill="#1A1A1A"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M4.15168 24.215C3.47168 24.215 2.88168 23.805 2.63168 23.215C2.92168 23.685 3.45168 24.005 4.04168 24.005C4.96168 24.005 5.70168 23.265 5.70168 22.345C5.70168 22.115 5.65168 21.885 5.56168 21.685C5.72168 21.935 5.81168 22.235 5.81168 22.555C5.81168 23.475 5.07168 24.215 4.15168 24.215Z" fill="#FFCE61"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M22.2517 20.695C22.6817 20.695 23.0817 20.875 23.3617 21.155C23.6517 21.445 23.8217 21.835 23.8217 22.265C23.8217 22.695 23.6417 23.095 23.3617 23.375C23.0817 23.665 22.6817 23.835 22.2517 23.835C21.8217 23.835 21.4217 23.655 21.1417 23.375C20.8517 23.085 20.6817 22.695 20.6817 22.265C20.6817 21.835 20.8617 21.435 21.1417 21.155C21.4317 20.875 21.8217 20.695 22.2517 20.695ZM23.0417 21.485C22.8417 21.285 22.5617 21.155 22.2517 21.155C21.9417 21.155 21.6617 21.275 21.4617 21.485C21.2617 21.685 21.1317 21.965 21.1317 22.275C21.1317 22.585 21.2517 22.865 21.4617 23.065C21.6617 23.265 21.9417 23.395 22.2517 23.395C22.5617 23.395 22.8417 23.265 23.0417 23.065C23.2417 22.865 23.3717 22.585 23.3717 22.275C23.3717 21.965 23.2417 21.685 23.0417 21.485Z" fill="#1A1A1A"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M22.2517 20.925C22.9917 20.925 23.6017 21.525 23.6017 22.275C23.6017 23.025 23.0017 23.625 22.2517 23.625C21.5017 23.625 20.9017 23.025 20.9017 22.275C20.9017 21.525 21.5017 20.925 22.2517 20.925Z" fill="#4D4D4D"/>
                <path d="M21.6529 21.3734L21.2262 21.9283L22.8512 23.1779L23.2779 22.623L21.6529 21.3734Z" fill="#1A1A1A"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M22.4017 24.215C21.7217 24.215 21.1317 23.805 20.8817 23.215C21.1717 23.685 21.7017 24.005 22.2917 24.005C23.2117 24.005 23.9517 23.265 23.9517 22.345C23.9517 22.115 23.9017 21.885 23.8117 21.685C23.9717 21.935 24.0617 22.235 24.0617 22.555C24.0617 23.475 23.3217 24.215 22.4017 24.215Z" fill="#FFCE61"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M18.1917 13.145L18.8117 13.175L17.2417 14.345L12.0317 18.235L12.3917 17.465L12.6217 16.975L13.2317 15.675L13.4717 15.155L13.7517 14.555L13.0917 14.495L12.8217 14.465L11.5117 14.335L11.5317 14.285L11.8217 13.645L14.2017 8.44502L14.5217 7.73502H20.3617L16.6017 12.215L15.9917 12.935L16.9317 12.985H16.9617L16.9417 13.015L16.8917 13.075L17.7917 13.125H17.8417L18.1917 13.145Z" fill="#FFCE61"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M17.6517 12.775L18.6017 12.825L18.5617 12.855L18.4817 12.915L17.0317 13.995L13.3117 16.765L11.8117 17.885L12.4217 16.585L13.2517 14.805L13.5117 14.255L13.5317 14.205L12.8717 14.145L12.2217 14.075L11.3017 13.985L11.6117 13.305L13.9217 8.23501L14.3117 7.38501H20.8217L20.7017 7.52501H21.0517L17.2917 12.005L16.6817 12.725L17.6217 12.775H17.6517Z" fill="#FFCE61"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M11.4217 7.285L7.62168 15.635L9.84168 15.855L7.10168 21.715L13.0817 17.255L18.8817 12.935L18.9617 12.875L18.5617 12.855L17.6317 12.805L16.3317 12.735L20.7017 7.525L20.8217 7.385L20.9017 7.285H11.4217ZM14.4017 13.575L16.3217 13.675L13.9217 15.465L12.4217 16.585L9.53168 18.735L11.2517 15.055L9.01168 14.835L12.0217 8.235H18.8817L14.4017 13.575Z" fill="#E87D18"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M19.9017 7.76501H11.7217L8.32168 15.235L10.5517 15.455L8.32168 20.225L17.6417 13.275L15.3717 13.155L19.9017 7.76501Z" fill="url(#paint0_linear_61_1363)"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M18.8917 8.23502H12.0217L9.33168 14.145L9.02168 14.835L10.5917 14.995L11.2517 15.055L10.9717 15.655L9.53168 18.735L14.7517 14.845L16.3217 13.675L15.3417 13.625L14.4017 13.575L15.0117 12.855L18.8917 8.23502Z" fill="url(#paint1_linear_61_1363)"/>
            </g>
            <defs>
                <linearGradient id="paint0_linear_61_1363" x1="11.7817" y1="20.195" x2="15.0017" y2="8.18501" gradientUnits="userSpaceOnUse">
                    <stop/>
                    <stop offset="0.14" stop-color="#191A1A"/>
                    <stop offset="0.29" stop-color="#2D2E2E"/>
                    <stop offset="0.46" stop-color="#3B3C3D"/>
                    <stop offset="0.66" stop-color="#434445"/>
                    <stop offset="1" stop-color="#464748"/>
                </linearGradient>
                <linearGradient id="paint1_linear_61_1363" x1="10.7417" y1="18.835" x2="17.1217" y2="4.34502" gradientUnits="userSpaceOnUse">
                    <stop/>
                    <stop offset="0.14" stop-color="#191A1A"/>
                    <stop offset="0.29" stop-color="#2D2E2E"/>
                    <stop offset="0.46" stop-color="#3B3C3D"/>
                    <stop offset="0.66" stop-color="#434445"/>
                    <stop offset="1" stop-color="#464748"/>
                </linearGradient>
                <clipPath id="clip0_61_1363">
                    <rect width="25.21" height="27.75" fill="white" transform="translate(0.561676 0.625)"/>
                </clipPath>
            </defs>
        </svg>
    )
}