import styled from "styled-components";
import {useEffect, useState} from "react";

interface Props {
    total: number;
    current: number;
}

export const Scale = ({total, current}: Props) => {
    const scoreHeight = 125 / total; // Calculate height based on total
    const [currentScore, setCurrentScore] = useState(0);

    useEffect(() => {
        setCurrentScore(current)
    }, [current]);



    return (
        <Absolute>
            <Relative>
                <Circle>
                    <Text>{current}</Text>
                </Circle>
                <Container>
                    <Container2>
                        {[...Array(total)].map((_, index) => (
                            <Score
                                key={index}
                                isFirst={index === 0}
                                isActive={index < currentScore}
                                height={scoreHeight}
                            />
                        ))}
                    </Container2>
                </Container>
            </Relative>
        </Absolute>
    );
};

const Absolute = styled.div`
  position: absolute;
  top: 40%;
  left: 10px;
`;

const Relative = styled.div`
  position: relative;
`;

const Container = styled.div`
  border: 1px solid #e87d18;
  background: #ffc541;
  width: 16px;
  height: 173px;
  border-radius: 50px;
  flex-shrink: 0;
  position: relative;

  display: flex;
  align-items: center;
  justify-content: center;
`;

const Container2 = styled.div`
  border: 1px solid #e87d18;
  background: #1287a5;
  width: 12px;
  top: 0;
  left: 0;
  height: 169px;
  border-radius: 50px;
  flex-shrink: 0;
  align-items: center;
  display: flex;
  flex-direction: column-reverse;
  overflow: hidden;
`;

const Score = styled.div<{ isFirst?: boolean; isActive?: boolean; height: number }>`
  //border: 1px solid #0d647a;
  border: ${props => (props.isActive ? "1px solid #0D647A" : "0px")};
  border-bottom-width: ${props => (props.isFirst ? "1px" : "0px")};
  border-bottom-color: ${props => (props.isFirst ? "#E0DEDE" : "#0D647A")};

  background: ${props => (props.isActive ? "#E0DEDE" : "#1287A5")};
  width: 11px;
  height: ${props => props.height}px;
  flex-shrink: 0;
`;

const Circle = styled.div`
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background: linear-gradient(90deg, #ffa901 0%, #ffc71e 100%);
  border: 1px solid #e87d18;
  flex-shrink: 0;
  position: absolute;
  top: -5px;
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  left: -4px;
`;

const Text = styled.div`
  color: #fff;
  text-align: center;
  font-family: "American Captain";
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 133.333% */
  letter-spacing: 1px;
  text-transform: uppercase;
  margin-top: 4px;
`;
