import {IconProps} from "../../model";

export const StarsIcon = (props:IconProps) => {
    return (
        <svg
            {...props}
            xmlns="http://www.w3.org/2000/svg" width="76" height="78" viewBox="0 0 76 78" fill="none">
            <g filter="url(#filter0_d_593_10896)">
                <rect x="0.5" y="0.5" width="75" height="75" fill="#FFB500"/>
                <rect x="1" y="1" width="74" height="74" stroke="#E87D18"/>
            </g>
            <path
                d="M28.6906 26.4631L32.9629 26.1607C33.3265 26.1363 33.6426 25.9086 33.7791 25.5789L36.4935 19.0087C36.7987 18.2947 37.1045 17.5815 37.4083 16.8661C37.4097 16.8632 37.4112 16.8603 37.4126 16.8582C38.1894 15.6234 40.1475 15.7211 40.7381 17.1512L44.219 25.5789C44.3555 25.9086 44.6724 26.1363 45.0338 26.1607L48.9666 26.4387C49.7711 26.5156 50.5764 26.591 51.3809 26.6642C52.3643 26.754 53.3492 26.828 54.3326 26.9185C55.3883 27.0155 56.1643 27.9801 55.9701 29.0059C55.8964 29.3952 55.6905 29.7708 55.3313 30.0682L48.2606 35.9294C47.9825 36.1593 47.862 36.5256 47.9489 36.8733L50.1676 45.7032C50.572 47.3129 48.7848 48.5907 47.3532 47.7151L39.5028 42.9105C39.1954 42.7209 38.8041 42.7209 38.4953 42.9105L30.6449 47.7151C29.2717 48.557 27.5677 47.4091 27.7933 45.8907C27.7933 45.8892 27.7933 45.8871 27.7941 45.8856C27.8554 45.6256 27.9174 45.3663 27.9802 45.1063C28.2109 44.1467 28.4511 43.1885 28.7008 42.2346C28.7066 42.2188 28.7096 42.203 28.7139 42.185C28.7877 41.9085 28.8629 41.6305 28.9373 41.354C29.1936 40.4144 29.9748 39.8326 30.8143 39.3435C33.2316 37.9349 35.6357 36.5091 38.0456 35.0876C38.1369 35.0337 38.2836 34.921 38.4063 34.824C38.5654 34.6983 38.7048 34.5475 38.8078 34.3736C38.9385 34.1524 39.0757 33.9118 38.8698 33.4183C38.6596 32.9162 38.0682 32.9823 37.7368 33.0146C34.8457 33.2969 32.5636 33.4995 29.9463 33.7875C28.8139 33.9118 27.7663 33.8601 26.8048 33.4937C26.799 33.4916 26.7939 33.4887 26.7895 33.4851L22.6668 30.0675C22.3697 29.8211 22.1769 29.5216 22.0777 29.2048C21.7447 28.1446 22.5223 27.0564 23.6451 26.948L28.6906 26.4617V26.4631Z"
                fill="#333333"/>

            <defs>
                <filter id="filter0_d_593_10896" x="0.5" y="0.5" width="75" height="77" filterUnits="userSpaceOnUse"
                        color-interpolation-filters="sRGB">
                    <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                                   result="hardAlpha"/>
                    <feOffset dy="2"/>
                    <feComposite in2="hardAlpha" operator="out"/>
                    <feColorMatrix type="matrix"
                                   values="0 0 0 0 0.631373 0 0 0 0 0.227451 0 0 0 0 0.0235294 0 0 0 1 0"/>
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_593_10896"/>
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_593_10896" result="shape"/>
                </filter>
            </defs>
        </svg>
    )
}