import styled from "styled-components";
import { useSpring, animated } from 'react-spring';
import React from 'react';

interface BorderedTitleProps extends React.HTMLAttributes<HTMLDivElement> {
    title: string;
}

export const Balance = (props: BorderedTitleProps) => {
    // Define the animation using react-spring's useSpring hook
    const springProps = useSpring({
        from: {value: 0},
        config: {duration: 1000},
        value: Number(props.title)
     });

    return (
        <Text>
            <animated.div>
                {springProps.value.to((val) => Math.floor(val))}
            </animated.div>
        </Text>
    );
}

// Use the animated.div instead of a regular div to apply animations
const Text = styled.div`

    color: rgba(241, 172, 0, 1);
    font-size: 52px;
    line-height: 52px;
    letter-spacing: 1px;
    font-style: normal;
    font-weight: 400;
    text-transform: uppercase;
    text-shadow: 1px 1px #a13a06,
    -1px -1px 0 #a13a06,
    1px -1px 0 #a13a06,
    -1px 1px 0 #a13a06,
    0px 1px 0 #a13a06,
    1px 0px 0 #a13a06,
    -1px 0px 0 #a13a06,
    0px -1px 0 #a13a06;
`