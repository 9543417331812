import {create} from 'zustand';
import {Boost} from "../api/requests/area";

type BoostState = {
    boosts: Boost[] | null;
    setBoosts: (boosts: (prev: Boost[]) => Boost[] | null) => void;
};

export const useBoostsStore = create<BoostState>((set) => ({
    boosts: null,
    setBoosts: (boosts) => set((state) => ({boosts: boosts(state.boosts!!)})),
}));
