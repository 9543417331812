import {IconProps} from "../../model";

export const HeartIcon = (props: IconProps) => {
    return (
        <svg
            {...props}
            xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
            <path fill-rule="evenodd" clip-rule="evenodd"
                  d="M9.9729 2.38938C10.3523 1.85841 10.7498 1.39823 11.2195 1.04425C12.1048 0.353982 13.1346 0 14.5077 0C16.0253 0 17.3984 0.60177 18.3921 1.57522C19.3857 2.54867 20 3.89381 20 5.38053C20 10.1947 15.3388 14.7788 10.6594 19.3451L9.99097 20L9.32249 19.3451C7.04607 17.115 4.76965 14.885 3.01716 12.5841C1.21048 10.2124 0 7.82301 0 5.38053C0 3.89381 0.614273 2.54867 1.60795 1.57522C2.60163 0.60177 3.97471 0 5.49232 0C6.84733 0 7.89521 0.353982 8.78049 1.04425C9.25023 1.39823 9.6477 1.85841 10.0271 2.38938H9.9729ZM12.4119 2.49558C11.8338 2.95575 11.346 3.68142 10.822 4.67257L9.9729 6.33628L9.12376 4.67257C8.61789 3.68142 8.13008 2.93805 7.53388 2.49558C7.00994 2.0885 6.34146 1.87611 5.43812 1.87611C4.44444 1.87611 3.55917 2.26549 2.90876 2.90265C2.25836 3.53982 1.86089 4.40708 1.86089 5.38053C1.86089 7.36283 2.90876 9.41593 4.49865 11.469C5.98013 13.3982 7.94941 15.3805 9.95483 17.3451C14.1102 13.2743 18.0488 9.20354 18.0488 5.36283C18.0488 4.38938 17.6513 3.52212 17.0009 2.88496C16.3505 2.24779 15.4652 1.85841 14.4715 1.85841C13.5682 1.85841 12.8997 2.0708 12.3758 2.47788L12.4119 2.49558Z"
                  fill="#4C2600"/>
            <path fill-rule="evenodd" clip-rule="evenodd"
                  d="M9.97288 4.26542C11.1111 2.05303 12.2312 0.937988 14.5077 0.937988C17.0009 0.937988 19.0424 2.92029 19.0424 5.38047C19.0424 9.82295 14.5077 14.2477 9.99094 18.6902C5.45617 14.2477 0.939453 9.82295 0.939453 5.38047C0.939453 2.93799 2.96294 0.937988 5.47423 0.937988C7.73259 0.937988 8.8708 2.05303 10.009 4.26542H9.97288Z"
                  fill="#B90000"/>
            <mask id="mask0_232_12250"
                // style="mask-type:luminance"
                  style={{maskType: "luminance"}}
                  maskUnits="userSpaceOnUse" x="0" y="0" width="20" height="19">
                <path
                    d="M9.97288 4.26542C11.1111 2.05303 12.2312 0.937988 14.5077 0.937988C17.0009 0.937988 19.0424 2.92029 19.0424 5.38047C19.0424 9.82295 14.5077 14.2477 9.99094 18.6902C5.45617 14.2477 0.939453 9.82295 0.939453 5.38047C0.939453 2.93799 2.96294 0.937988 5.47423 0.937988C7.73259 0.937988 8.8708 2.05303 10.009 4.26542H9.97288Z"
                    fill="white"/>
            </mask>
            <g mask="url(#mask0_232_12250)">
                <path fill-rule="evenodd" clip-rule="evenodd"
                      d="M3.75788 -8.86731H12.701L4.31795 19.2566H-4.62514L3.75788 -8.86731Z"
                      fill="url(#paint0_linear_232_12250)"/>
                <path fill-rule="evenodd" clip-rule="evenodd"
                      d="M13.4417 -8.86731H16.495L8.11199 19.2566H5.07676L13.4417 -8.86731Z"
                      fill="url(#paint1_linear_232_12250)"/>
                <path fill-rule="evenodd" clip-rule="evenodd"
                      d="M21.0298 -8.86731H29.9729L21.5899 19.2566H12.6468L21.0298 -8.86731Z"
                      fill="url(#paint2_linear_232_12250)"/>
            </g>
            <path fill-rule="evenodd" clip-rule="evenodd"
                  d="M9.73805 14.8672C5.81754 11.7698 1.9151 8.6725 0.921425 5.57516C1.06596 9.94684 5.5104 14.3008 9.97292 18.6725C14.5077 14.23 19.0244 9.80524 19.0244 5.36277C19.0244 4.86719 18.9341 4.38932 18.7895 3.92914C18.7895 3.98224 18.7895 4.05303 18.7895 4.10613C18.7895 7.69905 14.2548 11.2743 9.73805 14.8672Z"
                  fill="#9B0000"/>
            <path fill-rule="evenodd" clip-rule="evenodd"
                  d="M1.53571 5.36279C1.71638 3.29199 3.48693 1.68137 5.65496 1.68137C6.7751 1.68137 7.58811 1.98226 8.25658 2.56633C7.53391 1.71677 6.6667 1.29199 5.36589 1.29199C3.234 1.29199 1.49958 2.99111 1.49958 5.0796C1.49958 5.1327 1.49958 5.2035 1.49958 5.25659C1.49958 5.29199 1.51765 5.32739 1.53571 5.36279Z"
                  fill="white"/>
            <path fill-rule="evenodd" clip-rule="evenodd"
                  d="M10.6956 4.72562C11.2557 2.72562 13.3153 1.45129 15.4291 1.84067C16.5312 2.03536 17.2719 2.47784 17.832 3.1858C17.29 2.21235 16.5131 1.64598 15.2304 1.41589C13.1346 1.02651 11.1111 2.38934 10.7136 4.44244C10.7136 4.49554 10.6956 4.56633 10.6775 4.61943C10.6775 4.65483 10.6775 4.69023 10.6775 4.72562H10.6956Z"
                  fill="white"/>
            <defs>
                <linearGradient id="paint0_linear_232_12250" x1="4.04695" y1="19.2566" x2="4.04695" y2="-8.81421"
                                gradientUnits="userSpaceOnUse">
                    <stop stop-color="#B2282D"/>
                    <stop offset="1" stop-color="#ED1C24"/>
                </linearGradient>
                <linearGradient id="paint1_linear_232_12250" x1="10.7859" y1="19.2566" x2="10.7859" y2="-8.81421"
                                gradientUnits="userSpaceOnUse">
                    <stop stop-color="#B2282D"/>
                    <stop offset="1" stop-color="#ED1C24"/>
                </linearGradient>
                <linearGradient id="paint2_linear_232_12250" x1="21.3189" y1="19.2566" x2="21.3189" y2="-8.81421"
                                gradientUnits="userSpaceOnUse">
                    <stop stop-color="#B2282D"/>
                    <stop offset="1" stop-color="#ED1C24"/>
                </linearGradient>
            </defs>
        </svg>
    )
}