import {postEvent} from "@telegram-apps/sdk";

export const tgLink =(link: string) => {
    if(link.startsWith('https://t.me/')){
        postEvent('web_app_open_tg_link', {path_full: link.replace('https://t.me', '')})
        console.log('web_app_open_tg_link', {path_full: link.replace('https://t.me', '')})
    } else if(link.startsWith('t.me/')){
        postEvent('web_app_open_tg_link', {path_full: link.replace('t.me', '')})
        console.log('web_app_open_tg_link', {path_full: link.replace('t.me', '')})
    } else {
        console.log('web_app_open_link', {url: link})
        postEvent('web_app_open_link', {url: link})
    }
}