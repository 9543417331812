import styled, {keyframes} from "styled-components";
import {ColorButton, ColorButtonProps} from "./color-button";
import {IconProps} from "../model";


interface ActionModalProps {
    open: boolean;
    onClose?: () => void;
    combo: number;
    high: number;
    middle: number;
    low: number;

}

export const UWinModal = ({open, onClose, combo, high, middle, low}: ActionModalProps) => {

    if (!open) return null;

    return (
        <Wrapper>
            <Container>
                <Title>
                    Congratulations!
                </Title>
                <SubTitle>
                    Your score
                </SubTitle>
                <Column>
                    <ScoreItem title={'Combo'} value={combo}/>
                    <ScoreItem title={'High'} value={high}/>
                    <ScoreItem title={'Middle'} value={middle}/>
                    <ScoreItem title={'Low'} value={low}/>
                </Column>
                <ColorButton
                    onClick={onClose!!}
                    color={'linear-gradient(0deg, #28B236 0%, #1CED63 99.86%)'}
                    borderColor={'#76FF7C'}
                    text={'Continue'}
                />

                {/*<ColorButton color={color} borderColor={borderColor} onClick={onClick} text={text}/>*/}
            </Container>
        </Wrapper>

    )
}


const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: scale(0.8);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
`;


const Wrapper = styled.div`
  background: rgba(0, 0, 0, 0.20);
  backdrop-filter: blur(7px);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 100;
`


const SubTitle = styled.div`
  color: #FFF;
  text-align: center;
  font-family: Roboto;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px; /* 100% */
  letter-spacing: -0.4px;
  text-transform: uppercase;
`

const Title = styled.div`
  color: #FFFFFF;
  font-size: 46px;
  line-height: 46px;
  letter-spacing: 1px;
  font-style: normal;
  font-weight: 400;
  text-transform: uppercase;
  text-shadow: 1px 1px #C96814,
    -1px -1px 0 #C96814,
    1px -1px 0 #C96814,
  -1px 1px 0 #C96814,
  0px 1px 0 #C96814,
  1px 0px 0 #C96814,
  -1px 0px 0 #C96814,
    0px -1px 0 #C96814;
`


const Container = styled.div`
  display: flex;
  width: 80%;
  padding: 28px 28px 32px 28px;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 327px;
  border-radius: 12px;
  border: 4px solid #F9D838;
  background: #0F7088;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  backdrop-filter: blur(10px);
  animation: ${fadeIn} 0.3s ease-in-out;
  position: relative;

`

const Column = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  width: 100%;
`


const CloseIconSWG = (props: IconProps) => {
    return (
        <svg
            {...props}
            xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
            <path fill-rule="evenodd" clip-rule="evenodd"
                  d="M3.29289 3.29289C3.68342 2.90237 4.31658 2.90237 4.70711 3.29289L10 8.58579L15.2929 3.29289C15.6834 2.90237 16.3166 2.90237 16.7071 3.29289C17.0976 3.68342 17.0976 4.31658 16.7071 4.70711L11.4142 10L16.7071 15.2929C17.0976 15.6834 17.0976 16.3166 16.7071 16.7071C16.3166 17.0976 15.6834 17.0976 15.2929 16.7071L10 11.4142L4.70711 16.7071C4.31658 17.0976 3.68342 17.0976 3.29289 16.7071C2.90237 16.3166 2.90237 15.6834 3.29289 15.2929L8.58579 10L3.29289 4.70711C2.90237 4.31658 2.90237 3.68342 3.29289 3.29289Z"
                  fill="white"/>
        </svg>
    )
}


const CLoseIcon = styled(CloseIconSWG)`
  position: absolute;
  top: 16px;
  right: 16px;
  cursor: pointer;
`


const ScoreItem = (props: { title: string, value: number }) => (
    <ScoreItemRow>
        <ScoreItemRowText>
            {props.title}
        </ScoreItemRowText>
        <Line/>
        <ScoreItemRowText>
            {props.value}
        </ScoreItemRowText>
    </ScoreItemRow>
)


const ScoreItemRow = styled.div`
  display: flex;
  width: 76%;
  align-items: flex-end;
  gap: 5px;
  justify-content: space-between;
`


const ScoreItemRowText = styled.div`
  color: #FFF;
  font-family: Roboto;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px; /* 123.077% */
  letter-spacing: -0.078px;
`

const Line = styled.div`
  width: 227px;
  border-style: dashed solid;
  border-color: #FFF;
  border-width: 1px;
  margin-bottom: 4px;
`

