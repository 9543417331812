import styled from "styled-components";


interface Props {
    title: string;
    value: number;
}

export const Info = ({title, value}: Props) => {
    return (
        <ThreeDContainer>
            <Label>
                {title}
            </Label>
            <Value>
                {value}
            </Value>
        </ThreeDContainer>
    )
}


const ThreeDContainer = styled.div`
  box-sizing: border-box;
  border-radius: 12px;
  border: 1px solid #F1AC00;
  background: rgba(15, 112, 136, 0.80);
  box-shadow: 0px 2px 0px 0px #C24606;
  display: flex;
  width: 100%;
  padding: 8px 30px;
  flex-direction: column;
  align-items: center;
  gap: 3px;

`


const Label = styled.div`
  font-family: Roboto;
  font-size: 10px;
  font-weight: 500;
  line-height: 11px;
  letter-spacing: -0.02em;
  text-align: left;
  color: #FFFFFF;
`
const Value = styled.div`
  font-size: 12px;
  font-weight: 400;
  line-height: 13.2px;
  letter-spacing: 0.1em;
  text-align: left;
  color: #FFFFFF;

`
