
import {create} from 'zustand';
import {TaskGroup} from "../api/requests/user-info";

type State = {
    tasks: TaskGroup[] | null;
    setTasks: (tasks: TaskGroup[]) => void;
};

export const useTaskStore = create<State>((set) => ({
    tasks: null,
    setTasks: (tasks) => set({tasks}),
}));