import {IconProps} from "../../model";

export const CLockIcon = (props: IconProps) => {
    return (
        <svg
            {...props}
            xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40" fill="none">
            <path fill-rule="evenodd" clip-rule="evenodd"
                  d="M4.36059 -0.00523376H35.6394C36.8344 -0.00523376 37.9245 0.48743 38.7212 1.27359C39.5073 2.07024 40 3.16037 40 4.35534V35.6342C40 36.8291 39.5073 37.9193 38.7212 38.7159C37.935 39.5126 36.8344 40.0052 35.6394 40.0052H4.36059C3.16562 40.0052 2.07547 39.5126 1.27883 38.7264C0.492664 37.9298 0 36.8396 0 35.6446V4.36583C0 3.17086 0.492664 2.08072 1.27883 1.28407C2.06499 0.487424 3.15514 -0.00523376 4.36059 -0.00523376ZM35.6394 2.90881H4.36059C3.96227 2.90881 3.5954 3.06604 3.33334 3.32809C3.07129 3.60063 2.91404 3.95702 2.91404 4.35534V35.6342C2.91404 36.0325 3.07129 36.3994 3.33334 36.6614C3.5954 36.9235 3.96227 37.0912 4.36059 37.0912H35.6394C36.0377 37.0912 36.4046 36.9235 36.6667 36.6719C36.9287 36.3994 37.086 36.043 37.086 35.6446V4.36583C37.086 3.96751 36.9287 3.60064 36.6667 3.33858C36.4046 3.07653 36.0377 2.90881 35.6394 2.90881Z"
                  fill="#E87D18"/>
            <path
                d="M35.6392 1.45183H4.37083C2.76145 1.45183 1.45679 2.75649 1.45679 4.36587V35.6342C1.45679 37.2436 2.76145 38.5483 4.37083 38.5483H35.6392C37.2486 38.5483 38.5532 37.2436 38.5532 35.6342V4.36587C38.5532 2.75649 37.2486 1.45183 35.6392 1.45183Z"
                fill="#FFB500"/>
            <path fill-rule="evenodd" clip-rule="evenodd"
                  d="M4.36058 1.45183H35.6394C37.2432 1.45183 38.543 2.7621 38.543 4.36587V20.0053H1.44653V4.36587C1.44653 2.7621 2.7568 1.45183 4.36058 1.45183Z"
                  fill="#FFC030"/>
            <g clip-path="url(#clip0_132_3645)">
                <path fill-rule="evenodd" clip-rule="evenodd"
                      d="M14.1296 11.8933C16.277 10.3431 18.8437 9.77128 21.2833 10.0889C23.8246 10.4193 26.2388 11.7154 27.9161 13.9009C29.6061 16.0864 30.2414 18.7548 29.911 21.2834C29.5807 23.8247 28.2846 26.2389 26.0991 27.9161C23.9136 29.6061 21.2452 30.2414 18.7166 29.9111C16.1753 29.5807 13.7611 28.2846 12.0838 26.0991L11.9059 25.8577C10.343 23.723 9.77125 21.1563 10.0889 18.7166C10.4193 16.1753 11.7153 13.7611 13.9009 12.0839L14.1423 11.906L14.1296 11.8933ZM21.0673 11.7662C18.958 11.4867 16.7471 12.0203 14.9301 13.418L14.892 13.4435C13.1003 14.8412 12.033 16.8361 11.7662 18.9199C11.4993 21.0292 12.0203 23.2402 13.418 25.0572L13.4434 25.0953C14.8411 26.8869 16.8361 27.9543 18.9199 28.2338C21.0292 28.5006 23.2401 27.9797 25.0571 26.582C26.8742 25.1842 27.9542 23.1766 28.2338 21.0673C28.5006 18.9581 27.9796 16.7471 26.5819 14.9301C25.1842 13.1131 23.1766 12.033 21.0673 11.7662Z"
                      fill="#666666"/>
                <path fill-rule="evenodd" clip-rule="evenodd"
                      d="M14.4091 12.7446C18.4243 9.65692 24.1677 10.4066 27.2426 14.4091C30.3303 18.4117 29.5806 24.155 25.5781 27.2427C21.5755 30.3304 15.8322 29.5807 12.7445 25.5781C9.66957 21.5756 10.4065 15.8323 14.4091 12.7446Z"
                      fill="#CBCBCB"/>
                <path fill-rule="evenodd" clip-rule="evenodd"
                      d="M15.6672 14.3837C18.7676 11.9949 23.2148 12.5667 25.6036 15.6798C27.9925 18.7802 27.4207 23.2274 24.3076 25.6163C21.2072 28.0051 16.7599 27.4333 14.3711 24.3329C11.9823 21.2325 12.5541 16.7853 15.6672 14.3964V14.3837Z"
                      fill="#F3F3F3"/>
                <path fill-rule="evenodd" clip-rule="evenodd"
                      d="M25.7814 15.5019C28.2465 18.7039 27.6492 23.3164 24.4472 25.7815C21.2452 28.2465 16.6327 27.6493 14.1677 24.4473C13.9898 24.2058 13.8246 23.9644 13.6721 23.7103C13.85 24.0407 14.066 24.3583 14.3074 24.676C16.8233 27.9416 21.5247 28.5515 24.7903 26.0356C28.0559 23.5197 28.6658 18.8183 26.1499 15.5527C24.6759 13.6341 22.4523 12.6302 20.2032 12.6429C22.3252 12.7065 24.3964 13.6849 25.7814 15.5019Z"
                      fill="white"/>
                <path fill-rule="evenodd" clip-rule="evenodd"
                      d="M14.9301 13.1004C18.526 10.3304 23.5451 10.7116 26.6836 13.8119C23.5324 10.521 18.3354 10.0635 14.6505 12.9098C10.6988 15.9466 9.96183 21.6137 12.9987 25.5654C14.2693 27.2173 15.9974 28.2973 17.8653 28.7929C16.1118 28.2846 14.498 27.23 13.2909 25.6671C10.2795 21.7408 11.0038 16.1245 14.9301 13.1004Z"
                      fill="white"/>
                <path d="M15.9326 14.3842L15.6506 14.6012L16.2707 15.4067L16.5526 15.1896L15.9326 14.3842Z"
                      fill="black"/>
                <path d="M13.7041 17.2007L13.5686 17.5297L14.5086 17.9168L14.644 17.5878L13.7041 17.2007Z"
                      fill="black"/>
                <path d="M14.1983 20.622L13.1909 20.7579L13.2385 21.1105L14.2459 20.9746L14.1983 20.622Z" fill="black"/>
                <path d="M15.3204 23.4696L14.5151 24.09L14.7323 24.3718L15.5375 23.7515L15.3204 23.4696Z" fill="black"/>
                <path opacity="0.5" fill-rule="evenodd" clip-rule="evenodd"
                      d="M16.9885 24.8285C16.1626 23.7611 15.7179 22.5159 15.6289 21.2579C16.7217 21.9441 18.1702 21.9187 19.263 21.0801C20.648 20.0127 20.9148 18.0178 19.8475 16.6201C19.4281 16.0737 18.8564 15.6925 18.2337 15.5146C19.7331 14.371 21.5755 13.9644 23.3163 14.244C21.0546 12.9098 18.1194 12.9987 15.9085 14.7014C13.0114 16.9377 12.465 21.0928 14.7013 23.9898C15.7687 25.3748 17.2808 26.2262 18.8818 26.4803C18.1702 26.061 17.5349 25.5019 17.0012 24.8158L16.9885 24.8285Z"
                      fill="white"/>
                <path d="M17.7223 25.37L17.3345 26.3096L17.6633 26.4454L18.0512 25.5057L17.7223 25.37Z" fill="black"/>
                <path d="M21.1075 25.7868L20.7549 25.8343L20.8906 26.8417L21.2432 26.7942L21.1075 25.7868Z"
                      fill="black"/>
                <path d="M23.8954 24.4901L23.6138 24.7075L24.2348 25.5122L24.5165 25.2948L23.8954 24.4901Z"
                      fill="black"/>
                <path d="M25.6621 21.9691L25.5266 22.2981L26.4665 22.6853L26.602 22.3563L25.6621 21.9691Z"
                      fill="black"/>
                <path d="M26.9407 18.7747L25.9331 18.909L25.9801 19.2617L26.9877 19.1274L26.9407 18.7747Z"
                      fill="black"/>
                <path d="M25.4356 15.5021L24.6301 16.1221L24.8472 16.4041L25.6526 15.784L25.4356 15.5021Z"
                      fill="black"/>
                <path d="M22.5028 13.4266L22.1155 14.3664L22.4444 14.502L22.8318 13.5622L22.5028 13.4266Z"
                      fill="black"/>
                <path d="M19.2699 13.058L18.9172 13.1051L19.0517 14.1127L19.4043 14.0656L19.2699 13.058Z" fill="black"/>
                <path fill-rule="evenodd" clip-rule="evenodd"
                      d="M23.4436 15.8958C23.6724 16.0229 23.7486 16.3024 23.6215 16.5311L21.3598 20.6353C21.2327 20.864 20.9532 20.9403 20.7245 20.8132C20.4957 20.6861 20.4195 20.4066 20.5466 20.1779L22.8083 16.0737C22.9354 15.845 23.2149 15.7687 23.4436 15.8958Z"
                      fill="black"/>
                <path fill-rule="evenodd" clip-rule="evenodd"
                      d="M21.131 20.0508C21.5884 20.305 21.7536 20.8768 21.4994 21.3342C21.2453 21.7916 20.6735 21.9568 20.2034 21.7027C19.7459 21.4485 19.5808 20.8767 19.8349 20.4193C20.089 19.9619 20.6608 19.7967 21.131 20.0508Z"
                      fill="black"/>
                <path fill-rule="evenodd" clip-rule="evenodd"
                      d="M20.8259 20.6099C20.9784 20.6989 21.0292 20.8895 20.953 21.0292C20.864 21.1817 20.6734 21.2325 20.5337 21.1563C20.3812 21.0673 20.3304 20.8767 20.4066 20.737C20.4956 20.5845 20.6862 20.5337 20.8259 20.6099Z"
                      fill="white"/>
            </g>
            <path fill-rule="evenodd" clip-rule="evenodd"
                  d="M5.88027 3.16046H37.1591C37.5993 3.16046 38.0291 3.26529 38.4065 3.44349C38.0186 2.29045 36.9285 1.46236 35.6497 1.46236H4.37083C2.76706 1.46236 1.45679 2.77263 1.45679 4.3764V35.6552C1.45679 36.8083 2.13812 37.8146 3.12345 38.2863C3.02911 37.9928 2.9767 37.6888 2.9767 37.3638V6.085C2.9767 4.48123 4.28697 3.17096 5.89074 3.17096L5.88027 3.16046Z"
                  fill="#FFCE61"/>
            <path fill-rule="evenodd" clip-rule="evenodd"
                  d="M5.47155 2.9716C6.16337 2.9716 6.79231 3.25462 7.24304 3.70535C7.69377 4.15609 7.97679 4.78502 7.97679 5.47684C7.97679 6.16867 7.69377 6.7976 7.24304 7.24833C6.79231 7.69907 6.16337 7.98208 5.47155 7.98208C4.77972 7.98208 4.15079 7.69907 3.70006 7.24833C3.24932 6.7976 2.96631 6.16867 2.96631 5.47684C2.96631 4.78502 3.24932 4.15609 3.70006 3.70535C4.15079 3.25462 4.77972 2.9716 5.47155 2.9716ZM6.72941 4.21899C6.40446 3.89404 5.96421 3.69488 5.47155 3.69488C4.97889 3.69488 4.53864 3.89404 4.21369 4.21899C3.88874 4.54393 3.68958 4.98418 3.68958 5.47684C3.68958 5.96951 3.88874 6.40975 4.21369 6.7347C4.53864 7.05965 4.97889 7.25881 5.47155 7.25881C5.96421 7.25881 6.40446 7.05965 6.72941 6.7347C7.05435 6.40975 7.25352 5.96951 7.25352 5.47684C7.25352 4.98418 7.05435 4.54393 6.72941 4.21899Z"
                  fill="#1A1A1A"/>
            <path fill-rule="evenodd" clip-rule="evenodd"
                  d="M5.47234 3.32808C6.65683 3.32808 7.6107 4.28197 7.6107 5.46645C7.6107 6.65094 6.65683 7.60481 5.47234 7.60481C4.28786 7.60481 3.33398 6.65094 3.33398 5.46645C3.33398 4.28197 4.28786 3.32808 5.47234 3.32808Z"
                  fill="#4D4D4D"/>
            <path d="M6.93216 4.52648L3.77393 5.29112L4.03785 6.38121L7.19608 5.61657L6.93216 4.52648Z" fill="#1A1A1A"/>
            <path fill-rule="evenodd" clip-rule="evenodd"
                  d="M5.70265 8.55873C4.62299 8.55873 3.69007 7.90882 3.29175 6.97591C3.75296 7.73063 4.58106 8.22329 5.53493 8.22329C6.99196 8.22329 8.16597 7.04929 8.16597 5.59227C8.16597 5.22539 8.09259 4.869 7.94584 4.54405C8.19741 4.94237 8.33367 5.42455 8.33367 5.92769C8.33367 7.38471 7.15968 8.55873 5.70265 8.55873Z"
                  fill="#FFCE61"/>
            <path fill-rule="evenodd" clip-rule="evenodd"
                  d="M34.434 2.9716C35.1258 2.9716 35.7547 3.25462 36.2054 3.70535C36.6562 4.15609 36.9392 4.78502 36.9392 5.47684C36.9392 6.16867 36.6562 6.7976 36.2054 7.24833C35.7547 7.69907 35.1258 7.98208 34.434 7.98208C33.7421 7.98208 33.1132 7.69907 32.6625 7.24833C32.2117 6.7976 31.9287 6.16867 31.9287 5.47684C31.9287 4.78502 32.2117 4.15609 32.6625 3.70535C33.1132 3.25462 33.7421 2.9716 34.434 2.9716ZM35.6813 4.21899C35.3564 3.89404 34.9161 3.69488 34.4235 3.69488C33.9308 3.69488 33.4905 3.89404 33.1656 4.21899C32.8407 4.54393 32.6415 4.98418 32.6415 5.47684C32.6415 5.96951 32.8407 6.40975 33.1656 6.7347C33.4905 7.05965 33.9308 7.25881 34.4235 7.25881C34.9161 7.25881 35.3564 7.05965 35.6813 6.7347C36.0063 6.40975 36.2054 5.96951 36.2054 5.47684C36.2054 4.98418 36.0063 4.54393 35.6813 4.21899Z"
                  fill="#1A1A1A"/>
            <path fill-rule="evenodd" clip-rule="evenodd"
                  d="M34.4235 3.32808C35.608 3.32808 36.5619 4.28197 36.5619 5.46645C36.5619 6.65094 35.608 7.60481 34.4235 7.60481C33.239 7.60481 32.2852 6.65094 32.2852 5.46645C32.2852 4.28197 33.239 3.32808 34.4235 3.32808Z"
                  fill="#4D4D4D"/>
            <path d="M33.048 4.39091L32.6912 5.45424L35.7718 6.488L36.1286 5.42467L33.048 4.39091Z" fill="#1A1A1A"/>
            <path fill-rule="evenodd" clip-rule="evenodd"
                  d="M34.6538 8.55873C33.5742 8.55873 32.6412 7.90882 32.2429 6.97591C32.7041 7.73063 33.5322 8.22329 34.4861 8.22329C35.9431 8.22329 37.1171 7.04929 37.1171 5.59227C37.1171 5.22539 37.0438 4.869 36.897 4.54405C37.1486 4.94237 37.2849 5.42455 37.2849 5.92769C37.2849 7.38471 36.1108 8.55873 34.6538 8.55873Z"
                  fill="#FFCE61"/>
            <path fill-rule="evenodd" clip-rule="evenodd"
                  d="M6.36274 32.8145L6.3208 32.7935C5.91199 32.5734 5.44031 32.5315 5.02102 32.6468C4.58077 32.7621 4.18243 33.0451 3.94134 33.4749C3.70025 33.8942 3.64784 34.3868 3.76314 34.8271C3.87845 35.2673 4.16147 35.6552 4.58075 35.8963H4.59125C5.01053 36.1479 5.49271 36.2003 5.93296 36.0745C6.37322 35.9592 6.76105 35.6761 7.01263 35.2464L7.04407 35.2045C7.2642 34.7956 7.30613 34.3239 7.19082 33.9047C7.07552 33.4644 6.79251 33.0766 6.36274 32.825V32.8145ZM6.7296 32.1856C7.32709 32.5315 7.72541 33.087 7.89313 33.7055C8.06084 34.3239 7.98747 34.9948 7.65204 35.5923C7.30613 36.2003 6.75058 36.5986 6.13213 36.7663C5.51368 36.934 4.83234 36.8606 4.23486 36.5147C3.63737 36.1688 3.23905 35.6133 3.07133 34.9948C2.90362 34.3764 2.97698 33.695 3.3229 33.0975C3.66881 32.5001 4.22436 32.0913 4.84281 31.934C5.46126 31.7663 6.14261 31.8397 6.7401 32.1856H6.7296Z"
                  fill="#1A1A1A"/>
            <path fill-rule="evenodd" clip-rule="evenodd"
                  d="M6.54074 32.5C7.55752 33.087 7.91391 34.3973 7.32691 35.4246C6.73991 36.4413 5.42963 36.7977 4.41286 36.2107C3.38561 35.6237 3.0397 34.3134 3.6267 33.2862C4.2137 32.2694 5.52397 31.913 6.55122 32.5H6.54074Z"
                  fill="#4D4D4D"/>
            <path d="M6.0135 32.7157L4.03174 35.2909L4.92061 35.975L6.90237 33.3997L6.0135 32.7157Z" fill="#1A1A1A"/>
            <path fill-rule="evenodd" clip-rule="evenodd"
                  d="M5.70168 37.4372C4.62201 37.4372 3.68909 36.7873 3.29077 35.8544C3.75199 36.6091 4.58008 37.1017 5.53396 37.1017C6.99098 37.1017 8.16499 35.9277 8.16499 34.4707C8.16499 34.1038 8.09161 33.7474 7.94486 33.4225C8.19643 33.8208 8.3327 34.2925 8.3327 34.8061C8.3327 36.2632 7.1587 37.4372 5.70168 37.4372Z"
                  fill="#FFCE61"/>
            <path fill-rule="evenodd" clip-rule="evenodd"
                  d="M34.434 31.8501C35.1258 31.8501 35.7547 32.1331 36.2054 32.5838C36.6562 33.0346 36.9392 33.6635 36.9392 34.3553C36.9392 35.0471 36.6562 35.6761 36.2054 36.1268C35.7547 36.5775 35.1258 36.8606 34.434 36.8606C33.7421 36.8606 33.1132 36.5775 32.6625 36.1268C32.2117 35.6761 31.9287 35.0471 31.9287 34.3553C31.9287 33.6635 32.2117 33.0346 32.6625 32.5838C33.1132 32.1331 33.7421 31.8501 34.434 31.8501ZM35.6813 33.0975C35.3564 32.7725 34.9161 32.5734 34.4235 32.5734C33.9308 32.5734 33.4905 32.7725 33.1656 33.0975C32.8407 33.4224 32.6415 33.8627 32.6415 34.3553C32.6415 34.848 32.8407 35.2882 33.1656 35.6132C33.4905 35.9381 33.9308 36.1373 34.4235 36.1373C34.9161 36.1373 35.3564 35.9381 35.6813 35.6132C36.0063 35.2882 36.2054 34.848 36.2054 34.3553C36.2054 33.8627 36.0063 33.4224 35.6813 33.0975Z"
                  fill="#1A1A1A"/>
            <path fill-rule="evenodd" clip-rule="evenodd"
                  d="M34.4235 32.2169C35.608 32.2169 36.5619 33.1708 36.5619 34.3553C36.5619 35.5398 35.608 36.4936 34.4235 36.4936C33.239 36.4936 32.2852 35.5398 32.2852 34.3553C32.2852 33.1708 33.239 32.2169 34.4235 32.2169Z"
                  fill="#4D4D4D"/>
            <path d="M33.4926 32.9378L32.8088 33.8269L35.3848 35.8077L36.0685 34.9186L33.4926 32.9378Z" fill="#1A1A1A"/>
            <path fill-rule="evenodd" clip-rule="evenodd"
                  d="M34.6538 37.4372C33.5742 37.4372 32.6412 36.7873 32.2429 35.8544C32.7041 36.6091 33.5322 37.1017 34.4861 37.1017C35.9431 37.1017 37.1171 35.9277 37.1171 34.4707C37.1171 34.1038 37.0438 33.7475 36.897 33.4225C37.1486 33.8208 37.2849 34.2925 37.2849 34.8061C37.2849 36.2632 36.1108 37.4372 34.6538 37.4372Z"
                  fill="#FFCE61"/>
            <defs>
                <clipPath id="clip0_132_3645">
                    <rect width="20" height="20" fill="white" transform="translate(10 10)"/>
                </clipPath>
            </defs>
        </svg>
    )
}