import {authHandler} from "./requests/user-info";
import {retrieveLaunchParams} from "@tma.js/sdk-react";
import {useUserStore} from "../store/user-store";

export const API_KEY = process.env.REACT_APP_API_KEY;
export const API_URL = process.env.REACT_APP_API_URL || "https://bloxx-backend.for-game.ru";

interface ApiUrls {
    auth: string;
    dashboard: string;
    promo: string;
}


export interface ApiError {
    code: number;
    message: string;
}

interface FetchOptions<T> extends RequestInit {
    data?: T;
    headers?: HeadersInit;
}


interface PostParams<T> {
    input: RequestInfo;
    data?: T;
    init?: FetchOptions<T>;
    serviceName?: keyof ApiUrls;
}


export async function post<T>(
    {input, data, init}: PostParams<T>
): Promise<Response> {
    const {headers, ...restInit} = init ?? {};

    return fetcher(input, {
        method: "POST",
        headers: {
            ...(headers || {}),
        },
        ...restInit,
        body: (data instanceof FormData) ? data : JSON.stringify(data),
    });
}


export const fetcher = async <T>(
    input: RequestInfo,
    options?: FetchOptions<T>,
): Promise<Response> => {

    const apiURL = process.env.REACT_APP_API_URL || "https://bloxx-backend.for-game.ru";

    let requestOptions = {
        ...options,
        headers: {
            "Content-Type": "application/json",
            "x-api-key": useUserStore.getState().user?.access_token || '',
            ...options?.headers,
        },
    };

    if (options?.data) {
        requestOptions.body = JSON.stringify(options.data);
    }

    try {
        const response = await fetch(`${apiURL}${input}`, requestOptions);
        if (response.status === 401 || response.status === 422) {

            localStorage.removeItem('token');

            const resp = await authHandler();
            useUserStore.getState().setUser(() => resp);
            return fetcher(input, options);


        } else if (response.status >= 400) {
            const result = (await response.json()) as ApiError;

            throw new Error(result.message);
        }
        return response;
    } catch (error) {
        throw error;
    }
};

interface FetchSWR<T> {
    input: RequestInfo;
    init?: FetchOptions<T>;
    serviceName?: keyof ApiUrls;
}

export async function fetchSWR<T>({
                                      input,
                                      init,
                                  }: FetchSWR<T>): Promise<T> {
    const requestOptions = {
        ...init,
        headers: {
            ...init?.headers,
            "Content-Type": "application/json"
        },
    };
    const response = await fetcher(input);
    return await response.json();
}


