import styled from "styled-components";
import {BottomNav} from "../bottom-nav";
import {Info} from "../info";
import {BorderedTitle} from "../bordered-title";
import {useNavigate, useSearchParams} from "react-router-dom";
import {Area, buildHandler, claimHandler, onboardingHandler, useAreas} from "../../api/requests/area";
import {useAreasStore} from "../../store/areas-store";
import {useUserStore} from "../../store/user-store";
import {initInvoice, postEvent} from '@telegram-apps/sdk';

import {use} from "matter-js";
import {ColorButton} from "../color-button";
import React, {useEffect, useMemo} from "react";
import {ActionModal} from "../action-modal";
import {TowerInfo} from "../tower-info";
import {CloseLevelIcon} from "../icons/close-level";
import {usePositionStore} from "../../store/position-store";
import {StarsIcon} from "../icons/stars";
import {useInvoiceStore} from "../../store/star-store";
import {useBuyingInvoiceLink} from "../../api/requests/user-info";
import {Balance} from "./balance";
import {OnboardingModal} from "./onboarding";

export const MainView = () => {

    const {areas, setAreas} = useAreasStore();
    const {user, setUser} = useUserStore()
    const {mutate} = useAreas()
    const nav = useNavigate()
    const [activeArea, setActiveArea] = React.useState<Area | null>(null)
    const [isBuildModalOpen, setIsBuildModalOpen] = React.useState(false)
    const [isInfoModalOpen, setIsInfoModalOpen] = React.useState(false)
    const {setPosition} = usePositionStore()
    const {setPrice} = useInvoiceStore();

    const [search] = useSearchParams()

    const {price} = useInvoiceStore()
    const {mutate: getLink} = useBuyingInvoiceLink()

    const rentPerHourOffAllAreas = useMemo(() => {
        return areas?.reduce((acc, area) => {
            return acc + area.rent_per_hour_amount
        }, 0) || 0
    }, [areas])

    const onClaim = async (posX: number, posY: number) => {
        const resp = await claimHandler({
            pos_x: posX,
            pos_y: posY
        })
        setAreas((prevAreas) => {
                return prevAreas?.map((prevArea) => {
                        if (prevArea.pos_x === posX && prevArea.pos_y === posY) {
                            return {
                                ...prevArea,
                                available_to_claim: 0
                            }
                        }
                        return prevArea
                    }
                )
            }
        )

        setUser((prevUser) => {
            return {
                ...prevUser,
                balance: prevUser?.balance + resp.claimed
            }
        })

    }


    useEffect(() => {
        if (search.get('onboarding') === 'true') {
            setIsInfoModalOpen(true)
            setActiveArea({
                "pos_x": 1,
                "pos_y": 0,
                "building_level": 1,
                //@ts-ignore
                "base_rent_amount": 340,
                "rent_claim_at": "2024-10-10T17:23:09.344996Z",
                "has_elevator": false,
                "elevator_setup_price": 2000,
                "has_conditioner": false,
                "conditioner_setup_price": 2000,
                "has_parking": false,
                "parking_setup_price": 3000,
                "has_pool": false,
                "pool_setup_price": 4000,
                "has_terrace": false,
                "terrace_setup_price": 5000,
                "has_shop": false,
                "shop_setup_price": 5000,
                "has_laundry": false,
                "laundry_setup_price": 5000,
                "rent_per_hour_amount": 340,
                "available_to_claim": 200000,
                "max_claim_amount": 1360,
                "elapsed_rent_seconds": 19,
                "max_rent_seconds": 14400
            })
        }
    }, [search?.get('onboarding')])


    return (
        <Container>
            <Col>
                <Title>
                    Town balance
                </Title>
                <Balance title={user?.balance?.toString() || '0'}/>
                <Row>
                    <Info title={'Blox level'} value={user?.block_level || 0}/>
                    <Info title={'Rent per hour'} value={rentPerHourOffAllAreas}/>
                </Row>
                <Matrix>
                    {areas?.map((area, index) => {
                        if (area.building_level > 0) {
                            return (
                                <Relative>
                                    {area?.available_to_claim > 0 && (
                                        <img
                                            onClick={async () => {
                                                await onClaim(area.pos_x, area.pos_y)
                                            }}

                                            style={{
                                                position: 'absolute',
                                                top: '50%',
                                                left: '50%',
                                                transform: 'translate(-50%, -50%)',
                                                width: 30,
                                                height: 30,
                                                objectFit: 'contain',
                                            }}
                                            src={'/images/dollar.png'}
                                        />

                                    )}
                                    <OpenLevelIcon
                                        src={`/images/roof/${area.building_level}.png`}
                                        onClick={async () => {
                                            if (area?.available_to_claim > 0) {
                                                await onClaim(area.pos_x, area.pos_y)

                                            } else {
                                                setActiveArea(area)
                                                setIsInfoModalOpen(true)
                                            }
                                        }}
                                        key={index}
                                    />
                                </Relative>
                            );
                        }

                        return <CloseLevelIcon
                            active={(activeArea?.pos_x === area.pos_x && activeArea?.pos_y === area.pos_y)}
                            onClick={() => {
                                setActiveArea(area)
                                setIsBuildModalOpen(true)
                            }}
                            key={index}
                        />
                    })}
                    <Relative

                        onClick={async () => {
                            getLink().then((res) => {
                                if (!res?.invoice_link) return
                                //@ts-ignore
                                window.Telegram.WebApp.openInvoice(res?.invoice_link).then(() => {
                                    mutate().then((value) => {
                                        if (!value) return
                                        setAreas(() => value)
                                        setPrice((prev) => (prev || 0) * 2)
                                    })
                                })
                            })
                        }}
                    >
                        <StarsIcon/>
                        <Price>
                            {price}
                        </Price>
                    </Relative>
                </Matrix>

                <ButtonRow>
                </ButtonRow>
                <BottomNav/>

            </Col>

            <ActionModal
                onClick={() => {
                    setPosition({pos_x: activeArea?.pos_x || 0, pos_y: activeArea?.pos_y || 0})
                    nav(`/game`)
                }}
                color={'linear-gradient(0deg, #DB9900 0%, #FFC71E 99.86%)'}
                borderColor={'#FFB500'}
                text={'Build'}
                title={'Build new tower'}
                open={isBuildModalOpen}
                onClose={() => setIsBuildModalOpen(false)}
            />
            <TowerInfo
                onCloseOnboarding={async () => {
                    await onboardingHandler()
                    setUser((prevUser) => {
                        return {
                            ...prevUser,
                            is_onboarding_completed: true
                        }
                    })
                    setIsInfoModalOpen(false)
                    setActiveArea(null)
                }}
                area={activeArea}
                open={isInfoModalOpen}
                onClose={() => {
                    setActiveArea(null)
                    setIsInfoModalOpen(false)
                }}/>
            <OnboardingModal text={'Here you can build\n' +
                'towers, collect rent and\n' +
                'buy additional plots'} next={'/invite'}
            />

        </Container>
    )
}

const Price = styled.span`
    font-size: 12px;
    color: #333333;;
    position: absolute;
    bottom: 15px;
    left: 50%;
    transform: translateX(-50%);
`


const OpenLevelIcon = styled.img`
    width: 75px;
    height: 75px;
`

const Title = styled.div`
    font-family: Roboto;
    // uppercase
    //font-style: uppercase;
    text-transform: uppercase;
    color: white;
    font-size: 12px;
    font-weight: 500;
    line-height: 14px;
    letter-spacing: 2px;
    text-align: left;
`

const Container = styled.div`
    display: flex;
    flex-direction: column;
    height: 100vh;
    scrollbar-width: none;
    width: 100%;
    background-image: url('/images/bg.png');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    justify-content: space-between;
    align-items: center;
    position: relative;
    box-sizing: border-box;
`


const Col = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    max-width: 320px;
    padding: 10% 0 0 0;
    gap: 15px;
    box-sizing: border-box;
`

const Row = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    box-sizing: border-box;
    gap: 10px;
`

const Matrix = styled.div`
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(3, 1fr);
    gap: 7px;
    grid-template-areas:
          "Column Column Column Column"
          "Column Column Column Column"
          "Column Column Column Column";

`

const ButtonRow = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    gap: 10px;
    box-sizing: border-box;
`

const Relative = styled.div`
    position: relative;
`