import {create} from 'zustand';


interface Position {
    pos_x: number;
    pos_y: number;
}


type PositionState = {
    position: Position | null;
    setPosition: (position: Position) => void;
};


export const usePositionStore = create<PositionState>((set) => ({
    position: null,
    setPosition: (position) => set({position}),
}));
