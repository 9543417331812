import styled from "styled-components";
import {InviteIcon} from "./icons/menu/invite";
import {IconProps} from "../model";
import {EarnIcon} from "./icons/menu/earn";
import {BoostIcon} from "./icons/menu/boost";
import {Link} from "react-router-dom";

export const BottomNav = ({isAbsolute}: { isAbsolute?: boolean }) => {
    return (
        <Fixed>
            <Container>
                <BorderTop/>
                <NavItem
                    href={"/invite"}
                    icon={<InviteIcon/>} label={"Invite"}/>
                <Divider/>
                <NavItem
                    href={"/earn"}
                    icon={<EarnIcon/>} label={"Earn"}/>
                <Divider/>
                <NavItem
                    href={"/boost"}
                    icon={<BoostIcon/>} label={"Boost"}/>
                <BorderBottom/>
            </Container>
        </Fixed>
    )
}


const Fixed = styled.div`
  position: fixed;
  bottom: 24px;
  transform: translateX(-50%);
  width: 100%;
  z-index: 1000;
  left: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
`

const Container = styled.div`
  display: flex;
  width: 320px;
  height: 80px;
  border-radius: 12px;
  gap: 5px;
  align-items: center;
  border: 2px solid rgba(241, 172, 0, 1);
  background: linear-gradient(180deg, #0D596F 13.64%, #0F7088 50%, #0D596F 86.14%);
  position: relative;
  overflow: hidden;
  justify-content: space-between;
`


const LineSVG = (props: IconProps) => {
    return (
        <svg
            {...props}
            xmlns="http://www.w3.org/2000/svg" width="318" height="5" viewBox="0 0 318 5" fill="none">
            <path fill-rule="evenodd" clip-rule="evenodd"
                  d="M0 5H312.418C315.489 5 312.418 5 318 5V0H7.15256e-06V3.65183C7.15256e-06 4.39354 0 4.25 0 5Z"
                  fill="#F1AC00"/>
            <mask id="mask0_61_1253"
                  style={{
                      maskType: "luminance"
                  }}
                  maskUnits="userSpaceOnUse" x="0" y="0" width="318" height="5">
                <path fill-rule="evenodd" clip-rule="evenodd"
                      d="M0 5H312.418C315.489 5 312.418 5 318 5V0H7.15256e-06V3.65183C7.15256e-06 4.39354 0 4.25 0 5Z"
                      fill="white"/>
            </mask>
            <g mask="url(#mask0_61_1253)">
                <path fill-rule="evenodd" clip-rule="evenodd"
                      d="M2.05938 7.70993H14.7411L0.325136 -19.2128H-12.3566L2.05938 7.70993Z" fill="#333333"/>
                <path fill-rule="evenodd" clip-rule="evenodd"
                      d="M24.157 7.70993H36.8387L22.4228 -19.2128H9.74109L24.157 7.70993Z" fill="#333333"/>
                <path fill-rule="evenodd" clip-rule="evenodd"
                      d="M46.2547 7.70993H58.9183L44.5024 -19.2128H31.8387L46.2547 7.70993Z" fill="#333333"/>
                <path fill-rule="evenodd" clip-rule="evenodd"
                      d="M68.3343 7.70993H80.9979L66.582 -19.2128H53.9183L68.3343 7.70993Z" fill="#333333"/>
                <path fill-rule="evenodd" clip-rule="evenodd"
                      d="M90.4139 7.70993H103.096L88.6796 -19.2128H75.9979L90.4139 7.70993Z" fill="#333333"/>
                <path fill-rule="evenodd" clip-rule="evenodd"
                      d="M112.512 7.70993H125.193L110.777 -19.2128H98.0956L112.512 7.70993Z" fill="#333333"/>
                <path fill-rule="evenodd" clip-rule="evenodd"
                      d="M134.609 7.70993H147.273L132.857 -19.2128H120.193L134.609 7.70993Z" fill="#333333"/>
                <path fill-rule="evenodd" clip-rule="evenodd"
                      d="M156.689 7.70993H169.352L154.936 -19.2128H142.273L156.689 7.70993Z" fill="#333333"/>
                <path fill-rule="evenodd" clip-rule="evenodd"
                      d="M178.768 7.70993H191.45L177.034 -19.2128H164.352L178.768 7.70993Z" fill="#333333"/>
                <path fill-rule="evenodd" clip-rule="evenodd"
                      d="M200.866 7.70993H213.548L199.132 -19.2128H186.45L200.866 7.70993Z" fill="#333333"/>
                <path fill-rule="evenodd" clip-rule="evenodd"
                      d="M222.964 7.70993H235.627L221.211 -19.2128H208.548L222.964 7.70993Z" fill="#333333"/>
                <path fill-rule="evenodd" clip-rule="evenodd"
                      d="M245.043 7.70993H257.707L243.291 -19.2128H230.627L245.043 7.70993Z" fill="#333333"/>
                <path fill-rule="evenodd" clip-rule="evenodd"
                      d="M267.123 7.70993H279.805L265.389 -19.2128H252.707L267.123 7.70993Z" fill="#333333"/>
                <path fill-rule="evenodd" clip-rule="evenodd"
                      d="M289.221 7.70993H301.902L287.486 -19.2128H274.805L289.221 7.70993Z" fill="#333333"/>
                <path fill-rule="evenodd" clip-rule="evenodd"
                      d="M311.318 7.70993H323.982L309.566 -19.2128H296.902L311.318 7.70993Z" fill="#333333"/>
            </g>
        </svg>
    )
}

const BorderTop = styled(LineSVG)`
  position: absolute;
  top: 0;
  left: 0;
`

const BorderBottom = styled(LineSVG)`
  position: absolute;
  bottom: 0;
  left: 0;
`


interface NavItemProps {
    icon: React.ReactNode
    label: string
    href: string
}


const NavItem = (props: NavItemProps) => {
    return (
        <Link
            style={{textDecoration: 'none'}}
            to={props.href}>
            <Column>
                {props.icon}
                <NavText>{props.label}</NavText>
            </Column>
        </Link>
    )
}


const NavText = styled.span`
    font-size: 14px;
    font-weight: 500;
    line-height: 16px;
    letter-spacing: -0.07999999821186066px;
    text-align: center;
    color: white;
    text-decoration: none;
`

const Divider = (props: IconProps) => {
    return (
        <svg
            {...props}
            xmlns="http://www.w3.org/2000/svg" width="2" height="16" viewBox="0 0 2 16" fill="none">
            <line x1="0.916664" y1="0.5" x2="0.916663" y2="15.5" stroke="#243035" stroke-width="0.5"/>
        </svg>
    )
}

const Column = styled.div`
  display: flex;
  width: 97px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 6px;
`

