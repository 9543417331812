import {create} from 'zustand';
import { Link} from "../api/requests/channels";

type LinkState = {
    links: Link[] | null;
    setLinks: (Link: Link[]) => void;
};


export const useLinksStore = create<LinkState>((set) => ({
    links: null,
    setLinks: (links) => set({links}),
}));