export const CheckedIcon = (props: {style?: React.CSSProperties}) => {
    return (
        <svg
            {...props}
            xmlns="http://www.w3.org/2000/svg" width="20" height="22" viewBox="0 0 20 22" fill="none">
            <g clip-path="url(#clip0_336_15186)">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M1.47088 3.2832H18.5356C19.3427 3.2832 20.0065 3.94632 20.0065 4.75247V20.5308C20.0065 21.337 19.3427 22.0001 18.5356 22.0001H1.47088C0.663846 22.0001 0 21.337 0 20.5308V4.75247C0 3.93982 0.663846 3.2832 1.47088 3.2832Z" fill="#C24606"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M2.18012 0H17.8196C18.4183 0 18.965 0.247045 19.3555 0.637116C19.7525 1.03369 19.9933 1.57979 19.9933 2.1779V17.8002C19.9933 18.3983 19.746 18.9444 19.3555 19.3345C18.9585 19.7311 18.4118 19.9781 17.8196 19.9781H2.18012C1.58136 19.9781 1.03466 19.7311 0.644161 19.341C0.247155 18.9444 0.00634766 18.3983 0.00634766 17.8002V2.1779C0.00634766 1.57979 0.253663 1.03369 0.644161 0.643617C1.04117 0.247045 1.58136 0 2.18012 0ZM17.8196 1.45626H2.18012C1.97836 1.45626 1.79613 1.53428 1.66596 1.6643C1.5358 1.80083 1.4577 1.97636 1.4577 2.1779V17.8002C1.4577 18.0018 1.5358 18.1838 1.66596 18.3138C1.79613 18.4439 1.97836 18.5284 2.18012 18.5284H17.8196C18.0213 18.5284 18.2036 18.4504 18.3337 18.3203C18.4639 18.1838 18.542 18.0018 18.542 17.8067V2.1779C18.542 1.97636 18.4639 1.79433 18.3337 1.6643C18.2036 1.53428 18.0213 1.44976 17.8196 1.44976V1.45626Z" fill="#E87D18"/>
                <path d="M2.18051 0.728181H17.82C18.6205 0.728181 19.2713 1.3783 19.2713 2.17794V17.8003C19.2713 18.5999 18.6205 19.2565 17.8134 19.2565H2.18051C1.37999 19.2565 0.722656 18.6064 0.722656 17.8003V2.17794C0.722656 1.3783 1.37349 0.72168 2.18051 0.72168V0.728181Z" fill="#FFB500"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M2.18035 0.728181H17.8198C18.6203 0.728181 19.2711 1.3848 19.2711 2.18445V9.99236H0.729004V2.17794C0.729004 1.3783 1.38634 0.72168 2.18686 0.72168L2.18035 0.728181Z" fill="#FFC030"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M2.94183 1.57968H18.5813C18.8025 1.57968 19.0108 1.63169 19.1996 1.72271C19.0043 1.1506 18.4576 0.728027 17.8198 0.728027H2.18035C1.37983 0.728027 0.729004 1.38465 0.729004 2.17779V17.8001C0.729004 18.3787 1.06744 18.8793 1.56207 19.1134C1.51 18.9703 1.48397 18.8143 1.48397 18.6518V3.02945C1.48397 2.2298 2.1413 1.57968 2.93532 1.57968H2.94183Z" fill="#FFCE61"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M2.73983 1.48218C3.08477 1.48218 3.39717 1.6252 3.62496 1.84624C3.85275 2.07379 3.98942 2.38584 3.98942 2.7304C3.98942 3.07497 3.84624 3.38702 3.62496 3.61457C3.39717 3.84211 3.08477 3.97863 2.73983 3.97863C2.39489 3.97863 2.08249 3.83561 1.8547 3.61457C1.62691 3.38702 1.49023 3.07497 1.49023 2.7304C1.49023 2.38584 1.63342 2.07379 1.8547 1.84624C2.08249 1.6187 2.39489 1.48218 2.73983 1.48218ZM3.36462 2.10629C3.20192 1.94376 2.98063 1.84624 2.73983 1.84624C2.49902 1.84624 2.27123 1.94376 2.11503 2.10629C1.95232 2.26882 1.8547 2.48986 1.8547 2.7304C1.8547 2.97095 1.95232 3.19849 2.11503 3.35452C2.27774 3.51705 2.49902 3.61457 2.73983 3.61457C2.98063 3.61457 3.20843 3.51705 3.36462 3.35452C3.52733 3.19199 3.62496 2.97095 3.62496 2.7304C3.62496 2.48986 3.52733 2.26232 3.36462 2.10629Z" fill="#1A1A1A"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M2.73972 1.66431C3.33198 1.66431 3.80709 2.13889 3.80709 2.7305C3.80709 3.32211 3.32547 3.79669 2.73972 3.79669C2.15398 3.79669 1.67236 3.32211 1.67236 2.7305C1.67236 2.13889 2.15398 1.66431 2.73972 1.66431Z" fill="#4D4D4D"/>
                <path d="M3.45836 2.26996L1.8833 2.65088L2.01501 3.19428L3.59007 2.81336L3.45836 2.26996Z" fill="#1A1A1A"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M2.85068 4.27116C2.31049 4.27116 1.8484 3.9461 1.64014 3.47802C1.87444 3.85509 2.28446 4.10213 2.75956 4.10213C3.48849 4.10213 4.07424 3.51052 4.07424 2.78889C4.07424 2.60036 4.03519 2.42483 3.9636 2.2688C4.08726 2.47034 4.15885 2.70438 4.15885 2.95792C4.15885 3.68606 3.56659 4.27116 2.84417 4.27116H2.85068Z" fill="#FFCE61"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M17.2144 1.48218C17.5594 1.48218 17.8718 1.6252 18.0996 1.84624C18.3274 2.07379 18.464 2.38584 18.464 2.7304C18.464 3.07497 18.3208 3.38702 18.0996 3.61457C17.8718 3.84211 17.5594 3.97863 17.2144 3.97863C16.8695 3.97863 16.5571 3.83561 16.3293 3.61457C16.1015 3.38702 15.9648 3.07497 15.9648 2.7304C15.9648 2.38584 16.108 2.07379 16.3293 1.84624C16.5571 1.6187 16.8695 1.48218 17.2144 1.48218ZM17.8392 2.10629C17.6765 1.94376 17.4552 1.84624 17.2144 1.84624C16.9736 1.84624 16.7458 1.94376 16.5896 2.10629C16.4269 2.26882 16.3293 2.48986 16.3293 2.7304C16.3293 2.97095 16.4269 3.19849 16.5896 3.35452C16.7523 3.51705 16.9736 3.61457 17.2144 3.61457C17.4552 3.61457 17.683 3.51705 17.8392 3.35452C18.0019 3.19199 18.0996 2.97095 18.0996 2.7304C18.0996 2.48986 18.0019 2.26232 17.8392 2.10629Z" fill="#1A1A1A"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M17.2143 1.66431C17.8066 1.66431 18.2817 2.13889 18.2817 2.7305C18.2817 3.32211 17.8001 3.79669 17.2143 3.79669C16.6286 3.79669 16.147 3.32211 16.147 2.7305C16.147 2.13889 16.6286 1.66431 17.2143 1.66431Z" fill="#4D4D4D"/>
                <path d="M16.5296 2.20701L16.3516 2.73706L17.8879 3.25205L18.066 2.722L16.5296 2.20701Z" fill="#1A1A1A"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M17.3316 4.27116C16.7914 4.27116 16.3294 3.9461 16.1211 3.47802C16.3554 3.85509 16.7654 4.10213 17.2405 4.10213C17.9694 4.10213 18.5552 3.51052 18.5552 2.78889C18.5552 2.60036 18.5161 2.42483 18.4446 2.2688C18.5682 2.47034 18.6398 2.70438 18.6398 2.95792C18.6398 3.68606 18.0476 4.27116 17.3251 4.27116H17.3316Z" fill="#FFCE61"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M3.31943 16.0513L3.36499 16.0773C3.66437 16.2463 3.86613 16.5259 3.94423 16.838C4.02233 17.1305 3.99629 17.4556 3.8466 17.7416V17.7741L3.80755 17.8131C3.63183 18.0992 3.36499 18.2942 3.0591 18.3722C2.75321 18.4567 2.40827 18.4177 2.10889 18.2487L2.08285 18.2357C1.79649 18.0602 1.60124 17.7936 1.52314 17.4881C1.43853 17.1825 1.47758 16.838 1.6468 16.5389L1.65981 16.5129C1.83554 16.2268 2.10238 16.0318 2.40827 15.9538C2.70114 15.8758 3.02005 15.9018 3.31292 16.0513H3.31943ZM3.59278 16.929C3.5342 16.7079 3.39102 16.5129 3.18276 16.3894C2.97449 16.2658 2.72718 16.2398 2.5124 16.2984C2.29112 16.3569 2.09587 16.4999 1.97221 16.7079L1.9592 16.7274C1.84856 16.9355 1.82903 17.1695 1.8811 17.3776C1.93967 17.5986 2.08285 17.7936 2.29112 17.9172L2.31064 17.9302C2.51891 18.0407 2.75321 18.0602 2.96147 18.0082C3.17625 17.9497 3.3715 17.8131 3.49515 17.6051L3.50817 17.5791C3.62532 17.3711 3.64485 17.137 3.58627 16.9225L3.59278 16.929Z" fill="#1A1A1A"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M3.2739 16.2334C3.78155 16.526 3.95727 17.1826 3.6644 17.6897C3.37153 18.1968 2.71419 18.3723 2.20654 18.0797C1.69238 17.7872 1.52317 17.1306 1.81604 16.6235C2.10892 16.1164 2.76625 15.9409 3.2739 16.2334Z" fill="#4D4D4D"/>
                <path d="M3.01053 16.3486L2.02197 17.6313L2.46549 17.9724L3.45404 16.6897L3.01053 16.3486Z" fill="#1A1A1A"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M2.85068 18.6972C2.31049 18.6972 1.8484 18.3721 1.64014 17.904C1.87444 18.2811 2.28446 18.5282 2.75956 18.5282C3.48849 18.5282 4.07424 17.9365 4.07424 17.2149C4.07424 17.0264 4.03519 16.8509 3.9636 16.6948C4.08726 16.8964 4.15885 17.1304 4.15885 17.3839C4.15885 18.1121 3.56659 18.6972 2.84417 18.6972H2.85068Z" fill="#FFCE61"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M17.2144 15.9084C17.5594 15.9084 17.8718 16.0515 18.0996 16.2725C18.3274 16.5001 18.464 16.8121 18.464 17.1567C18.464 17.5012 18.3208 17.8133 18.0996 18.0408C17.8718 18.2684 17.5594 18.4049 17.2144 18.4049C16.8695 18.4049 16.5571 18.2619 16.3293 18.0408C16.1015 17.8133 15.9648 17.5012 15.9648 17.1567C15.9648 16.8121 16.108 16.5001 16.3293 16.2725C16.5571 16.045 16.8695 15.9084 17.2144 15.9084ZM17.8392 16.5326C17.6765 16.37 17.4552 16.2725 17.2144 16.2725C16.9736 16.2725 16.7458 16.37 16.5896 16.5326C16.4269 16.6951 16.3293 16.9161 16.3293 17.1567C16.3293 17.3972 16.4269 17.6248 16.5896 17.7808C16.7523 17.9433 16.9736 18.0408 17.2144 18.0408C17.4552 18.0408 17.683 17.9433 17.8392 17.7808C18.0019 17.6183 18.0996 17.3972 18.0996 17.1567C18.0996 16.9161 18.0019 16.6886 17.8392 16.5326Z" fill="#1A1A1A"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M17.2143 16.0906C17.8066 16.0906 18.2817 16.5717 18.2817 17.1568C18.2817 17.7419 17.8001 18.223 17.2143 18.223C16.6286 18.223 16.147 17.7419 16.147 17.1568C16.147 16.5717 16.6286 16.0906 17.2143 16.0906Z" fill="#4D4D4D"/>
                <path d="M16.7357 16.4396L16.3945 16.8828L17.6843 17.8736L18.0255 17.4304L16.7357 16.4396Z" fill="#1A1A1A"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M17.3316 18.6974C16.7914 18.6974 16.3294 18.3724 16.1211 17.9043C16.3554 18.2814 16.7654 18.5284 17.2405 18.5284C17.9694 18.5284 18.5552 17.9368 18.5552 17.2152C18.5552 17.0266 18.5161 16.8511 18.4446 16.6951C18.5682 16.8966 18.6398 17.1306 18.6398 17.3842C18.6398 18.1123 18.0476 18.6974 17.3251 18.6974H17.3316Z" fill="#FFCE61"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M15.1318 4.90201L16.9932 6.87187L17.2535 7.15142L17.4357 7.34646L17.234 7.52849L16.9476 7.78854L9.0075 14.9788L8.96194 15.0244L8.7732 15.2064L8.49985 15.4664L8.30461 15.6485L8.12237 15.4534L7.86204 15.1804L2.99383 10.0249L2.7335 9.7519L2.55127 9.55686L2.74652 9.37483L3.01987 9.11478L4.99188 7.25544L5.26523 6.99539L5.46048 6.81336L5.64271 7.0084L5.90304 7.28145L8.51287 10.0444L14.2337 4.86301L14.507 4.61596L14.7023 4.44043L14.878 4.62896L15.1318 4.89551V4.90201ZM15.6134 7.27495L14.6307 6.23476L8.90988 11.4162L8.63653 11.6632L8.44128 11.8388L8.26555 11.6502L8.01173 11.3837L5.41492 8.63369L4.37359 9.61537L8.36969 13.8476L15.6134 7.28795V7.27495Z" fill="#E87D18"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M14.663 5.33765L16.5244 7.3075L8.5257 14.5498L8.33696 14.7319L3.46875 9.57642L5.44076 7.71708L8.48014 10.9352L14.663 5.33765Z" fill="url(#paint0_linear_336_15186)"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M14.1749 10.2981C12.2485 11.9169 10.1268 13.9193 8.30445 15.6356L4.06104 11.1432L8.30445 16.1492L14.1749 10.2981Z" fill="#FFCE61"/>
            </g>
            <defs>
                <linearGradient id="paint0_linear_336_15186" x1="9.99657" y1="15.3235" x2="9.99657" y2="6.30632" gradientUnits="userSpaceOnUse">
                    <stop/>
                    <stop offset="0.14" stop-color="#191A1A"/>
                    <stop offset="0.29" stop-color="#2D2E2E"/>
                    <stop offset="0.46" stop-color="#3B3C3D"/>
                    <stop offset="0.66" stop-color="#434445"/>
                    <stop offset="1" stop-color="#464748"/>
                </linearGradient>
                <clipPath id="clip0_336_15186">
                    <rect width="20" height="22" fill="white"/>
                </clipPath>
            </defs>
        </svg>
    );
}