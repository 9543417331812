import {IconProps} from "../../model";

export const CloseLevelIcon = (props: IconProps & {active?: boolean}) => {
    return(
        <svg

            {...props}
            xmlns="http://www.w3.org/2000/svg" width="76" height="77" viewBox="0 0 76 77" fill="none">
            <g filter="url(#filter0_d_234_15465)">
                <rect x="0.5" width="75" height="75" fill="#FFB500"/>
                <rect x="1" y="0.5" width="74" height="74" stroke={props?.active ? 'red' : "#E87D18"}/>
            </g>
            <g clip-path="url(#clip0_234_15465)">
                <path d="M29.7992 20.9083H45.9689V26.3748L41.8622 39.6504H33.5207L29.7992 25.4634V20.9083Z" fill="#F1AC00"/>
                <path d="M38.4347 45.0499C38.4347 45.0499 40.3247 43.5223 40.2289 41.5401L40.2487 41.1176H42.7593L47.4995 26.0092V20H28.5V26.0092L33.2407 41.1176H35.9037V41.9303C35.9037 41.9303 31.7384 44.7247 31.8983 48.7546C32.0581 52.7841 35.1347 54.7986 38.242 54.994C41.3504 55.1893 46.8938 50.8995 42.4401 46.3172C42.4401 46.3172 42.8561 51.0617 38.947 51.0617C35.0379 51.0617 36.1594 45.7976 38.4347 45.0499ZM41.2724 38.0206L32.2805 30.881L31.4197 28.137L41.9555 36.5026L41.4794 38.0201H41.2724V38.0206ZM38.1298 22.5824H38.2698L44.7178 27.6974L44.2239 29.2714L35.7994 22.5824H38.1298ZM44.9447 24.3633L42.6997 22.5824H44.9447V24.3633ZM31.38 22.5824L31.3775 22.586L43.3417 32.0854L42.8387 33.688L31.0553 24.3326V22.5824H31.38ZM34.5206 38.0206L33.7491 35.562L36.8455 38.0206H34.5206Z" fill="#333333"/>
            </g>
            <defs>
                <filter id="filter0_d_234_15465" x="0.5" y="0" width="75" height="77" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                    <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                    <feOffset dy="2"/>
                    <feComposite in2="hardAlpha" operator="out"/>
                    <feColorMatrix type="matrix" values="0 0 0 0 0.631373 0 0 0 0 0.227451 0 0 0 0 0.0235294 0 0 0 1 0"/>
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_234_15465"/>
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_234_15465" result="shape"/>
                </filter>
                <clipPath id="clip0_234_15465">
                    <rect width="19" height="35" fill="white" transform="translate(28.5 20)"/>
                </clipPath>
            </defs>
        </svg>
    )
}