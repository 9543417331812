import {create} from 'zustand';
import {User} from "../api/requests/user-info";

type MeState = {
    user: User | null;
    setUser: (user:(prev: User) => User| null) => void;
};

export const useUserStore = create<MeState>((set) => ({
    user: null,
    setUser: (user) => set((state) => ({user: user(state.user!!)})),
}));
